import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "reactstrap";

class File extends Component {
  state = {
    addTooltipOpen: false,
    editTooltipOpen: false,
    deleteTooltipOpen: false
  };

  static propTypes = {
    galleryFile: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      note: PropTypes.string,
      tags: PropTypes.array
    }),
    index: PropTypes.number.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    openLightboxAt: PropTypes.func.isRequired
  };

  toggleTooltip = name => {
    this.setState(currentState => ({
      [name]: !currentState[name]
    }));
  };

  render() {
    const {
      galleryFile,
      index,
      onAddClick,
      onEditClick,
      onDeleteClick,
      openLightboxAt,
      token
    } = this.props;
    const { addTooltipOpen, editTooltipOpen, deleteTooltipOpen } = this.state;

    return (
      <div className="mx-2 my-2">
        <button
          className="btn btn-link"
          onClick={() => openLightboxAt("files", index)}
        >
          <img
            src={`${process.env.REACT_APP_API_URL}/gallery/file/${
              galleryFile.id
            }/thumb?token=${token}`}
            alt={galleryFile.title}
          />
        </button>
        <br />
        <div style={{ width: 140 }}>
          {galleryFile.title}
          <br />
          <i>{galleryFile.note}</i>
          <br />
          {galleryFile.tags.map(tag => (
            <span key={tag} className="badge badge-primary mr-1 mb-1">
              {tag}
            </span>
          ))}
        </div>
        <button
          type="button"
          id={`addToGallery-${index}`}
          onClick={() => onAddClick(galleryFile)}
          className="btn btn-default btn-xs icon-btn mr-1 bg-success"
        >
          <i className="ion ion-md-add" />
        </button>
        <button
          type="button"
          id={`edit-${index}`}
          onClick={() => onEditClick(galleryFile)}
          className="btn btn-default btn-xs icon-btn mr-1 bg-warning"
        >
          <i className="ion ion-md-create" />
        </button>
        <button
          type="button"
          id={`delete-${index}`}
          onClick={() => onDeleteClick(galleryFile)}
          className="btn btn-default btn-xs icon-btn bg-danger text-white"
        >
          <i className="ion ion-md-trash" />
        </button>
        <Tooltip
          placement="top"
          isOpen={addTooltipOpen}
          target={`addToGallery-${index}`}
          toggle={() => this.toggleTooltip("addTooltipOpen")}
        >
          Add to gallery
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={editTooltipOpen}
          target={`edit-${index}`}
          toggle={() => this.toggleTooltip("editTooltipOpen")}
        >
          Edit
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={deleteTooltipOpen}
          target={`delete-${index}`}
          toggle={() => this.toggleTooltip("deleteTooltipOpen")}
        >
          Delete file
        </Tooltip>
      </div>
    );
  }
}

export default File;
