import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  EditorState,
  ContentState,
  RichUtils,
  convertFromHTML
} from "draft-js";
import Editor from "draft-js-plugins-editor";
import createToolbarPlugin, { Separator } from "draft-js-static-toolbar-plugin";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton
} from "draft-js-buttons";
import "draft-js-static-toolbar-plugin/lib/plugin.css";
import { stateToHTML } from "draft-js-export-html";
import HeadlinesButton from "./HeadlinesButton";
import editorStyles from "./editorStyles.module.css";

const toolbarPlugin = createToolbarPlugin();
const { Toolbar } = toolbarPlugin;

export default class CustomToolbarEditor extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    content: PropTypes.string
  };

  static defaultProps = {
    onChange: () => {},
    content: ""
  };

  getEditorState = () => {
    if (!this.props.content) return EditorState.createEmpty();

    const blocksFromHTML = convertFromHTML(this.props.content);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    return EditorState.createWithContent(state);
  };

  state = {
    editorState: this.getEditorState()
  };

  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content) {
      this.setState({ editorState: this.getEditorState() });
    }
  }

  onChange = editorState => {
    const oldContent = stateToHTML(this.state.editorState.getCurrentContent());
    const newContent = stateToHTML(editorState.getCurrentContent());
    this.setState({
      editorState
    });

    // onChange is called on mounting phase and I think that's an unwanted behavior
    // for our current use case. This way we make sure that something really changed
    // to then call our onChange prop
    if (oldContent !== newContent) {
      this.props.onChange(editorState);
    }
  };

  focus = () => {
    this.editor.focus();
  };

  handleKeyCommand = command => {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  render() {
    return (
      <div className={editorStyles.editor} onClick={this.focus}>
        <Editor
          {...this.props}
          editorState={this.state.editorState}
          onChange={this.onChange}
          plugins={[toolbarPlugin]}
          handleKeyCommand={this.handleKeyCommand}
          onTab={this.onTab}
          spellCheck={true}
          ref={element => {
            this.editor = element;
          }}
        />
        <Toolbar>
          {externalProps => (
            <>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <CodeButton {...externalProps} />
              <Separator {...externalProps} />
              <HeadlinesButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
              <BlockquoteButton {...externalProps} />
              <CodeBlockButton {...externalProps} />
            </>
          )}
        </Toolbar>
      </div>
    );
  }
}
