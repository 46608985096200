import React from "react";
import PropTypes from "prop-types";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";

// eslint-disable-next-line
const secureUrlRegex = /^(?:https:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

const CallsSchema = Yup.object().shape({
  callTitle: Yup.string().required("Message is required"),
  callLink: Yup.string()
    .matches(secureUrlRegex, "Link must be a valid https URL")
    .required("Link is required")
});

const CallsMessagingForm = ({ onSubmit }) => {
  return (
    <div className="card mb-4">
      <h6 className="card-header"> WEEKLY CALLS MESSAGING</h6>
      <div className="card-body">
        <Formik
          initialValues={{ callTitle: "", callLink: "" }}
          validationSchema={CallsSchema}
          onSubmit={async (values, utils) => {
            await onSubmit(values);
            utils.setSubmitting(false);
            utils.resetForm();
          }}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            isValid,
            handleSubmit,
            touched,
            errors
          }) => (
            <form
              onSubmit={handleSubmit}
              className="needs-validation"
              noValidate
            >
              <div className="form-group">
                <label className="form-label" htmlFor="callTitle">
                  Message
                </label>
                <input
                  id="callTitle"
                  name="callTitle"
                  type="text"
                  className={
                    touched.callTitle && errors.callTitle
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  required
                  value={values.callTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="callTitle"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="callLink">
                  Link
                </label>
                <input
                  id="callLink"
                  name="callLink"
                  type="url"
                  className={
                    touched.callLink && errors.callLink
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  required
                  value={values.callLink}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="callLink"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <button
                type="submit"
                className="btn btn-primary"
                disabled={!isValid || isSubmitting}
              >
                Submit
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

CallsMessagingForm.propTypes = { onSubmit: PropTypes.func.isRequired };

export default CallsMessagingForm;
