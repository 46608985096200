import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "reactstrap";
import { FormattedDate } from "react-intl";

class Applicant extends React.PureComponent {
  state = {
    downloadTooltipOpen: false,
    deleteTooltipOpen: false
  };

  static propTypes = {
    applicant: PropTypes.shape({
      id: PropTypes.number.isRequired,
      fullName: PropTypes.string.isRequired,
      jobs: PropTypes.string.isRequired,
      methodContact: PropTypes.string.isRequired,
      date: PropTypes.instanceOf(Date),
      email: PropTypes.string,
      phone: PropTypes.string
    }),
    onDownloadClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired
  };

  toggleDownloadTooltip = e => {
    this.setState(currentState => ({
      downloadTooltipOpen: !currentState.downloadTooltipOpen
    }));
  };

  toggleDeleteTooltipOpen = e => {
    this.setState(currentState => ({
      deleteTooltipOpen: !currentState.deleteTooltipOpen
    }));
  };

  handleDeleteClick = e => {
    e.preventDefault();
    this.props.onDeleteClick(this.props.applicant);
  };

  handleDownloadClick = e => {
    e.preventDefault();
    this.props.onDownloadClick(this.props.applicant);
  };

  render() {
    const {
      applicant: { id, fullName, jobs, date, methodContact, phone, email }
    } = this.props;

    const { downloadTooltipOpen, deleteTooltipOpen } = this.state;

    return (
      <>
        <tr>
          <td className="align-middle">
            <button
              type="button"
              className="btn btn-link p-0"
              title="Download"
              onClick={this.handleDownloadClick}
            >
              {fullName}
            </button>
          </td>
          <td className="align-middle">
            <a href={`tel:${phone}`} title="Call">
              {phone}
            </a>
          </td>
          <td className="align-middle">
            <a href={`mailto:${email}`} title="Compose email">
              {email}
            </a>
          </td>
          <td className="align-middle">{methodContact}</td>
          <td className="align-middle">
            {date && (
              <FormattedDate
                value={date}
                hour12={false}
                day="2-digit"
                month="short"
                year="numeric"
                hour="numeric"
                minute="numeric"
                second="numeric"
              />
            )}
          </td>
          <td className="align-middle">{jobs}</td>
          <td className="text-center text-nowrap align-middle">
            <button
              type="button"
              id={`download-${id}`}
              onClick={this.handleDownloadClick}
              className="btn btn-default btn-xs icon-btn mr-1 bg-primary text-white"
            >
              <i className="ion ion-md-download" />
            </button>
            <button
              type="button"
              id={`delete-${id}`}
              onClick={this.handleDeleteClick}
              className="btn btn-default btn-xs icon-btn bg-danger text-white"
            >
              <i className="ion ion-md-close" />
            </button>
          </td>
          <Tooltip
            placement="top"
            isOpen={downloadTooltipOpen}
            target={`download-${id}`}
            toggle={this.toggleDownloadTooltip}
          >
            Download
          </Tooltip>
          <Tooltip
            placement="top"
            isOpen={deleteTooltipOpen}
            target={`delete-${id}`}
            toggle={this.toggleDeleteTooltipOpen}
          >
            Delete
          </Tooltip>
        </tr>
      </>
    );
  }
}

export default Applicant;
