import React, { Component } from "react";
import Axios from "axios";
import { withAuthorization } from "../Auth";
import * as routes from "../../config/routes";
import { hasPermission, PERMISSIONS } from "../../config/permissions";
import ApplicantsList from "./ApplicantsList";
import DeleteConfirmation from "../DeleteConfirmation";
import DownloadFile from "../DownloadFile";

class CareersApplicants extends Component {
  state = {
    applicants: [],
    filteredApplicants: [],
    selectedApplicant: {},
    searchValue: "",
    loading: true,
    sortBy: "date",
    sortDesc: true,
    resumeBlob: {},
    resumeFileName: ""
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    const URL = `${process.env.REACT_APP_API_URL}/careers/applicants`;

    const response = await Axios.get(URL);

    const applicants = response.data.map(a => {
      a.date = a.date ? new Date(a.date) : "";
      a.jobs = `${a.jobs.join(", ")}.`;
      return a;
    });

    const sortedApplicants = this.sort(applicants);

    this.setState({
      applicants: sortedApplicants,
      filteredApplicants: sortedApplicants,
      loading: false
    });
  };

  fetchResume = async applicant => {
    const URL = `${process.env.REACT_APP_API_URL}/file/resume/${applicant.id}`;

    const { data, headers } = await Axios.get(URL, {
      responseType: "blob"
    });

    this.setState({
      resumeBlob: data,
      resumeFileName: headers.filename.split("/")[1]
    });
  };

  sort = applicants => {
    const { sortBy, sortDesc } = this.state;

    return applicants.sort((A, B) => {
      const firstField =
        typeof A[sortBy] === "string" ? A[sortBy].toLowerCase() : A[sortBy];
      const secondField =
        typeof B[sortBy] === "string" ? B[sortBy].toLowerCase() : B[sortBy];

      if (sortDesc) {
        return firstField > secondField ? -1 : 1;
      } else {
        return firstField > secondField ? 1 : -1;
      }
    });
  };

  setSortBy = key => {
    this.setState(
      currentState => {
        const nextState = {};
        if (key === currentState.sortBy) {
          nextState.sortDesc = !currentState.sortDesc;
        } else {
          nextState.sortBy = key;
        }

        return nextState;
      },
      () => {
        this.setState(
          { applicants: this.sort(this.state.applicants) },
          this.filter
        );
      }
    );
  };

  filter = () => {
    const { applicants, searchValue } = this.state;

    const filteredApplicants = applicants.filter(
      ({ fullName, email, jobs }) =>
        fullName.toLowerCase().includes(searchValue.toLowerCase()) ||
        (email && email.toLowerCase().includes(searchValue.toLowerCase())) ||
        (jobs && jobs.toLowerCase().includes(searchValue.toLowerCase()))
    );

    this.setState({ filteredApplicants });
  };

  delete = async applicant => {
    const url = `${process.env.REACT_APP_API_URL}/careers/applicants/${
      applicant.id
    }`;

    await Axios.delete(url);

    this.setState(({ applicants }) => {
      const idx = applicants.findIndex(a => a.id === applicant.id);

      return {
        applicants: [...applicants.slice(0, idx), ...applicants.slice(idx + 1)]
      };
    }, this.filter);

    this.onDeleteClick();
  };

  onDeleteClick = (selectedApplicant = {}) =>
    this.setState({ selectedApplicant });

  onSearchChange = e => {
    this.setState({ searchValue: e.target.value }, () => {
      if (this.state.searchValue.length >= 3) {
        this.filter();
      } else {
        this.setState(currentState => {
          return { filteredApplicants: currentState.applicants };
        });
      }
    });
  };

  onDownloadResume = () => {
    this.setState({ resumeBlob: {} });
  };

  render() {
    const {
      selectedApplicant,
      filteredApplicants,
      searchValue,
      loading,
      sortBy,
      sortDesc,
      resumeBlob,
      resumeFileName
    } = this.state;

    return (
      <>
        {resumeBlob instanceof Blob ? (
          <DownloadFile
            blob={resumeBlob}
            onDownload={this.onDownloadResume}
            fileName={resumeFileName}
          />
        ) : null}
        {Object.keys(selectedApplicant).length ? (
          <DeleteConfirmation
            name={`${selectedApplicant.fullName} ${selectedApplicant.jobs}`}
            onCancelClick={_ => this.onDeleteClick()}
            onDeleteClick={this.delete}
            resource={selectedApplicant}
          />
        ) : null}
        <ApplicantsList
          applicants={filteredApplicants}
          search={this.onSearchChange}
          searchValue={searchValue}
          loading={loading}
          sortBy={this.setSortBy}
          sortDesc={sortDesc}
          sortKey={sortBy}
          onDeleteClick={this.onDeleteClick}
          onDownloadClick={this.fetchResume}
        />
      </>
    );
  }
}

const protectedPage = withAuthorization(
  user => hasPermission(user, PERMISSIONS.careers),
  () => routes.DASHBOARD
);

export default protectedPage(CareersApplicants);
