import React from "react";
import PropTypes from "prop-types";
import Career from "./Career";
import CareersPostingFilter from "./CareersPostingFilter";

const CareersPostingsList = ({
  loading,
  careers,
  search,
  searchValue,
  filterField,
  onFilterChange,
  onEditClick,
  onDeleteClick
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col">
            <CareersPostingFilter
              search={search}
              searchValue={searchValue}
              filterField={filterField}
              onFilterChange={onFilterChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <em className="text-muted float-right">
              {loading && "Loading..."}
              {!loading && (
                <>
                  Showing <strong>{careers.length}</strong> positions.
                </>
              )}
            </em>
          </div>
        </div>
      </div>
      <hr className="border-light m-0" />
      <div className="container-fluid" id="careersList">
        {careers.map(career => (
          <Career
            key={career.job_id}
            career={career}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
          />
        ))}
      </div>
      <div className="card-body py-0">
        <div className="row">
          <div className="col-sm text-sm-left text-center pt-3 pb-3">
            <em className="text-muted">
              {loading && "Loading..."}
              {!loading && (
                <>
                  Showing <strong>{careers.length}</strong> positions.
                </>
              )}
            </em>
          </div>
        </div>
      </div>
    </div>
  );
};

CareersPostingsList.propTypes = {
  search: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  filterField: PropTypes.string
};

CareersPostingsList.defaultProps = {
  filterField: "",
  searchValue: ""
};

export default CareersPostingsList;
