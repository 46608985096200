import React, { Component } from "react";
import PropTypes from "prop-types";

class DownloadFile extends Component {
  state = {
    href: ""
  };

  static propTypes = {
    blob: PropTypes.object.isRequired,
    fileName: PropTypes.string,
    onDownload: PropTypes.func.isRequired
  };

  static defaultProps = {
    fileName: ""
  };

  componentDidMount() {
    this.setState(
      { href: window.URL.createObjectURL(this.props.blob) },
      this.download
    );
  }

  download = () => {
    this.anchorElement.click();
    this.props.onDownload();
  };

  render() {
    const { href } = this.state;
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        ref={anchor => (this.anchorElement = anchor)}
        style={{ display: "none" }}
        download={this.props.fileName || null}
      >
        Downloading
      </a>
    );
  }
}

export default DownloadFile;
