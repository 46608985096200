import React, { Component } from "react";
import PropTypes from "prop-types";
import DocumentTitle from "react-document-title";
import { FormattedDate } from "react-intl";
import { withAuthorization } from "../Auth";

class Dashboard extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  render() {
    const { user } = this.props;
    return (
      <DocumentTitle title="Home - Borger Team">
        <>
          <h4 className="media align-items-center font-weight-bold py-3 mb-4">
            <div className="media-body ml-3">
              Welcome back, {user.firstName}!
              <div className="text-muted text-tiny mt-1">
                <small className="font-weight-normal">
                  Today is{" "}
                  <FormattedDate
                    value={new Date()}
                    weekday="long"
                    day="2-digit"
                    month="long"
                    year="numeric"
                  />
                </small>
              </div>
            </div>
          </h4>
          <hr className="container-m--x mt-0 mb-4" />
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  Hold on to your hats!
                  <i className="fab fa-3x fa-pied-piper-hat" />
                  <br />
                  New portal coming soon...
                </div>
              </div>
            </div>
          </div>
        </>
      </DocumentTitle>
    );
  }
}

export default withAuthorization()(Dashboard);
