import React from "react";
import PropTypes from "prop-types";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import Logo from "../../static/images/logo/borger_logo_large.png";
import "../../vendor/styles/pages/authentication.scss";

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .email("Your email doesn't look quite right...")
    .required("Pzzt! The email field is required."),
  password: Yup.string()
    .required("Pzz! The password field is required.")
    .min(6, "Password must be between 6 and 24 characters.")
    .max(24, "Password must be between 6 and 24 characters.")
});

const DisplayLogin = ({ onSubmit, hasError }) => (
  <div className="authentication-wrapper authentication-1 px-4">
    <div className="authentication-inner py-5">
      <div className="d-flex justify-content-center align-items-center">
        <div>
          <div className="position-relative" style={{ paddingBottom: "54%" }}>
            <img src={Logo} alt="Borger Logo" />
          </div>
        </div>
      </div>

      <Formik
        initialValues={{ username: "", password: "" }}
        validationSchema={LoginSchema}
        onSubmit={async (values, utils) => {
          await onSubmit(values);
          utils.setSubmitting(false);
        }}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          isValid,
          handleSubmit
        }) => {
          return (
            <form className="my-5" onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="form-label" htmlFor="email">
                  Your email
                </label>
                <input
                  id="email"
                  className="form-control"
                  type="email"
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                <ErrorMessage name="username" component="div" />
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  name="password"
                  className="form-control"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                <ErrorMessage name="password" component="div" />
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block mt-4"
                disabled={!isValid || isSubmitting}
              >
                Log In
              </button>
              {hasError && (
                <div className="alert alert-dark-danger mt-2" role="alert">
                  Your email or password is incorrect.
                </div>
              )}
            </form>
          );
        }}
      </Formik>
    </div>
  </div>
);

DisplayLogin.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired
};

export default DisplayLogin;
