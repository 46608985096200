/* eslint-disable */
!(function(t, e) {
  var i = (function(t) {
    var e = {};
    function i(n) {
      if (e[n]) return e[n].exports;
      var o = (e[n] = { i: n, l: !1, exports: {} });
      return t[n].call(o.exports, o, o.exports, i), (o.l = !0), o.exports;
    }
    return (
      (i.m = t),
      (i.c = e),
      (i.d = function(t, e, n) {
        i.o(t, e) ||
          Object.defineProperty(t, e, {
            configurable: !1,
            enumerable: !0,
            get: n
          });
      }),
      (i.r = function(t) {
        Object.defineProperty(t, "__esModule", { value: !0 });
      }),
      (i.n = function(t) {
        var e =
          t && t.__esModule
            ? function() {
                return t.default;
              }
            : function() {
                return t;
              };
        return i.d(e, "a", e), e;
      }),
      (i.o = function(t, e) {
        return Object.prototype.hasOwnProperty.call(t, e);
      }),
      (i.p = ""),
      i((i.s = 459))
    );
  })({
    459: function(t, e, i) {
      "use strict";
      function n(t) {
        return Array.isArray(t) ? t : Array.from(t);
      }
      Object.defineProperty(e, "__esModule", { value: !0 });
      var o = ["transitionend", "webkitTransitionEnd", "oTransitionEnd"],
        a = [
          "transition",
          "MozTransition",
          "webkitTransition",
          "WebkitTransition",
          "OTransition"
        ];
      function s(t) {
        throw new Error("Parameter required" + (t ? ": `" + t + "`" : ""));
      }
      var r = {
        CONTAINER:
          "undefined" != typeof window ? document.documentElement : null,
        LAYOUT_BREAKPOINT: 992,
        RESIZE_DELAY: 200,
        _curStyle: null,
        _styleEl: null,
        _resizeTimeout: null,
        _resizeCallback: null,
        _transitionCallback: null,
        _transitionCallbackTimeout: null,
        _listeners: [],
        _initialized: !1,
        _autoUpdate: !1,
        _lastWindowHeight: 0,
        _addClass: function(t) {
          var e =
            arguments.length > 1 && void 0 !== arguments[1]
              ? arguments[1]
              : this.CONTAINER;
          t.split(" ").forEach(function(t) {
            return e.classList.add(t);
          });
        },
        _removeClass: function(t) {
          var e =
            arguments.length > 1 && void 0 !== arguments[1]
              ? arguments[1]
              : this.CONTAINER;
          t.split(" ").forEach(function(t) {
            return e.classList.remove(t);
          });
        },
        _hasClass: function(t) {
          var e =
              arguments.length > 1 && void 0 !== arguments[1]
                ? arguments[1]
                : this.CONTAINER,
            i = !1;
          return (
            t.split(" ").forEach(function(t) {
              e.classList.contains(t) && (i = !0);
            }),
            i
          );
        },
        _supportsTransitionEnd: function() {
          if (window.QUnit) return !1;
          var t = document.body || document.documentElement;
          if (!t) return !1;
          var e = !1;
          return (
            a.forEach(function(i) {
              void 0 !== t.style[i] && (e = !0);
            }),
            e
          );
        },
        _getAnimationDuration: function(t) {
          var e = window.getComputedStyle(t).transitionDuration;
          return parseFloat(e) * (-1 !== e.indexOf("ms") ? 1 : 1e3);
        },
        _triggerWindowEvent: function(t) {
          if ("undefined" != typeof window)
            if (document.createEvent) {
              var e = void 0;
              "function" == typeof Event
                ? (e = new Event(t))
                : (e = document.createEvent("Event")).initEvent(t, !1, !0),
                window.dispatchEvent(e);
            } else window.fireEvent("on" + t, document.createEventObject());
        },
        _triggerEvent: function(t) {
          this._triggerWindowEvent("layout" + t),
            this._listeners
              .filter(function(e) {
                return e.event === t;
              })
              .forEach(function(t) {
                return t.callback.call(null);
              });
        },
        _updateInlineStyle: function() {
          var t =
              arguments.length > 0 && void 0 !== arguments[0]
                ? arguments[0]
                : 0,
            e =
              arguments.length > 1 && void 0 !== arguments[1]
                ? arguments[1]
                : 0;
          this._styleEl ||
            ((this._styleEl = document.createElement("style")),
            (this._styleEl.type = "text/css"),
            document.head.appendChild(this._styleEl));
          var i = "\n.layout-fixed .layout-1 .layout-sidenav,\n.layout-fixed-offcanvas .layout-1 .layout-sidenav {\n  top: {navbarHeight}px !important;\n}\n.layout-container {\n  padding-top: {navbarHeight}px !important;\n}\n.layout-content {\n  padding-bottom: {footerHeight}px !important;\n}"
            .replace(/\{navbarHeight\}/gi, t)
            .replace(/\{footerHeight\}/gi, e);
          this._curStyle !== i &&
            ((this._curStyle = i), (this._styleEl.textContent = i));
        },
        _removeInlineStyle: function() {
          this._styleEl && document.head.removeChild(this._styleEl),
            (this._styleEl = null),
            (this._curStyle = null);
        },
        _redrawLayoutSidenav: function() {
          var t = this.getLayoutSidenav();
          if (t && t.querySelector(".sidenav")) {
            var e = t.querySelector(".sidenav-inner"),
              i = e.scrollTop,
              n = document.documentElement.scrollTop;
            return (
              (t.style.display = "none"),
              t.offsetHeight,
              (t.style.display = ""),
              (e.scrollTop = i),
              (document.documentElement.scrollTop = n),
              !0
            );
          }
          return !1;
        },
        _getNavbarHeight: function() {
          var t = this,
            e = this.getLayoutNavbar();
          if (!e) return 0;
          if (!this.isSmallScreen()) return e.getBoundingClientRect().height;
          var i = e.cloneNode(!0);
          (i.id = null),
            (i.style.visibility = "hidden"),
            (i.style.position = "absolute"),
            Array.prototype.slice
              .call(i.querySelectorAll(".collapse.show"))
              .forEach(function(e) {
                return t._removeClass("show", e);
              }),
            e.parentNode.insertBefore(i, e);
          var n = i.getBoundingClientRect().height;
          return i.parentNode.removeChild(i), n;
        },
        _getFooterHeight: function() {
          var t = this.getLayoutFooter();
          return t ? t.getBoundingClientRect().height : 0;
        },
        _bindLayoutAnimationEndEvent: function(t, e) {
          var i = this,
            n = this.getSidenav(),
            a = n ? this._getAnimationDuration(n) + 50 : 0;
          if (!a) return t.call(this), void e.call(this);
          (this._transitionCallback = function(t) {
            t.target === n && (i._unbindLayoutAnimationEndEvent(), e.call(i));
          }),
            o.forEach(function(t) {
              n.addEventListener(t, i._transitionCallback, !1);
            }),
            t.call(this),
            (this._transitionCallbackTimeout = setTimeout(function() {
              i._transitionCallback.call(i, { target: n });
            }, a));
        },
        _unbindLayoutAnimationEndEvent: function() {
          var t = this,
            e = this.getSidenav();
          this._transitionCallbackTimeout &&
            (clearTimeout(this._transitionCallbackTimeout),
            (this._transitionCallbackTimeout = null)),
            e &&
              this._transitionCallback &&
              o.forEach(function(i) {
                e.removeEventListener(i, t._transitionCallback, !1);
              }),
            this._transitionCallback && (this._transitionCallback = null);
        },
        _bindWindowResizeEvent: function() {
          var t = this;
          this._unbindWindowResizeEvent();
          var e = function() {
            t._resizeTimeout &&
              (clearTimeout(t._resizeTimeout), (t._resizeTimeout = null)),
              t._triggerEvent("resize");
          };
          (this._resizeCallback = function() {
            t._resizeTimeout && clearTimeout(t._resizeTimeout),
              (t._resizeTimeout = setTimeout(e, t.RESIZE_DELAY));
          }),
            window.addEventListener("resize", this._resizeCallback, !1);
        },
        _unbindWindowResizeEvent: function() {
          this._resizeTimeout &&
            (clearTimeout(this._resizeTimeout), (this._resizeTimeout = null)),
            this._resizeCallback &&
              (window.removeEventListener("resize", this._resizeCallback, !1),
              (this._resizeCallback = null));
        },
        _setCollapsed: function(t) {
          var e = this;
          this.isSmallScreen()
            ? t
              ? this._removeClass("layout-expanded")
              : setTimeout(
                  function() {
                    e._addClass("layout-expanded");
                  },
                  this._redrawLayoutSidenav() ? 5 : 0
                )
            : this[t ? "_addClass" : "_removeClass"]("layout-collapsed");
        },
        getLayoutSidenav: function() {
          return document.querySelector(".layout-sidenav");
        },
        getSidenav: function() {
          var t = this.getLayoutSidenav();
          return t
            ? this._hasClass("sidenav", t)
              ? t
              : t.querySelector(".sidenav")
            : null;
        },
        getLayoutNavbar: function() {
          return document.querySelector(".layout-navbar");
        },
        getLayoutFooter: function() {
          return document.querySelector(".layout-footer");
        },
        getLayoutContainer: function() {
          return document.querySelector(".layout-container");
        },
        isMobileDevice: function() {
          return (
            void 0 !== window.orientation ||
            -1 !== navigator.userAgent.indexOf("IEMobile")
          );
        },
        isSmallScreen: function() {
          return (
            (window.innerWidth ||
              document.documentElement.clientWidth ||
              document.body.clientWidth) < this.LAYOUT_BREAKPOINT
          );
        },
        isLayout1: function() {
          return !!document.querySelector(".layout-wrapper.layout-1");
        },
        isCollapsed: function() {
          return this.isSmallScreen()
            ? !this._hasClass("layout-expanded")
            : this._hasClass("layout-collapsed");
        },
        isFixed: function() {
          return this._hasClass("layout-fixed layout-fixed-offcanvas");
        },
        isOffcanvas: function() {
          return this._hasClass("layout-offcanvas layout-fixed-offcanvas");
        },
        isNavbarFixed: function() {
          return (
            this._hasClass("layout-navbar-fixed") ||
            (!this.isSmallScreen() && this.isFixed() && this.isLayout1())
          );
        },
        isFooterFixed: function() {
          return this._hasClass("layout-footer-fixed");
        },
        isReversed: function() {
          return this._hasClass("layout-reversed");
        },
        setCollapsed: function() {
          var t = this,
            e =
              arguments.length > 0 && void 0 !== arguments[0]
                ? arguments[0]
                : s("collapsed"),
            i =
              !(arguments.length > 1 && void 0 !== arguments[1]) ||
              arguments[1],
            n = this.getLayoutSidenav();
          n &&
            (this._unbindLayoutAnimationEndEvent(),
            i && this._supportsTransitionEnd()
              ? (this._addClass("layout-transitioning"),
                this._bindLayoutAnimationEndEvent(
                  function() {
                    t._setCollapsed(e);
                  },
                  function() {
                    t._removeClass("layout-transitioning"),
                      t._triggerWindowEvent("resize"),
                      t._triggerEvent("toggle");
                  }
                ))
              : (this._addClass("layout-no-transition"),
                this._setCollapsed(e),
                setTimeout(function() {
                  t._removeClass("layout-no-transition"),
                    t._triggerWindowEvent("resize"),
                    t._triggerEvent("toggle");
                }, 1)));
        },
        toggleCollapsed: function() {
          var t =
            !(arguments.length > 0 && void 0 !== arguments[0]) || arguments[0];
          this.setCollapsed(!this.isCollapsed(), t);
        },
        setPosition: function() {
          var t =
              arguments.length > 0 && void 0 !== arguments[0]
                ? arguments[0]
                : s("fixed"),
            e =
              arguments.length > 1 && void 0 !== arguments[1]
                ? arguments[1]
                : s("offcanvas");
          this._removeClass(
            "layout-offcanvas layout-fixed layout-fixed-offcanvas"
          ),
            !t && e
              ? this._addClass("layout-offcanvas")
              : t && !e
              ? (this._addClass("layout-fixed"), this._redrawLayoutSidenav())
              : t &&
                e &&
                (this._addClass("layout-fixed-offcanvas"),
                this._redrawLayoutSidenav()),
            this.update();
        },
        setNavbarFixed: function() {
          var t =
            arguments.length > 0 && void 0 !== arguments[0]
              ? arguments[0]
              : s("fixed");
          this[t ? "_addClass" : "_removeClass"]("layout-navbar-fixed"),
            this.update();
        },
        setFooterFixed: function() {
          var t =
            arguments.length > 0 && void 0 !== arguments[0]
              ? arguments[0]
              : s("fixed");
          this[t ? "_addClass" : "_removeClass"]("layout-footer-fixed"),
            this.update();
        },
        setReversed: function() {
          var t =
            arguments.length > 0 && void 0 !== arguments[0]
              ? arguments[0]
              : s("reversed");
          this[t ? "_addClass" : "_removeClass"]("layout-reversed");
        },
        update: function() {
          ((this.getLayoutNavbar() &&
            ((!this.isSmallScreen() && this.isLayout1() && this.isFixed()) ||
              this.isNavbarFixed())) ||
            (this.getLayoutFooter() && this.isFooterFixed())) &&
            this._updateInlineStyle(
              this._getNavbarHeight(),
              this._getFooterHeight()
            );
        },
        setAutoUpdate: function() {
          var t = this,
            e =
              arguments.length > 0 && void 0 !== arguments[0]
                ? arguments[0]
                : s("enable");
          e && !this._autoUpdate
            ? (this.on("resize.layoutHelpers:autoUpdate", function() {
                return t.update();
              }),
              (this._autoUpdate = !0))
            : !e &&
              this._autoUpdate &&
              (this.off("resize.layoutHelpers:autoUpdate"),
              (this._autoUpdate = !1));
        },
        on: function() {
          var t =
              arguments.length > 0 && void 0 !== arguments[0]
                ? arguments[0]
                : s("event"),
            e =
              arguments.length > 1 && void 0 !== arguments[1]
                ? arguments[1]
                : s("callback"),
            i = t.split("."),
            o = n(i),
            a = o[0],
            r = o.slice(1);
          (r = r.join(".") || null),
            this._listeners.push({ event: a, namespace: r, callback: e });
        },
        off: function() {
          var t = this,
            e =
              arguments.length > 0 && void 0 !== arguments[0]
                ? arguments[0]
                : s("event"),
            i = e.split("."),
            o = n(i),
            a = o[0],
            r = o.slice(1);
          (r = r.join(".") || null),
            this._listeners
              .filter(function(t) {
                return t.event === a && t.namespace === r;
              })
              .forEach(function(e) {
                return t._listeners.splice(t._listeners.indexOf(e), 1);
              });
        },
        init: function() {
          var t = this;
          this._initialized ||
            ((this._initialized = !0),
            this._updateInlineStyle(0),
            this._bindWindowResizeEvent(),
            this.off("init._layoutHelpers"),
            this.on("init._layoutHelpers", function() {
              t.off("resize._layoutHelpers:redrawSidenav"),
                t.on("resize._layoutHelpers:redrawSidenav", function() {
                  t.isSmallScreen() &&
                    !t.isCollapsed() &&
                    t._redrawLayoutSidenav();
                }),
                "number" == typeof document.documentMode &&
                  document.documentMode < 11 &&
                  (t.off("resize._layoutHelpers:ie10RepaintBody"),
                  t.on("resize._layoutHelpers:ie10RepaintBody", function() {
                    if (!t.isFixed()) {
                      var e = document.documentElement.scrollTop;
                      (document.body.style.display = "none"),
                        document.body.offsetHeight,
                        (document.body.style.display = "block"),
                        (document.documentElement.scrollTop = e);
                    }
                  }));
            }),
            this._triggerEvent("init"));
        },
        destroy: function() {
          var t = this;
          this._initialized &&
            ((this._initialized = !1),
            this._removeClass("layout-transitioning"),
            this._removeInlineStyle(),
            this._unbindLayoutAnimationEndEvent(),
            this._unbindWindowResizeEvent(),
            this.setAutoUpdate(!1),
            this.off("init._layoutHelpers"),
            this._listeners
              .filter(function(t) {
                return "init" !== t.event;
              })
              .forEach(function(e) {
                return t._listeners.splice(t._listeners.indexOf(e), 1);
              }));
        }
      };
      "undefined" != typeof window &&
        (r.init(),
        r.isMobileDevice() &&
          window.chrome &&
          document.documentElement.classList.add("layout-sidenav-100vh"),
        "complete" === document.readyState
          ? r.update()
          : document.addEventListener("DOMContentLoaded", function t() {
              r.update(), document.removeEventListener("DOMContentLoaded", t);
            })),
        (e.layoutHelpers = r);
    }
  });
  if ("object" == typeof i) {
    var n = [
      "object" == typeof module && "object" == typeof module.exports
        ? module.exports
        : null,
      "undefined" != typeof window ? window : null,
      t && t !== window ? t : null
    ];
    for (var o in i)
      n[0] && (n[0][o] = i[o]),
        n[1] && "__esModule" !== o && (n[1][o] = i[o]),
        n[2] && (n[2][o] = i[o]);
  }
})(this);
