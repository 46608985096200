import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "reactstrap";

class GalleryItem extends Component {
  state = {
    leftTooltipOpen: false,
    rightTooltipOpen: false,
    removeTooltipOpen: false
  };

  static propTypes = {
    index: PropTypes.number.isRequired,
    onRemoveClick: PropTypes.func.isRequired,
    onLeftClick: PropTypes.func.isRequired,
    onRightClick: PropTypes.func.isRequired,
    openLightboxAt: PropTypes.func.isRequired
  };

  toggleTooltip = name => {
    this.setState(currentState => ({
      [name]: !currentState[name]
    }));
  };

  render() {
    const {
      galleryFile,
      index,
      token,
      openLightboxAt,
      onLeftClick,
      onRightClick,
      onRemoveClick
    } = this.props;
    const { leftTooltipOpen, rightTooltipOpen, removeTooltipOpen } = this.state;

    return (
      <div className="mx-2 my-2">
        <button
          className="btn btn-link"
          onClick={() => openLightboxAt("gallery", index)}
        >
          <img
            src={`${process.env.REACT_APP_API_URL}/gallery/file/${
              galleryFile.id
            }/thumb?token=${token}`}
            alt={galleryFile.title}
          />
        </button>
        <br />
        <div style={{ width: 140 }}>
          {galleryFile.title}
          <br />
          <i>{galleryFile.note}</i>
        </div>
        <button
          type="button"
          id={`moveLeft-${index}`}
          onClick={() => onLeftClick(galleryFile)}
          className="btn btn-default btn-xs icon-btn mr-1 bg-warning"
        >
          <i className="ion ion-md-arrow-dropleft" />
        </button>
        <button
          type="button"
          id={`moveRight-${index}`}
          onClick={() => onRightClick(galleryFile)}
          className="btn btn-default btn-xs icon-btn mr-1 bg-warning"
        >
          <i className="ion ion-md-arrow-dropright" />
        </button>
        <button
          type="button"
          id={`removeImage-${index}`}
          onClick={() => onRemoveClick(galleryFile)}
          className="btn btn-default btn-xs icon-btn bg-danger"
        >
          <i className="ion ion-md-close" />
        </button>
        <Tooltip
          placement="top"
          isOpen={leftTooltipOpen}
          target={`moveLeft-${index}`}
          toggle={() => this.toggleTooltip("leftTooltipOpen")}
        >
          Move previous
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={rightTooltipOpen}
          target={`moveRight-${index}`}
          toggle={() => this.toggleTooltip("rightTooltipOpen")}
        >
          Move next
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={removeTooltipOpen}
          target={`removeImage-${index}`}
          toggle={() => this.toggleTooltip("removeTooltipOpen")}
        >
          Remove image from gallery
        </Tooltip>
      </div>
    );
  }
}

export default GalleryItem;
