import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "../Theme";

const LayoutFooter = props => {
  return (
    <nav className={`footer ${props.theme.layoutFooterBg}`}>
      <div className="container-fluid container-p-x pb-3">
        <a
          href="https://borger.ca"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link pt-3"
        >
          borger.ca
        </a>
      </div>
    </nav>
  );
};

LayoutFooter.propTypes = {
  theme: PropTypes.object.isRequired
};

export default withTheme(LayoutFooter);
