import React, { Component } from "react";
import Axios from "axios";
import { withAuthorization } from "../Auth";
import * as routes from "../../config/routes";
import { hasPermission, PERMISSIONS } from "../../config/permissions";
import DocumentTitle from "react-document-title";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./styles.css";

class AccountUpdates extends Component {
  constructor(props) {
    super(props);
    this.formik = React.createRef();
  }

  state = {
    // searchValue: '',
    loading: true,
    users: [],
    filteredUsers: [],
    searchValueIdName: "",
    searchValueCompany: "",
    searchValuePosition: "",
    activeScreen: "select",
    submitSuccess: false
  };

  componentDidMount() {
    this.fetch();
  }

  filter = () => {
    const {
      users,
      searchValueIdName,
      searchValueCompany,
      searchValuePosition
    } = this.state;

    const filteredUsers = users.filter(
      ({ emplid, first_name, last_name, company, position }) =>
        `${emplid} ${first_name} ${last_name}`
          .toLowerCase()
          .includes(searchValueIdName.toLowerCase()) &&
        company.toLowerCase().includes(searchValueCompany) &&
        position.toLowerCase().includes(searchValuePosition)
    );

    this.setState({ filteredUsers });
  };

  fetch = async () => {
    let URL = `${process.env.REACT_APP_API_URL}/learning/courses`;
    let response = await Axios.get(URL);
    const courses = response.data;

    URL = `${process.env.REACT_APP_API_URL}/user`;
    response = await Axios.get(URL);
    const users = response.data;
    for (let u of users) {
      u.isSelectedUser = false;
    }

    this.setState({ courses, users, loading: false }, this.filter);
  };

  toggleSelectedUser = id => {
    this.setState(currentState => {
      const users = currentState.users.map(user =>
        user.employee_id === id
          ? { ...user, isSelectedUser: !user.isSelectedUser }
          : user
      );
      return { users };
    }, this.filter);
  };

  searchIdName = e => {
    this.setState({ searchValueIdName: e.target.value }, this.filter);
  };

  searchCompany = e => {
    this.setState({ searchValueCompany: e.target.value }, this.filter);
  };

  searchPosition = e => {
    this.setState({ searchValuePosition: e.target.value }, this.filter);
  };

  clearFilter = () => {
    this.setState(
      {
        searchValueIdName: "",
        searchValueCompany: "",
        searchValuePosition: ""
      },
      this.filter
    );
  };

  selectFilteredUsers = () => {
    for (let u of this.state.filteredUsers) {
      if (!u.isSelectedUser) {
        this.toggleSelectedUser(u.employee_id);
      }
    }
  };

  selectAllUsers = () => {
    for (let u of this.state.users) {
      if (!u.isSelectedUser) {
        this.toggleSelectedUser(u.employee_id);
      }
    }
  };

  countSelectedStudents = () => {
    let count = 0;
    for (let u of this.state.filteredUsers) {
      if (u.isSelectedUser) {
        count++;
      }
    }
    return count;
  };

  removeSelectedStudents = () => {
    for (let u of this.state.users) {
      if (u.isSelectedUser) {
        this.toggleSelectedUser(u.employee_id);
      }
    }
  };

  setActiveScreen = screen => {
    this.setState({ activeScreen: screen });
  };

  accountUpdatesSchema = Yup.object().shape({
    bucksAmount: Yup.number().required("Amount is required"),
    spcAmount: Yup.number().required("Amount is required"),
    coinAmount: Yup.number().required("Amount is required"),
    description: Yup.string().required("Description is required")
  });

  getSelectedEmployeeIds = () => {
    let selectedIds = [];
    for (let u of this.state.users) {
      if (u.isSelectedUser) {
        selectedIds.push(u.employee_id);
      }
    }
    return selectedIds;
  };

  submitAssignment = async values => {
    const bucksAmount = parseInt(values.bucksAmount);
    const spcAmount = parseInt(values.spcAmount);
    const coinAmount = parseInt(values.coinAmount);
    let bucksSuccess = true,
      spcSuccess = true,
      coinSuccess = true;

    if (bucksAmount !== 0) {
      let transactionRecord = {};
      transactionRecord["source"] = `Admin - ${this.props.user.firstName} ${
        this.props.user.lastName
      }`;
      transactionRecord["description"] = values.description;
      transactionRecord["employee_ids"] = this.getSelectedEmployeeIds();
      transactionRecord["amount"] = bucksAmount;
      bucksAmount > 0
        ? (transactionRecord["transactionType"] = "Deposit")
        : (transactionRecord["transactionType"] = "Withdrawal");

      const URL = `${process.env.REACT_APP_API_URL}/rewards/bucks`;
      const payload = transactionRecord;
      try {
        await Axios.put(URL, payload);
      } catch (err) {
        bucksSuccess = false;
        console.log(err);
      }
    }

    if (spcAmount !== 0) {
      let transactionRecord = {};
      transactionRecord["source"] = `Admin - ${this.props.user.firstName} ${
        this.props.user.lastName
      }`;
      transactionRecord["description"] = values.description;
      transactionRecord["employee_ids"] = this.getSelectedEmployeeIds();
      transactionRecord["amount"] = spcAmount;

      const URL = `${process.env.REACT_APP_API_URL}/rewards/spc`;
      const payload = transactionRecord;
      try {
        await Axios.put(URL, payload);
      } catch (err) {
        spcSuccess = false;
        console.log(err);
      }
    }

    if (coinAmount !== 0) {
      let transactionRecord = {};
      transactionRecord["source"] = `Admin - ${this.props.user.firstName} ${
        this.props.user.lastName
      }`;
      transactionRecord["description"] = values.description;
      transactionRecord["employee_ids"] = this.getSelectedEmployeeIds();
      transactionRecord["amount"] = coinAmount;

      const URL = `${process.env.REACT_APP_API_URL}/rewards/coin`;
      const payload = transactionRecord;
      try {
        await Axios.put(URL, payload);
      } catch (err) {
        coinSuccess = false;
        console.log(err);
      }
    }

    this.setState({ submitSuccess: bucksSuccess && spcSuccess && coinSuccess });
    this.setActiveScreen("result");
  };

  resetForm = () => {
    if (this.formik.current) {
      this.formik.current.resetForm();
    }
  };

  startAgain = () => {
    this.removeSelectedStudents();
    this.clearFilter();
    this.setActiveScreen("select");
  };

  render() {
    const {
      loading,
      users,
      filteredUsers,
      searchValueIdName,
      searchValueCompany,
      searchValuePosition,
      activeScreen,
      submitSuccess
    } = this.state;
    return (
      <DocumentTitle title="Update Accounts - Borger Team">
        <div>
          <div className="card">
            <div className="card-body">
              <h1>Update Accounts</h1>
              <br />
              {activeScreen === "select" && (
                <div>
                  {this.countSelectedStudents() > 0 ? (
                    <div>
                      <br clear="all" />
                      <div className="float-right">
                        <button
                          onClick={() => this.setActiveScreen("adjust")}
                          type="button"
                          className="btn btn-primary btn-round d-block"
                        >
                          Next Step
                        </button>
                      </div>
                    </div>
                  ) : null}

                  <h3>Search</h3>
                  <div className="row">
                    <div className="form-group mx-2">
                      <label htmlFor="idNameSearch">ID or name:</label>{" "}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchValueIdName}
                        onChange={this.searchIdName}
                        id="idNameSearch"
                        style={{ width: 200 }}
                      />
                    </div>

                    <div className="form-group mx-2">
                      <label htmlFor="companySearch">Company:</label>{" "}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchValueCompany}
                        onChange={this.searchCompany}
                        id="companySearch"
                        style={{ width: 200 }}
                      />
                    </div>

                    <div className="form-group mx-2">
                      <label htmlFor="positionSearch">Position:</label>{" "}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchValuePosition}
                        onChange={this.searchPosition}
                        id="positionSearch"
                        style={{ width: 200 }}
                      />
                    </div>
                  </div>
                  <br />

                  <div className="container-column-75">
                    <h3>Step one - Select employees</h3>
                    <div className="row float-right">
                      <button
                        onClick={this.clearFilter}
                        type="button"
                        className="btn btn-primary btn-round d-block btn-sm m-1"
                      >
                        Clear Filter
                      </button>
                      <button
                        onClick={this.selectAllUsers}
                        type="button"
                        className="btn btn-primary btn-round d-block btn-sm m-1"
                      >
                        Company Wide Adjustment
                      </button>
                      <button
                        onClick={this.selectFilteredUsers}
                        type="button"
                        className="btn btn-primary btn-round d-block btn-sm m-1"
                      >
                        Select List
                      </button>
                    </div>

                    <div className="table-responsive" style={{ height: 400 }}>
                      <table className="table table-striped table-bordered card-table">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Company</th>
                            <th>Position</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {filteredUsers.map(user =>
                            !user.isSelectedUser ? (
                              <tr key={user.employee_id}>
                                <td className="align-middle">{user.emplid}</td>
                                <td className="align-middle">
                                  {user.student_id ? (
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.toggleSelectedUser(
                                          user.employee_id
                                        )
                                      }
                                    >
                                      {`${user.first_name} ${user.last_name}`}
                                    </span>
                                  ) : (
                                    `${user.first_name} ${user.last_name}`
                                  )}
                                </td>
                                <td className="align-middle">
                                  {user.email_address}
                                </td>
                                <td className="align-middle">{user.company}</td>
                                <td className="align-middle">
                                  {user.position}
                                </td>
                                <td className="align-middle">
                                  {user.student_id ? (
                                    <button
                                      onClick={() =>
                                        this.toggleSelectedUser(
                                          user.employee_id
                                        )
                                      }
                                      type="button"
                                      className="btn btn-primary btn-round d-block btn-sm"
                                    >
                                      Select
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-secondary btn-round d-block btn-sm"
                                      disabled="true"
                                    >
                                      Invalid
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ) : null
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="card-body py-0">
                      <div className="row">
                        <div className="col-sm text-sm-left text-center pt-3 pb-3">
                          <span className="text-muted">
                            {loading ? "Loading..." : null}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-column-25">
                    <h3>Selected employees</h3>
                    <div className="float-right">
                      <button
                        onClick={this.removeSelectedStudents}
                        type="button"
                        className="btn btn-danger btn-round d-block btn-sm"
                      >
                        Remove All
                      </button>
                    </div>
                    <div className="table-responsive" style={{ height: 400 }}>
                      <table className="table table-striped table-bordered card-table">
                        <tbody>
                          {users.map(user =>
                            user.isSelectedUser ? (
                              <tr key={user.employee_id}>
                                <td className="align-middle">
                                  {`${user.first_name} ${user.last_name} (${
                                    user.emplid
                                  })`}{" "}
                                  <button
                                    onClick={() =>
                                      this.toggleSelectedUser(user.employee_id)
                                    }
                                    type="button"
                                    className="btn btn-danger btn-round btn-sm"
                                  >
                                    <i className="ion ion-md-close" />
                                  </button>
                                </td>
                              </tr>
                            ) : null
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {this.countSelectedStudents() > 0 ? (
                    <div>
                      <br clear="all" />
                      <div className="float-right">
                        <button
                          onClick={() => this.setActiveScreen("adjust")}
                          type="button"
                          className="btn btn-primary btn-round d-block"
                        >
                          Next Step
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
              {activeScreen === "adjust" && (
                <div>
                  <h3>Step two - Update account</h3>
                  Number of employees selected: {this.countSelectedStudents()}
                  <br />
                  <Formik
                    ref={this.formik}
                    initialValues={{
                      bucksAmount: "0",
                      spcAmount: "0",
                      coinAmount: "0",
                      description: ""
                    }}
                    validationSchema={this.accountUpdatesSchema}
                    onSubmit={async (values, utils) => {
                      await this.submitAssignment(values);
                      //utils.setSubmitting(false);
                      //utils.resetForm();
                    }}
                  >
                    {({
                      values,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      isValid,
                      handleSubmit,
                      touched,
                      errors
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className="needs-validation"
                        noValidate
                      >
                        <div className="row">
                          <div className="col-md-2">
                            <div className="form-group m-1">
                              <label
                                className="form-label"
                                htmlFor="bucksAmount"
                              >
                                Borger Bucks
                              </label>
                              <input
                                id="bucksAmount"
                                name="bucksAmount"
                                type="text"
                                className={
                                  touched.bucksAmount && errors.bucksAmount
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                required
                                value={values.bucksAmount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                name="bucksAmount"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group m-1">
                              <label className="form-label" htmlFor="spcAmount">
                                SPC Award
                              </label>
                              <input
                                id="spcAmount"
                                name="spcAmount"
                                type="text"
                                className={
                                  touched.spcAmount && errors.spcAmount
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                required
                                value={values.spcAmount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                name="spcAmount"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group m-1">
                              <label
                                className="form-label"
                                htmlFor="coinAmount"
                              >
                                Innovation Coin
                              </label>
                              <input
                                id="coinAmount"
                                name="coinAmount"
                                type="text"
                                className={
                                  touched.coinAmount && errors.coinAmount
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                required
                                value={values.coinAmount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                name="coinAmount"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="form-label" htmlFor="description">
                            Message
                          </label>
                          <textarea
                            id="description"
                            name="description"
                            className={
                              touched.description && errors.description
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            rows="6"
                            required
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="row">
                          <button
                            onClick={() => this.setActiveScreen("select")}
                            type="button"
                            className="btn btn-primary btn-round d-block m-1"
                          >
                            Previous Step
                          </button>

                          <button
                            type="submit"
                            className="btn btn-primary btn-round d-block m-1"
                            disabled={!isValid || isSubmitting}
                          >
                            Submit Adjustment
                          </button>

                          <button
                            onClick={this.resetForm}
                            type="button"
                            className="btn btn-primary btn-round d-block m-1"
                          >
                            Reset Form
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              )}
              {activeScreen === "result" && (
                <div>
                  <h3>Result</h3>
                  {submitSuccess
                    ? "The update was successful."
                    : "An error occured while attempting to update the accounts."}
                  <br />
                  <br />
                  <button
                    onClick={this.startAgain}
                    type="button"
                    className="btn btn-primary btn-round d-block"
                  >
                    Start Again
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

const protectedPage = withAuthorization(
  user => hasPermission(user, PERMISSIONS.accounts),
  () => routes.DASHBOARD
);

export default protectedPage(AccountUpdates);
