import React, { Component } from "react";
import PropTypes from "prop-types";
import DocumentTitle from "react-document-title";
import Axios from "axios";
import { withAuthorization } from "../Auth";
import * as routes from "../../config/routes";
import { hasPermission, PERMISSIONS } from "../../config/permissions";
import GeneralMessagingForm from "./GeneralMessagingForm";
import GeneralMessagingList from "./GeneralMessagingList";

class GeneralMessaging extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  state = {
    messages: []
  };

  componentDidMount() {
    this.fetchMessages();
  }

  fetchMessages = async (category = "all", page = 0) => {
    const URL = `${
      process.env.REACT_APP_API_URL
    }/message/retrieve/${category}/${page}`;

    const response = await Axios.get(URL);
    this.setState({ messages: response.data });
  };

  submit = async payload => {
    const URL = `${process.env.REACT_APP_API_URL}/message/send`;
    payload.createdBy = this.props.user.id;

    await Axios.post(URL, payload);
    if (!this.state.messages.length) return this.fetchMessages();

    const message = {
      id: this.state.messages[0].id + 1,
      createdAt: Date.now(),
      ...payload
    };

    this.setState(currentState => ({
      messages: [message, ...currentState.messages]
    }));
  };

  delete = async id => {
    const URL = `${process.env.REACT_APP_API_URL}/message/${id}`;

    await Axios.delete(URL);
    this.setState(({ messages }) => ({
      messages: messages.filter(m => m.id !== id)
    }));
  };

  render() {
    const { messages } = this.state;
    return (
      <DocumentTitle title="Team Messaging - Borger Team">
        <>
          <GeneralMessagingForm onSubmit={this.submit} />
          <GeneralMessagingList messages={messages} onDelete={this.delete} />
        </>
      </DocumentTitle>
    );
  }
}

const protectedPage = withAuthorization(
  user => hasPermission(user, PERMISSIONS.messaging),
  () => routes.DASHBOARD
);

export default protectedPage(GeneralMessaging);
