import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { withUser } from "../Auth";
import { hasPermission } from "../../config/permissions";

class SideNavItem extends React.Component {
  componentDidMount() {
    this.props.initialize(this.props.to);
  }

  render() {
    const { user, permission, to, children, iconClassName } = this.props;

    if (permission && user && !hasPermission(user, permission)) return null;

    return (
      <NavLink to={to} className="sidenav-item" activeClassName="active">
        <span className="sidenav-link">
          {iconClassName && (
            <i className={`sidenav-icon text-center ion ${iconClassName}`} />
          )}
          <div>{children}</div>
        </span>
      </NavLink>
    );
  }
}

SideNavItem.propTypes = {
  user: PropTypes.object,
  permission: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  iconClassName: PropTypes.string,
  initialize: PropTypes.func
};

SideNavItem.defaultProps = {
  iconClassName: "",
  permission: "",
  initialize: () => {},
  user: null
};

export default withUser(SideNavItem);
