import React from "react";
import PropTypes from "prop-types";
import DocumentTitle from "react-document-title";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import * as routes from "../../config/routes";
import DisplayLogin from "./DisplayLogin";
import { AuthContext } from "../Auth";

class Login extends React.Component {
  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  state = {
    hasError: false
  };

  componentDidMount() {
    if (this.props.auth.user) {
      this.props.history.push(routes.DASHBOARD);
    }
  }

  login = async (payload, authenticate) => {
    const URL = `${process.env.REACT_APP_API_URL}/login`;
    try {
      const response = await Axios.post(URL, payload);

      authenticate(response.data, () => {
        this.props.history.push(routes.DASHBOARD);
      });
    } catch (e) {
      this.setState({ hasError: true });
    }
  };

  handleSubmit = payload => {
    this.setState({ hasError: false }, () => {
      this.login(payload, this.props.auth.authenticate);
    });
  };

  render() {
    const { hasError } = this.state;
    return (
      <DocumentTitle title="Login - Borger Team">
        <DisplayLogin onSubmit={this.handleSubmit} hasError={hasError} />
      </DocumentTitle>
    );
  }
}

const AuthLogin = props => (
  <AuthContext.Consumer>
    {auth => <Login {...props} auth={auth} />}
  </AuthContext.Consumer>
);

export default withRouter(AuthLogin);
