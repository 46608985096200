import React from "react";
import Storage from "./";

const withStorage = Component => {
  function Wrapper(props) {
    return (
      <Storage {...props}>
        {storage => <Component {...props} storage={storage} />}
      </Storage>
    );
  }

  Wrapper.displayName = `withStorage(${Component.displayName ||
    Component.name})`;

  return Wrapper;
};

export default withStorage;
