import React, { Component } from "react";
import Axios from "axios";
import { withAuthorization } from "../Auth";
import * as routes from "../../config/routes";
import { hasPermission, PERMISSIONS } from "../../config/permissions";
import DocumentTitle from "react-document-title";
import CourseList from './CourseList'

class LearningAdmin extends Component {
  state = {
    searchValue: '',
    loading: true,
    courses: [],
    filteredCourses: [],
  };

  componentDidMount() {
    this.fetch();
  }

  filter = () => {
    const { courses, searchValue } = this.state;

    const filteredCourses = courses.filter(
      ({ id, name, category }) =>
        name.toLowerCase().includes(searchValue.toLowerCase()) ||
        id.toString().includes(searchValue.toLowerCase()) ||
        category.toLowerCase().includes(searchValue.toLowerCase())
    );

    this.setState({ filteredCourses });
  };

  onSearchChange = e => {
    this.setState({ searchValue: e.target.value }, this.filter);
  };

  fetch = async () => {
    const URL = `${process.env.REACT_APP_API_URL}/learning/courses`

    const response = await Axios.get(URL)

    const courses = response.data
    this.setState({ courses, filteredCourses: courses, loading: false })
  };

  updateCreditAndPay = async (id, credit, pay) => {
    const URL = `${process.env.REACT_APP_API_URL}/learning/courses/${id}`;

    const payload = { credits: credit.toString(), education_pay: pay.toString() }
    await Axios.put(URL, payload);

    this.setState(currentState => {
      const courses = currentState.courses.map(course =>
        course === id ? { ...course, credit, pay } : course
      );
      return {
        courses
      };
    }, this.filter);
  };

  render() {
    const { searchValue, loading, filteredCourses } = this.state;
    return (
      <DocumentTitle title="Courses - Borger Team">
        <div>
          <div className="card">
            <div className="card-body">
              <h1>Courses</h1><br />
              Click on <b>CREDITS</b> or <b>EDUCATION PAY</b> to edit the values.  Click <b>ENTER</b> to save the new value.<br />
              The contents of this list are maintained by an integration with Borger U.  Add or archive courses from Borger U.<br />
              <br />
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control d-inline-block"
                    placeholder="Search..."
                    value={searchValue}
                    onChange={this.onSearchChange}
                  /><br /><br />
                </div>
              </div>
              <CourseList
                courses={filteredCourses}
                loading={loading}
                updateCreditAndPay={this.updateCreditAndPay}
              />
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

const protectedPage = withAuthorization(
  user => hasPermission(user, PERMISSIONS.learning),
  () => routes.DASHBOARD
);

export default protectedPage(LearningAdmin);
