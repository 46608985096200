import React from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";

class MapContainer extends React.Component {
  render() {
    return (
      <Map
        google={this.props.google}
        style={{ width: "95%" }}
        zoom={14}
        {...this.props}
      >
        {this.props.children}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  LoadingContainer: () => <div>Loading map...</div>
})(MapContainer);
