import React, { Component } from "react";
import PropTypes from "prop-types";

class Course extends Component {
  state = {
    editActiveCredit: false,
    editActivePay: false,
    credit: this.props.course.credits,
    pay: this.props.course.education_pay,
  };

  static propTypes = {
    course: PropTypes.object.isRequired
  };

  toggleEditCredit = () => {
    this.setState(currentState => {
      return {
        editActiveCredit: !currentState.editActiveCredit
      }
    })
  }

  onCreditChange = (e) => {
    this.setState({ credit: e.target.value })
  }

  submitCredit = (e) => {
    e.preventDefault()
    this.props.updateCreditAndPay(this.props.course.id, this.state.credit, this.state.pay)
    this.toggleEditCredit()
  }

  toggleEditPay = () => {
    this.setState(currentState => {
      return {
        editActivePay: !currentState.editActivePay
      }
    })
  }

  onPayChange = (e) => {
    this.setState({ pay: e.target.value })
  }

  submitPay = (e) => {
    e.preventDefault()
    this.props.updateCreditAndPay(this.props.course.id, this.state.credit, this.state.pay)
    this.toggleEditPay()
  }

  render() {
    const {
      course
    } = this.props;

    const {
      editActiveCredit,
      editActivePay,
      credit,
      pay,
    } = this.state;

    return (
      <>
        <tr>
          <td className="align-middle">{course.id}</td>
          <td className="align-middle">{course.name}</td>
          <td className="align-middle">{course.category}</td>
          <td className="align-middle">{editActiveCredit ?
            <form id="creditForm" onSubmit={this.submitCredit}>
              <input type="text" id="creditEdit" name="creditName" size="3" value={credit} onChange={this.onCreditChange} autoFocus />
            </form>
            :
            <button
              type="button"
              className="btn btn-link"
              onClick={this.toggleEditCredit}
            >
              {credit}
            </button>
          }
          </td>
          <td className="align-middle">{editActivePay ?
            <form id="payForm" onSubmit={this.submitPay}>
              <input type="text" id="payEdit" name="payName" size="3" value={pay} onChange={this.onPayChange} autoFocus />
            </form>
            :
            <button
              type="button"
              className="btn btn-link"
              onClick={this.toggleEditPay}
            >
              {pay}
            </button>
          }</td>
          <td className="align-middle">{course.enrolled}</td>
          <td className="align-middle">{course.completed}</td>
        </tr>
      </>
    );
  }
}

export default Course;
