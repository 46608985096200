import "core-js/es6";
import "core-js/es7";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./vendor/styles/bootstrap.scss";
import "./vendor/fonts/fontawesome.css";
import "./vendor/fonts/ionicons.css";
import "./vendor/fonts/linearicons.css";
import "./vendor/styles/appwork.scss";
import "./vendor/styles/theme-twitlight.scss";
import "./vendor/styles/colors.scss";
import "./vendor/styles/uikit.scss";
import Pace from "pace-js";
import "pace-js/themes/yellow/pace-theme-flash.css";
import "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
Pace.start();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
