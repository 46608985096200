import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import Axios from "axios";
import * as Yup from "yup";

class AddressForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    address: PropTypes.object
  };

  static defaultProps = {
    address: {}
  };

  state = {
    error: false
  };

  initialValues = {
    address: this.props.address.address || "",
    city: this.props.address.city || "",
    province: this.props.address.province || "",
    country: this.props.address.country || ""
  };

  validationSchema = Yup.object().shape({
    address: Yup.string().required(),
    city: Yup.string().required(),
    province: Yup.string().required(),
    country: Yup.string().required()
  });

  // address=1600+Amphitheatre+Parkway,+Mountain+View,+CA
  formatLocation(location) {
    return Object.values(location)
      .reduce((location, value, idx) => {
        let formattedValue = idx > 0 ? `,+${value}` : value;

        return `${location}${formattedValue}`;
      }, "")
      .replace(/ /g, "+")
      .replace(/&/g, "");
  }

  async getCoordinates(location) {
    const URL = "https://maps.googleapis.com/maps/api/geocode/json";
    const response = await Axios.get(URL, {
      params: {
        address: this.formatLocation(location),
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      }
    });

    if (!response.data.results.length) {
      throw new Error();
    }

    return response.data.results[0].geometry.location;
  }

  handleSubmit = async (values, utils) => {
    try {
      const { lat, lng } = await this.getCoordinates(values, utils.resetForm);

      const response = {
        lat: () => lat,
        lng: () => lng,
        address: values
      };

      this.props.onSubmit(response);
    } catch (e) {
      this.setState({ error: true });
      utils.setSubmitting(false);
      utils.resetForm();
    }
  };

  render() {
    const { error } = this.state;
    return (
      <Formik
        initialValues={this.initialValues}
        validationSchema={this.validationSchema}
        onSubmit={this.handleSubmit}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          dirty,
          isValid,
          isSubmitting,
          handleSubmit
        }) => (
          <div className="needs-validation">
            <div className="form-group">
              <label className="form-label" htmlFor="address">
                Address
              </label>
              <input
                id="address"
                name="address"
                type="text"
                className="form-control"
                placeholder="street address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="form-label" htmlFor="city">
                  City
                </label>
                <input
                  id="city"
                  name="city"
                  type="text"
                  className="form-control"
                  placeholder="city"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              <div className="form-group col-md-3">
                <label className="form-label" htmlFor="province">
                  Prov
                </label>
                <input
                  id="province"
                  name="province"
                  type="text"
                  className="form-control"
                  placeholder="province"
                  value={values.province}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              <div className="form-group col-md-3">
                <label className="form-label" htmlFor="country">
                  Country
                </label>
                <input
                  id="country"
                  name="country"
                  type="text"
                  className="form-control"
                  placeholder="country"
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>

            {dirty && (
              <div className="text-center">
                <em className={!values.address ? "text-danger" : ""}>
                  {values.address || "(Address)"},{" "}
                </em>
                <em className={!values.city ? "text-danger" : ""}>
                  {values.city || "(City)"},{" "}
                </em>
                <em className={!values.province ? "text-danger" : ""}>
                  {values.province || "(Province)"},{" "}
                </em>
                <em className={!values.country ? "text-danger" : ""}>
                  {values.country || "(Country)"}
                </em>
              </div>
            )}
            <div className="text-center">
              <button
                onClick={handleSubmit}
                aria-disabled={!isValid || isSubmitting}
                disabled={!isValid || isSubmitting}
                type="button"
                className="btn btn-xs btn-primary"
              >
                Get coordinates
              </button>
              {error && (
                <div className="text-danger">
                  <em>Invalid address, please try again.</em>
                </div>
              )}
            </div>
          </div>
        )}
      </Formik>
    );
  }
}

export default AddressForm;
