import React from "react";
import PropTypes from "prop-types";

const CareersPostingFilter = ({
  search,
  searchValue,
  filterField,
  onFilterChange
}) => {
  return (
    <>
      <input
        type="text"
        className="form-control d-inline-block mb-2"
        placeholder="Search..."
        onChange={search}
        value={searchValue}
      />
      <div className="d-flex flex-column flex-sm-row">
        <div className="row">
          <div className="col m-auto">
            <i className="ion ion-md-funnel" />
            <em className="ml-1">Filter by: </em>
          </div>
          <div className="col btn-group btn-group-toggle my-1 d-flex align-items-center justify-content-end">
            <label
              className={
                filterField === "all"
                  ? "btn btn-sm btn-outline-primary active"
                  : "btn btn-sm btn-outline-primary"
              }
            >
              <input
                type="radio"
                autoComplete="off"
                value="all"
                onChange={onFilterChange}
                checked={filterField === "all"}
              />
              All
            </label>
            <label
              className={
                filterField === "active"
                  ? "btn btn-sm btn-outline-primary active"
                  : "btn btn-sm btn-outline-primary"
              }
            >
              <input
                type="radio"
                autoComplete="off"
                value="active"
                onChange={onFilterChange}
                checked={filterField === "active"}
              />
              Active
            </label>
            <label
              className={
                filterField === "inactive"
                  ? "btn btn-sm btn-outline-primary active"
                  : "btn btn-sm btn-outline-primary"
              }
            >
              <input
                type="radio"
                autoComplete="off"
                value="inactive"
                onChange={onFilterChange}
                checked={filterField === "inactive"}
              />
              Inactive
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

CareersPostingFilter.propTypes = {
  search: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  filterField: PropTypes.string
};

CareersPostingFilter.defaultProps = {
  filterField: "",
  searchValue: ""
};

export default CareersPostingFilter;
