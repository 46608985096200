import React from "react";
import SideNavItem from "./SideNavItem";
import SideNavMenu from "./SideNavMenu";
import * as routes from "../../config/routes";

const AdminSideNav = () => {
  return (
    <>
      <li className="sidenav-header small font-weight-semibold">ADMIN</li>
      <SideNavItem
        iconClassName="ion-md-contacts"
        to="/user"
        permission="users"
      >
        User Management
      </SideNavItem>
      <SideNavMenu
        text="Team Messaging"
        iconClassName="ion-md-chatboxes"
        permission="messaging"
      >
        <SideNavItem to={routes.MESSAGING.general}>General</SideNavItem>
        <SideNavItem to={routes.MESSAGING.calls}>Calls</SideNavItem>
      </SideNavMenu>
      <SideNavItem
        iconClassName="ion-md-cash"
        to="/to-do-awards"
        permission="awards"
      >
        Awards Admin
      </SideNavItem>
      <SideNavItem
        iconClassName="ion-md-bulb"
        to="/to-do-innvation"
        permission="awards"
      >
        Innovation Admin
      </SideNavItem>
      <SideNavMenu
        text="Learning Admin"
        iconClassName="ion-md-desktop"
        permission="learning"
      >
        <SideNavItem to={routes.LEARNING.admin}>Courses</SideNavItem>
        <SideNavItem to={routes.LEARNING.courseassign}>Assignment</SideNavItem>
      </SideNavMenu>
      <SideNavItem
        iconClassName="ion-md-basket"
        to="/to-do-store"
        permission="store"
      >
        Store Admin
      </SideNavItem>
      <SideNavItem
        iconClassName="ion-md-construct"
        to={routes.PROJECTS}
        permission="projects"
      >
        Projects Admin
      </SideNavItem>
      <SideNavMenu
        text="Career Admin"
        iconClassName="ion-md-fitness"
        permission="careers"
      >
        <SideNavItem to={routes.CAREERS.postings}>Postings</SideNavItem>
        <SideNavItem to={routes.CAREERS.applicants}>Applicants</SideNavItem>
      </SideNavMenu>
      <SideNavMenu
        text="Borger Accounts"
        iconClassName="ion-md-filing"
        permission="accounts"
      >
        <SideNavItem to={routes.ACCOUNTS.transactions}>
          Transactions
        </SideNavItem>
        <SideNavItem to={routes.ACCOUNTS.update}>Update Accounts</SideNavItem>
      </SideNavMenu>
      <SideNavItem
        iconClassName="ion-md-photos"
        to={routes.GALLERYADMIN}
        permission="gallery"
      >
        Gallery Admin
      </SideNavItem>
    </>
  );
};

export default AdminSideNav;
