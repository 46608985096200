import React from "react";
import PropTypes from "prop-types";
import Filter from "../common/Filter";

const UsersFilters = ({
  filterField,
  onFilterChange
}) => {
  const filters = [
    {
      name: "isActive",
      options: [
        { path: "all", label: "All" },
        { path: "active", label: "Active" },
        { path: "inactive", label: "Inactive" },
      ]
    },
    {
      name: "isEligible",
      options: [
        { path: "all", label: "All" },
        { path: "eligible", label: "Eligible" },
        { path: "notEligible", label: "Not eligible" },
      ]
    },
    {
      name: "isNexus",
      options: [
        { path: "all", label: "All" },
        { path: "nexus", label: "Nexus" },
        { path: "notNexus", label: "Not nexus" },
      ]
    },
    {
      name: "accessLevel",
      options: [
        { path: "all", label: "All" },
        { path: "admin", label: "Admin" },
        { path: "careerAdmin", label: "Career admin" },
        { path: "borgerAppAdmin", label: "Borger app admin" },
        { path: "employee", label: "Employee" },
      ]
    },
    {
      name: "company",
      options: [
        { path: "all", label: "All" },
        { path: "WRD", label: "WRD" },
        { path: "earthworks", label: "Earthworks" },
        { path: "transport", label: "Transport" },
      ]
    },
    {
      name: "contributorLevel",
      options: [
        { path: "all", label: "All" },
        { path: "individual", label: "Individual" },
        { path: "supervisor", label: "Supervisor" },
        { path: "manager", label: "Manager" },
      ]
    },
    {
      name: "position",
      options: [
        { path: "all", label: "All" },
        { path: "office", label: "Office" },
        { path: "field", label: "Field" },
      ]
    },
    {
      name: "clearAll",
      options: [
        { path: true, label: "Clear all filters" },
      ]
    },

  ]
  return (
    <>
      <div>
        <div className="m-4 mt-0" >
          <i className="ion ion-md-funnel" />
          <em className="ml-1">Filter by: </em>
        </div>
        <div className="mx-4 my-0 d-flex justify-content-start flex-wrap">
          {filters.map(({ name, options }) => (
            <Filter key={name} filterField={filterField} onFilterChange={onFilterChange} name={name} options={options} />
          ))}
        </div>
      </div>
    </>
  );
};

UsersFilters.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  filterField: PropTypes.object.isRequired,
};


export default UsersFilters;
