import React from "react";
import PropTypes from "prop-types";
import Course from "./Course";

const CourseList = ({
  courses,
  loading,
  updateCreditAndPay
}) => {
  return (
    <div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered card-table">
          <thead>
            <tr>
              <th className="text-nowrap">Course ID</th>
              <th className="text-nowrap" style={{ minWidth: "18rem" }}>
                Name
              </th>
              <th className="text-nowrap">Category</th>
              <th className="text-nowrap">Credits</th>
              <th className="text-nowrap">Education Pay</th>
              <th className="text-nowrap">Enrolled</th>
              <th className="text-nowrap">Completed</th>
            </tr>
          </thead>
          <tbody>
            {courses.map(course => (
              <Course
                key={course.id}
                course={course}
                updateCreditAndPay={updateCreditAndPay}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="card-body py-0">
        <div className="row">
          <div className="col-sm text-sm-left text-center pt-3 pb-3">
            <span className="text-muted">
              {loading ? "Loading..." : `Total items: ${courses.length}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

CourseList.propTypes = {
  courses: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

export default CourseList;
