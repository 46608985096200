import React from "react";
import PropTypes from "prop-types";
import withStorage from "./withStorage";

class Storage extends React.Component {
  static propTyps = {
    children: PropTypes.func.isRequired
  };

  getItem = key => localStorage.getItem(key);

  setItem = (key, data) => {
    localStorage.setItem(key, data);
  };

  removeItem = key => {
    localStorage.removeItem(key);
  };

  render() {
    return this.props.children({
      getItem: this.getItem,
      setItem: this.setItem,
      removeItem: this.removeItem
    });
  }
}

export { Storage as default, withStorage };
