import React from "react";
import PropTypes from "prop-types";
import Project from "./Project";

const ProjectsList = ({
  projects,
  onEditClick,
  onDeleteClick,
  searchValue,
  search,
  loading
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control d-inline-block"
              placeholder="Search..."
              value={searchValue}
              onChange={search}
            />
          </div>
        </div>
      </div>
      <hr className="border-light m-0" />
      <div className="table-responsive">
        <table className="table table-striped table-bordered card-table">
          <thead>
            <tr>
              <th className="text-nowrap">ID</th>
              <th className="text-nowrap" style={{ minWidth: "18rem" }}>
                Name
              </th>
              <th className="text-nowrap">Directions</th>
              <th className="text-nowrap">Active</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {projects.map(project => (
              <Project
                key={project.id}
                project={project}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="card-body py-0">
        <div className="row">
          <div className="col-sm text-sm-left text-center pt-3 pb-3">
            <span className="text-muted">
              {loading ? "Loading..." : `Total items: ${projects.length}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectsList.propTypes = {
  projects: PropTypes.array.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  search: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default ProjectsList;
