import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import * as routes from "../../config/routes";
import Logo from "../../static/images/logo/borger_logo.png";
import { withTheme } from "../Theme";
import { AuthContext } from "../Auth";

class LayoutNavbar extends Component {
  static propTypes = {
    theme: PropTypes.object.isRequired
  };

  static contextType = AuthContext;

  render() {
    const { theme } = this.props;
    return (
      <React.Fragment>
        <nav
          className={`layout-navbar navbar navbar-expand-lg align-items-lg-center container-p-x ${
            theme.layoutNavbarBg
          }`}
        >
          <Link to={routes.DASHBOARD} className="navbar-brand">
            <img src={Logo} alt="Borger Logo" />
          </Link>

          <div className="navbar-nav align-items-lg-center mr-auto mr-lg-4">
            <button
              type="button"
              className="nav-item nav-link px-0 ml-2 btn btn-link"
              onClick={theme.toggleCollapsed}
            >
              <i className="ion ion-md-menu text-large align-middle" />
            </button>
          </div>

          <div className="navbar-nav align-items-lg-center ml-auto">
            <button
              className="btn btn-round btn-secondary"
              onClick={this.context.unauthenticate}
            >
              LOG OUT
            </button>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default withTheme(LayoutNavbar);
