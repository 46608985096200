import React from "react";
import PropTypes from "prop-types";
import scrollToTop from "../../utils/scrollToTop";

class DeleteConfirmation extends React.Component {
  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate() {
    scrollToTop();
  }

  render() {
    const { resource, name, onCancelClick, onDeleteClick } = this.props;
    return (
      <div className="card card-default mb-3">
        <div className="card-header">
          <div className="h4">
            Are you sure you want to delete{" "}
            <em className="font-weight-bold">{name}?</em>
            <button
              onClick={onCancelClick}
              type="button"
              className="float-right btn btn-xs btn-oval btn-outline-primary"
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="card-body">
          <button
            onClick={() => onDeleteClick(resource)}
            type="button"
            className="btn btn-danger mb-2"
          >
            Confirm delete
          </button>
        </div>
      </div>
    );
  }
}
DeleteConfirmation.propTypes = {
  name: PropTypes.string.isRequired,
  resource: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};
export default DeleteConfirmation;
