import React, { Component } from "react";
import PropTypes from "prop-types";
import DocumentTitle from "react-document-title";
import Axios from "axios";
import * as _ from "lodash";
import Lightbox from "lightbox-react";
import "lightbox-react/style.css"; // This only needs to be imported once in your app
import * as routes from "../../config/routes";
import { hasPermission, PERMISSIONS } from "../../config/permissions";
import { withAuthorization } from "../Auth";
import File from "./File";
import FileEditForm from "./FileEditForm";
import FileDeleteConfirmation from "./FileDeleteConfirmation";
import { Tooltip } from "reactstrap";
import GalleryDeleteConfirmation from "./GalleryDeleteConfirmation";
import GalleryCreateEditForm from "./GalleryCreateEditForm";
import GalleryItem from "./GalleryItem";

class GalleryAdmin extends Component {
  state = {
    showField: "all",
    filterField: "all",
    searchValue: "",
    files: [],
    filteredFiles: [],
    tags: [],
    lightboxPhotoIndex: 0,
    lightboxIsOpen: false,
    lightboxFiles: [],
    filesCurrentAction: "",
    selectedImage: {},
    selectedGallery: 0,
    galleries: [],
    activeGalleryFiles: [],
    galleryEditTooltipOpen: false,
    galleryDeleteTooltipOpen: false,
    galleriesCurrentAction: "",
    galleryIsChanged: false,
    galleryIsSaving: false
  };

  static propTypes = {
    user: PropTypes.object.isRequired
  };

  actions = {
    create: "CREATE",
    update: "UPDATE",
    delete: "DELETE"
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    // fetch the files
    let URL = `${process.env.REACT_APP_API_URL}/gallery/files`;
    let response = await Axios.get(URL);

    const files = response.data;
    const arraysOfTags = files.map(gf => {
      try {
        const array = JSON.parse(gf.tags);
        gf.tags = array.constructor === Array ? array : [];
      } catch (err) {
        gf.tags = [];
      }
      return gf.tags;
    });
    const tags = _.union(...arraysOfTags).sort();

    // fetch the galleries
    URL = `${process.env.REACT_APP_API_URL}/gallery`;
    response = await Axios.get(URL);

    const galleries = response.data;

    this.setState(
      {
        files,
        filteredFiles: files,
        tags,
        galleries
      },
      () => {
        this.updateActiveGalleryFiles();
      }
    );
  };

  filter = () => {
    this.setState(state => {
      let fgf = state.files;

      // check show state
      if (state.showField === "notInGallery") {
        const galleryFilesArrays = state.galleries.map(g =>
          g.images.map(i => i.file_id)
        );
        const allGalleryFiles = _.union(...galleryFilesArrays);
        fgf = fgf.filter(gf => !_.includes(allGalleryFiles, gf.id));
      }
      // check filter tags
      if (state.filterField !== "all") {
        fgf = fgf.filter(gf => {
          return _.includes(gf.tags, state.filterField);
        });
      }
      // search string
      fgf = fgf.filter(gf => {
        return gf.tags.some(x => x.includes(state.searchValue.toLowerCase())) ||
          !gf.title
          ? true
          : gf.title.toLowerCase().includes(state.searchValue.toLowerCase()) ||
            !gf.note
          ? true
          : gf.note.toLowerCase().includes(state.searchValue.toLowerCase());
      });
      return { filteredFiles: fgf };
    });
  };

  setFilesCurrentAction = (filesCurrentAction = "", selectedImage = {}) => {
    this.setState({ filesCurrentAction, selectedImage });
  };

  onSearchChange = e => {
    this.setState({ searchValue: e.target.value }, this.filter);
  };

  onFilterChange = filterField => {
    this.setState({ filterField }, this.filter);
  };

  onShowChange = showField => {
    this.setState({ showField }, this.filter);
  };

  openLightboxAt = (which, index) => {
    this.setState(currentState => {
      let lightboxFiles = [];
      if (which === "files") {
        lightboxFiles = currentState.filteredFiles;
      } else {
        lightboxFiles = currentState.activeGalleryFiles;
      }

      return { lightboxIsOpen: true, lightboxPhotoIndex: index, lightboxFiles };
    });
  };

  updateFile = async payload => {
    let url = `${process.env.REACT_APP_API_URL}/gallery/file/${
      this.state.selectedImage.id
    }/meta`;

    const galleryFile = { ...payload };
    galleryFile.updated_by = this.props.user.id;

    let tags = galleryFile.tags.split(",").map(tag => tag.toLowerCase().trim());
    tags = tags.filter(tag => tag !== "");
    galleryFile.tags = JSON.stringify(tags);

    await Axios.put(url, galleryFile);

    url = `${process.env.REACT_APP_API_URL}/gallery`;
    const response = await Axios.get(url);
    const galleries = response.data;

    const selectedImage = { ...this.state.selectedImage };
    selectedImage.title = galleryFile.title;
    selectedImage.note = galleryFile.note;
    selectedImage.tags = tags;

    this.setState(
      currentState => {
        // enter the updated image info into the array
        const files = currentState.files.map(gf =>
          gf.id === currentState.selectedImage.id ? selectedImage : gf
        );
        // recompute the tags
        const arraysOfTags = files.map(gf => gf.tags);
        const tags = _.union(...arraysOfTags).sort();
        return {
          files,
          tags,
          selectedImage: {},
          galleries,
          galleryIsChanged: false
        };
      },
      () => {
        this.filter();
        this.updateActiveGalleryFiles();
      }
    );
  };

  deleteFile = async galleryFile => {
    let url = `${process.env.REACT_APP_API_URL}/gallery/file/${galleryFile.id}`;

    await Axios.delete(url);

    url = `${process.env.REACT_APP_API_URL}/gallery`;
    const response = await Axios.get(url);
    const galleries = response.data;

    this.setState(
      currentState => {
        // delete the image from the array
        const files = currentState.files.filter(gf => gf.id !== galleryFile.id);
        // recompute the tags
        const arraysOfTags = files.map(gf => gf.tags);
        const tags = _.union(...arraysOfTags);
        return {
          files,
          tags,
          galleries,
          galleryIsChanged: false
        };
      },
      () => {
        this.filter();
        this.updateActiveGalleryFiles();
      }
    );
  };

  submit = async payload => {
    const pendingAction = this.state.filesCurrentAction;
    this.setState({ filesCurrentAction: "" }, async () => {
      if (pendingAction === this.actions.update) {
        await this.updateFile(payload);
      } else if (pendingAction === this.actions.delete) {
        await this.deleteFile(payload);
      }
    });
  };

  onGalleryChange = e => {
    this.setState(
      {
        selectedGallery: e.target.value,
        galleryIsChanged: false
      },
      () => {
        this.updateActiveGalleryFiles();
      }
    );
  };

  toggleTooltip = name => {
    this.setState(currentState => ({
      [name]: !currentState[name]
    }));
  };

  setGalleriesCurrentAction = (galleriesCurrentAction = "") => {
    this.setState({ galleriesCurrentAction });
  };

  createGallery = async payload => {
    let url = `${process.env.REACT_APP_API_URL}/gallery`;
    await Axios.post(url, payload);

    url = `${process.env.REACT_APP_API_URL}/gallery`;
    const response = await Axios.get(url);
    const galleries = response.data;

    this.setState(
      currentState => {
        return {
          galleries,
          selectedGallery: galleries.length - 1
        };
      },
      () => {
        this.updateActiveGalleryFiles();
      }
    );
  };

  updateGallery = async payload => {
    const selGal = this.state.selectedGallery;
    let url = `${process.env.REACT_APP_API_URL}/gallery/${
      this.state.galleries[selGal].id
    }`;
    await Axios.put(url, payload);
    url = `${process.env.REACT_APP_API_URL}/gallery`;
    const response = await Axios.get(url);
    const galleries = response.data;
    this.setState(
      currentState => {
        return {
          galleries
        };
      },
      () => {
        this.updateActiveGalleryFiles();
      }
    );
  };

  deleteGallery = async payload => {
    const selGal = this.state.selectedGallery;
    let url = `${process.env.REACT_APP_API_URL}/gallery/${
      this.state.galleries[selGal].id
    }`;
    await Axios.delete(url);

    url = `${process.env.REACT_APP_API_URL}/gallery`;
    const response = await Axios.get(url);
    const galleries = response.data;

    this.setState(
      currentState => {
        return {
          galleries,
          selectedGallery: Math.min(
            galleries.length - 1,
            Math.max(currentState.selectedGallery - 1, 0)
          )
        };
      },
      () => {
        this.updateActiveGalleryFiles();
      }
    );

    if (this.state.galleries.length === 0) {
      this.setState({ activeGalleryFiles: [] }, () => {
        this.updateActiveGalleryFiles();
      });
    }
  };

  submitGalleries = async payload => {
    if (this.state.galleriesCurrentAction === this.actions.create) {
      await this.createGallery(payload);
    } else if (this.state.galleriesCurrentAction === this.actions.update) {
      await this.updateGallery(payload);
    } else if (this.state.galleriesCurrentAction === this.actions.delete) {
      await this.deleteGallery();
    }

    this.setGalleriesCurrentAction();
  };

  updateActiveGalleryFiles = () => {
    const galleries = this.state.galleries;
    const gal_index = this.state.selectedGallery;
    let activeGalleryFiles = [];
    if (galleries.length > 0) {
      let sortedImages = galleries[gal_index].images.sort(
        (a, b) => a.ord - b.ord
      );
      let file_ids = sortedImages.map(i => i.file_id);
      activeGalleryFiles = file_ids.map(file_id =>
        this.getFileRecordForGalleryFile(file_id)
      );
    }
    this.setState({ activeGalleryFiles });
  };

  getFileRecordForGalleryFile = file_id => {
    const fr = this.state.files.filter(f => f.id === file_id);
    if (fr.length === 1) {
      return fr[0];
    } else {
      return { id: -1, title: "File not found", note: "", tags: [] };
    }
  };

  addFileToGallery = galleryFile => {
    if (this.state.galleries.length === 0) return;

    let existingFile = this.state.activeGalleryFiles.filter(
      gf => gf.id === galleryFile.id
    );
    if (existingFile.length > 0) return;

    this.setState(currentState => {
      return {
        activeGalleryFiles: [...currentState.activeGalleryFiles, galleryFile],
        galleryIsChanged: true
      };
    });
  };

  removeGalleryFile = galleryFile => {
    this.setState(currentState => {
      return {
        activeGalleryFiles: currentState.activeGalleryFiles.filter(
          gf => gf.id !== galleryFile.id
        ),
        galleryIsChanged: true
      };
    });
  };

  galleryMoveLeft = galleryFile => {
    this.setState(currentState => {
      const index = this.state.activeGalleryFiles.findIndex(
        gf => gf.id === galleryFile.id
      );

      return index > 0
        ? {
            activeGalleryFiles: [
              ...currentState.activeGalleryFiles.slice(0, index - 1),
              currentState.activeGalleryFiles[index],
              currentState.activeGalleryFiles[index - 1],
              ...currentState.activeGalleryFiles.slice(
                index + 1,
                currentState.activeGalleryFiles.length
              )
            ],
            galleryIsChanged: true
          }
        : {};
    });
  };

  galleryMoveRight = galleryFile => {
    this.setState(currentState => {
      const index = this.state.activeGalleryFiles.findIndex(
        gf => gf.id === galleryFile.id
      );

      return index < this.state.activeGalleryFiles.length - 1
        ? {
            activeGalleryFiles: [
              ...currentState.activeGalleryFiles.slice(0, index),
              currentState.activeGalleryFiles[index + 1],
              currentState.activeGalleryFiles[index],
              ...currentState.activeGalleryFiles.slice(
                index + 2,
                currentState.activeGalleryFiles.length
              )
            ],
            galleryIsChanged: true
          }
        : {};
    });
  };

  saveGallery = async () => {
    if (this.state.galleries.length === 0) return;

    this.setState({ galleryIsChanged: false, galleryIsSaving: true });

    const images = this.state.activeGalleryFiles.map((gf, index) => ({
      file_id: gf.id,
      ord: index
    }));
    const payload = { images };

    const selGal = this.state.selectedGallery;
    console.log(selGal);
    console.log(this.state.galleries);
    let url = `${process.env.REACT_APP_API_URL}/gallery/${
      this.state.galleries[selGal].id
    }/images`;
    await Axios.put(url, payload);

    url = `${process.env.REACT_APP_API_URL}/gallery`;
    const response = await Axios.get(url);
    const galleries = response.data;

    this.setState(
      currentState => {
        return {
          galleries,
          selectedGallery: Math.min(
            galleries.length - 1,
            currentState.selectedGallery
          ),
          galleryIsSaving: false
        };
      },
      () => {
        this.updateActiveGalleryFiles();
      }
    );
  };

  render() {
    const {
      filteredFiles,
      lightboxPhotoIndex,
      lightboxIsOpen,
      lightboxFiles,
      searchValue,
      filesCurrentAction,
      selectedImage,
      selectedGallery,
      galleries,
      activeGalleryFiles,
      galleryEditTooltipOpen,
      galleryDeleteTooltipOpen,
      galleriesCurrentAction,
      galleryIsChanged,
      galleryIsSaving
    } = this.state;

    return (
      <DocumentTitle title="Gallery Admin - Borger Team">
        <div>
          <div className="row">
            <div className="col-6">
              <h1>Files</h1>
              {filesCurrentAction === this.actions.update && (
                <FileEditForm
                  title="Update Image Information"
                  file={selectedImage}
                  onCloseClick={_ => this.setFilesCurrentAction()}
                  onSubmit={this.submit}
                  token={this.props.user.token}
                />
              )}
              {filesCurrentAction === this.actions.delete && (
                <FileDeleteConfirmation
                  file={selectedImage}
                  onCancelClick={_ => this.setFilesCurrentAction()}
                  onDeleteClick={this.submit}
                  token={this.props.user.token}
                />
              )}
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <input
                      type="text"
                      className="form-control d-inline-block"
                      placeholder="Search..."
                      value={searchValue}
                      onChange={this.onSearchChange}
                    />
                  </div>
                  <div className="row">
                    <div className="m-auto">
                      <i className="ion ion-md-document" />
                      <em className="ml-1">Show: </em>
                    </div>
                    <div className="col my-1">
                      <button
                        type="button"
                        className={
                          this.state.showField === "all"
                            ? "btn m-1 btn-primary"
                            : "btn m-1 btn-secondary"
                        }
                        onClick={() => this.onShowChange("all")}
                      >
                        All
                      </button>
                      <button
                        type="button"
                        className={
                          this.state.showField === "notInGallery"
                            ? "btn m-1 btn-primary"
                            : "btn m-1 btn-secondary"
                        }
                        onClick={() => this.onShowChange("notInGallery")}
                      >
                        Files not in a gallery
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="m-auto">
                      <i className="ion ion-md-funnel" />
                      <em className="ml-1">Filter by: </em>
                    </div>
                    <div className="col my-1">
                      <button
                        type="button"
                        className={
                          this.state.filterField === "all"
                            ? "btn m-1 btn-primary"
                            : "btn m-1 btn-secondary"
                        }
                        onClick={() => this.onFilterChange("all")}
                      >
                        All
                      </button>
                      {this.state.tags.map(tag => (
                        <button
                          type="button"
                          key={tag}
                          className={
                            this.state.filterField === tag
                              ? "btn m-1 btn-primary"
                              : "btn m-1 btn-secondary"
                          }
                          onClick={() => this.onFilterChange(tag)}
                        >
                          {tag}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div
                    id="filelist"
                    className="d-flex align-content-start flex-wrap"
                  >
                    {filteredFiles.map((gf, index) => (
                      <File
                        key={gf.id}
                        galleryFile={gf}
                        index={index}
                        onAddClick={this.addFileToGallery}
                        onEditClick={gf =>
                          this.setFilesCurrentAction(this.actions.update, gf)
                        }
                        onDeleteClick={gf =>
                          this.setFilesCurrentAction(this.actions.delete, gf)
                        }
                        openLightboxAt={this.openLightboxAt}
                        token={this.props.user.token}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <h1>Galleries</h1>
              {galleriesCurrentAction === this.actions.delete &&
                galleries.length > 0 && (
                  <GalleryDeleteConfirmation
                    galleryInfo={{
                      title: galleries[selectedGallery].title,
                      count: galleries[selectedGallery].images.length
                    }}
                    onCancelClick={() => this.setGalleriesCurrentAction()}
                    onDeleteClick={this.submitGalleries}
                  />
                )}
              {galleriesCurrentAction === this.actions.update &&
                galleries.length > 0 && (
                  <GalleryCreateEditForm
                    title="Edit Gallery"
                    submitText="Update"
                    galleryInfo={{
                      title: galleries[selectedGallery].title,
                      description: galleries[selectedGallery].description,
                      is_active: galleries[selectedGallery].is_active
                    }}
                    onCloseClick={() => this.setGalleriesCurrentAction()}
                    onSubmit={this.submitGalleries}
                  />
                )}
              {galleriesCurrentAction === this.actions.create && (
                <GalleryCreateEditForm
                  title="Create Gallery"
                  submitText="Create"
                  galleryInfo={{
                    title: "",
                    description: "",
                    is_active: true
                  }}
                  onCloseClick={() => this.setGalleriesCurrentAction()}
                  onSubmit={this.submitGalleries}
                />
              )}
              <div className="card">
                <div className="card-body">
                  <label htmlFor="courseList">Select gallery:</label>
                  <br />
                  <select
                    className="form-control custom-select mb-2"
                    id="galleryList"
                    value={selectedGallery}
                    onChange={this.onGalleryChange}
                    disabled={!!galleriesCurrentAction || galleryIsSaving}
                  >
                    {galleries.map((gallery, index) => (
                      <option key={gallery.id} value={index}>
                        {gallery.title} {gallery.is_active ? "" : "(inactive) "}{" "}
                        - {gallery.description}
                      </option>
                    ))}
                  </select>

                  <div className="d-flex">
                    <button
                      onClick={() =>
                        this.setGalleriesCurrentAction(this.actions.create)
                      }
                      type="button"
                      className="btn btn-primary btn-round d-block mr-1"
                    >
                      <span className="ion ion-md-add" />
                      &nbsp; Create Gallery
                    </button>
                    <button
                      type="button"
                      id="edit-gallery"
                      onClick={() =>
                        this.setGalleriesCurrentAction(this.actions.update)
                      }
                      className="btn btn-default btn-xs icon-btn mr-1 bg-warning"
                    >
                      <i className="ion ion-md-create" />
                    </button>
                    <button
                      type="button"
                      id="delete-gallery"
                      onClick={() =>
                        this.setGalleriesCurrentAction(this.actions.delete)
                      }
                      className="btn btn-default btn-xs icon-btn bg-danger text-white"
                    >
                      <i className="ion ion-md-trash" />
                    </button>
                    <Tooltip
                      placement="top"
                      isOpen={galleryEditTooltipOpen}
                      target="edit-gallery"
                      toggle={() =>
                        this.toggleTooltip("galleryEditTooltipOpen")
                      }
                    >
                      Edit
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      isOpen={galleryDeleteTooltipOpen}
                      target="delete-gallery"
                      toggle={() =>
                        this.toggleTooltip("galleryDeleteTooltipOpen")
                      }
                    >
                      Delete gallery
                    </Tooltip>
                    <button
                      onClick={() => this.saveGallery()}
                      type="button"
                      className="btn btn-primary btn-round d-block mr-1 ml-auto"
                      disabled={!galleryIsChanged}
                    >
                      <span className="ion ion-md-save" />
                      &nbsp; {galleryIsSaving ? "Saving..." : "Save Gallery"}
                    </button>
                  </div>
                  <br />
                  <div
                    id="gallerylist"
                    className="d-flex align-content-start flex-wrap"
                  >
                    {activeGalleryFiles.map((gf, index) => (
                      <GalleryItem
                        key={gf.id}
                        galleryFile={gf}
                        index={index}
                        onRemoveClick={this.removeGalleryFile}
                        onLeftClick={this.galleryMoveLeft}
                        onRightClick={this.galleryMoveRight}
                        openLightboxAt={this.openLightboxAt}
                        token={this.props.user.token}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {lightboxIsOpen && (
              <Lightbox
                mainSrc={`${process.env.REACT_APP_API_URL}/gallery/file/${
                  lightboxFiles[lightboxPhotoIndex].id
                }/full?token=${this.props.user.token}`}
                nextSrc={`${process.env.REACT_APP_API_URL}/gallery/file/${
                  lightboxFiles[(lightboxPhotoIndex + 1) % lightboxFiles.length]
                    .id
                }/full?token=${this.props.user.token}`}
                prevSrc={`${process.env.REACT_APP_API_URL}/gallery/file/${
                  lightboxFiles[
                    (lightboxPhotoIndex + lightboxFiles.length - 1) %
                      lightboxFiles.length
                  ].id
                }/full?token=${this.props.user.token}`}
                onCloseRequest={() => this.setState({ lightboxIsOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    lightboxPhotoIndex:
                      (lightboxPhotoIndex + lightboxFiles.length - 1) %
                      lightboxFiles.length
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    lightboxPhotoIndex:
                      (lightboxPhotoIndex + 1) % lightboxFiles.length
                  })
                }
                imageCaption={`${lightboxFiles[lightboxPhotoIndex].title} - ${
                  lightboxFiles[lightboxPhotoIndex].note
                }`}
              />
            )}
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

const protectedPage = withAuthorization(
  user => hasPermission(user, PERMISSIONS.gallery),
  () => routes.DASHBOARD
);

export default protectedPage(GalleryAdmin);
