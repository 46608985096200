import React from "react";
import PropTypes from "prop-types";
import { FormattedDate } from "react-intl";

const GeneralMessagingList = ({ messages, onDelete }) => {
  return (
    <div className="card table-responsive">
      <h6 className="card-header">LAST 20 MESSAGES</h6>
      <table className="table card-table table-hover">
        <thead className="thead-light">
          <tr>
            <th>Message Text</th>
            <th>Category</th>
            <th>Send Date</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {messages.map(({ id, message, category, createdAt }) => (
            <tr key={id}>
              <td>{message}</td>
              <td>{category}</td>
              <td>
                {
                  <FormattedDate
                    value={createdAt}
                    hour12={false}
                    day="2-digit"
                    month="short"
                    year="numeric"
                    hour="numeric"
                    minute="numeric"
                    second="numeric"
                  />
                }
              </td>
              <td>
                <button
                  className="btn btn-danger btn-xs"
                  onClick={() => onDelete(id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

GeneralMessagingList.propTypes = {
  messages: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default GeneralMessagingList;
