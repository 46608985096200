import React from "react";
import PropTypes from "prop-types";

const Filter = ({
  filterField,
  onFilterChange,
  name,
  options
}) => {
  const labelClassName = `btn btn-sm btn-outline-${name === "clearAll" ? "danger" : "primary"}`
  return (
    <>
      <div className={`btn-group btn-group-toggle my-1 mr-2 d-flex align-items-center justify-content-end `}>
        {options && options.map(({ path, label }) => (
          <label key={`${name}_${path}`}
            className={`${labelClassName} ${filterField[name] === path ? "active" : ""}`}
          >
            <input
              type="radio"
              autoComplete="off"
              value={path}
              onChange={onFilterChange}
              checked={filterField[name] === path}
              name={name}
            />
            {label}
          </label>
        ))}
      </div>

    </>
  );
};

Filter.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  filterField: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
};


export default Filter;
