import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "reactstrap";

class Career extends React.Component {
  static propTypes = {
    career: PropTypes.shape({
      job_id: PropTypes.number.isRequired,
      job_title: PropTypes.string.isRequired,
      job_active: PropTypes.number.isRequired,
      job_description: PropTypes.string.isRequired
    }),
    onEditClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired
  };

  state = {
    editTooltipOpen: false,
    deleteTooltipOpen: false,
    showDescriptionTooltipOpen: false
  };

  toggleTooltip = name => {
    this.setState(currentState => ({
      [name]: !currentState[name]
    }));
  };

  render() {
    const {
      editTooltipOpen,
      deleteTooltipOpen,
      showDescriptionTooltipOpen
    } = this.state;
    const {
      job_id,
      job_title,
      job_active,
      job_description
    } = this.props.career;
    return (
      <div key={job_id} className="row mb-2 border-bottom border-light">
        <div className="col-12 my-4 d-flex justify-content-between">
          <a
            className="font-weight-bold"
            href={`#collapse-${job_id}`}
            role="button"
            data-toggle="collapse"
            data-target={`#collapse-${job_id}`}
            aria-expanded="false"
            aria-controls={`collapse-${job_id}`}
          >
            <span
              className={`badge text-small mr-2 ${
                job_active ? "badge-success" : "badge-secondary"
              }`}
            >
              {job_active ? "Active" : "Inactive"}
            </span>
            {job_title}
          </a>
          <div id={`heading-${job_id}`}>
            <button
              id={`collapseDescription-${job_id}`}
              className="btn btn-default btn-sm icon-btn my-1 mx-1"
              type="button"
              data-toggle="collapse"
              data-target={`#collapse-${job_id}`}
              aria-expanded="false"
              aria-controls={`collapse-${job_id}`}
            >
              <i className="fa fa-eye" />
            </button>
            <button
              type="button"
              id={`edit-${job_id}`}
              className="btn btn-default btn-sm icon-btn bg-warning my-1 mx-1"
              onClick={() => this.props.onEditClick(this.props.career)}
            >
              <i className="ion ion-md-create" />
            </button>
            <button
              type="button"
              id={`delete-${job_id}`}
              className="btn btn-default btn-sm icon-btn bg-danger text-white mx-1"
              onClick={() => this.props.onDeleteClick(this.props.career)}
            >
              <i className="ion ion-md-close" />
            </button>
          </div>
        </div>
        <div
          id={`collapse-${job_id}`}
          aria-labelledby={`heading-${job_id}`}
          data-parent="#careersList"
          className="col-12 text-justify collapse"
          dangerouslySetInnerHTML={{ __html: job_description }}
        />
        <Tooltip
          placement="top"
          target={`edit-${job_id}`}
          isOpen={editTooltipOpen}
          toggle={() => this.toggleTooltip("editTooltipOpen")}
        >
          Edit
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={deleteTooltipOpen}
          target={`delete-${job_id}`}
          toggle={() => this.toggleTooltip("deleteTooltipOpen")}
        >
          Delete
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={showDescriptionTooltipOpen}
          target={`collapseDescription-${job_id}`}
          toggle={() => this.toggleTooltip("showDescriptionTooltipOpen")}
        >
          Toggle description
        </Tooltip>
      </div>
    );
  }
}

export default Career;
