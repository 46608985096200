import React from "react";
import PropTypes from "prop-types";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";

const MessagingSchema = Yup.object().shape({
  message: Yup.string().required("Message is required"),
  category: Yup.string().required("Category is required")
});

const GeneralMessagingForm = ({ onSubmit }) => {
  return (
    <div className="card mb-4">
      <h6 className="card-header">TEAM MESSAGING</h6>
      <div className="card-body">
        <Formik
          initialValues={{ message: "", category: "default" }}
          validationSchema={MessagingSchema}
          onSubmit={async (values, utils) => {
            await onSubmit(values);
            utils.setSubmitting(false);
            utils.resetForm();
          }}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            isValid,
            handleSubmit,
            touched,
            errors
          }) => (
            <form
              onSubmit={handleSubmit}
              className="needs-validation"
              noValidate
            >
              <div className="form-group">
                <label className="form-label" htmlFor="message">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  className={
                    touched.message && errors.message
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  rows="6"
                  required
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">
                <label
                  htmlFor="default"
                  className="form-check form-check-inline"
                >
                  <input
                    id="default"
                    name="category"
                    className="form-check-input"
                    type="radio"
                    value="default"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.category === "default"}
                  />
                  <span className="form-check-label">Default</span>
                </label>
                <label
                  htmlFor="activeSite"
                  className="form-check form-check-inline"
                >
                  <input
                    id="activeSite"
                    name="category"
                    className="form-check-input"
                    type="radio"
                    value="activeSite"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.category === "activeSite"}
                  />
                  <span className="form-check-label">Active Site</span>
                </label>
                <label
                  htmlFor="inactiveSite"
                  className="form-check form-check-inline"
                >
                  <input
                    id="inactiveSite"
                    name="category"
                    className="form-check-input"
                    type="radio"
                    value="inactiveSite"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.category === "inactiveSite"}
                  />
                  <span className="form-check-label">Inactive Site</span>
                </label>
              </div>

              <button
                type="submit"
                className="btn btn-primary"
                disabled={!isValid || isSubmitting}
              >
                Submit
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

GeneralMessagingForm.propTypes = { onSubmit: PropTypes.func.isRequired };

export default GeneralMessagingForm;
