import React from "react";
import PropTypes from "prop-types";

class FileDeleteConfirmation extends React.Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  render() {
    const { file, onCancelClick, onDeleteClick } = this.props;
    return (
      <div className="card card-default mb-3">
        <div className="card-header">
          <div className="h4">
            Are you sure you want to delete the image{" "}
            <em className="font-weight-bold">"{file.title}"?</em>
            <br />
            <img
              src={`${process.env.REACT_APP_API_URL}/gallery/file/${
                this.props.file.id
              }/thumb?token=${this.props.token}`}
              alt={this.props.file.title}
            />
            <button
              onClick={onCancelClick}
              type="button"
              className="float-right btn btn-xs btn-oval btn-outline-primary"
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="card-body">
          <button
            onClick={() => onDeleteClick(file)}
            type="button"
            className="btn btn-danger mb-2"
          >
            Confirm delete
          </button>
        </div>
      </div>
    );
  }
}
FileDeleteConfirmation.propTypes = {
  file: PropTypes.object,
  onDeleteClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

export default FileDeleteConfirmation;
