import React from "react";
import ThemeContext from "./ThemeContext";

const withTheme = Component => {
  function wrapper(props) {
    return (
      <ThemeContext.Consumer>
        {theme => <Component {...props} theme={theme} />}
      </ThemeContext.Consumer>
    );
  }

  wrapper.displayName = `withTheme(${Component.displayName || Component.name})`;

  return wrapper;
};

export default withTheme;
