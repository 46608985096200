import React from "react";
import PropTypes from "prop-types";
import Applicant from "./Applicant";

class ApplicantsList extends React.PureComponent {
  static propTypes = {
    applicants: PropTypes.array.isRequired,
    onDownloadClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
    search: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    sortBy: PropTypes.func.isRequired,
    sortKey: PropTypes.string.isRequired,
    sortDesc: PropTypes.bool.isRequired
  };

  render() {
    const {
      applicants,
      onDownloadClick,
      onDeleteClick,
      sortBy,
      sortKey,
      sortDesc,
      searchValue,
      search,
      loading
    } = this.props;

    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col">
              <input
                type="text"
                className="form-control d-inline-block"
                placeholder="Type at least 3 characters to search..."
                value={searchValue}
                onChange={search}
              />
            </div>
          </div>
        </div>
        <hr className="border-light m-0" />
        <div className="table-responsive">
          <table className="table table-striped table-bordered card-table">
            <thead>
              <tr>
                <th
                  title="Sort"
                  className="text-nowrap"
                  onClick={() => sortBy("fullName")}
                >
                  <button
                    type="button"
                    className="btn btn-link p-0"
                    aria-pressed={sortKey === "fullName"}
                  >
                    Full Name
                    {sortKey === "fullName" && (
                      <i
                        className={`ion text-muted ml-2 ion-md-arrow-${
                          sortDesc ? "down" : "up"
                        }`}
                      />
                    )}
                  </button>
                </th>
                <th className="text-nowrap">Phone Number</th>
                <th className="text-nowrap">Email Address</th>
                <th className="text-nowrap">Contact By</th>
                <th
                  title="Sort"
                  className="text-nowrap"
                  onClick={() => sortBy("date")}
                >
                  <button
                    type="button"
                    className="btn btn-link p-0"
                    aria-pressed={sortKey === "date"}
                  >
                    Date Submitted
                    {sortKey === "date" && (
                      <i
                        className={`ion text-muted ml-2 ion-md-arrow-${
                          sortDesc ? "down" : "up"
                        }`}
                      />
                    )}
                  </button>
                </th>
                <th className="text-nowrap">Jobs Applied For</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {applicants.map(applicant => (
                <Applicant
                  key={applicant.id}
                  applicant={applicant}
                  onDownloadClick={onDownloadClick}
                  onDeleteClick={onDeleteClick}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="card-body py-0">
          <div className="row">
            <div className="col-sm text-sm-left text-center pt-3 pb-3">
              <span className="text-muted">
                {loading ? "Loading..." : `Total items: ${applicants.length}`}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApplicantsList;
