import React from "react";
import AuthContext from "./AuthContext";

const withUser = Component => {
  function Wrapper(props) {
    return (
      <AuthContext.Consumer>
        {({ user }) => <Component user={user} {...props} />}
      </AuthContext.Consumer>
    );
  }

  Wrapper.displayName = `withUser(${Component.displayName || Component.name})`;

  return Wrapper;
};

export default withUser;
