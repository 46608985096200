const sort = (items, sortBy, sortDesc) => {

    return items.sort((A, B) => {
        const firstField =
            typeof A[sortBy] === "string" ? A[sortBy].toLowerCase() : A[sortBy];
        const secondField =
            typeof B[sortBy] === "string" ? B[sortBy].toLowerCase() : B[sortBy];

        if (sortDesc) {
            return firstField > secondField ? -1 : 1;
        } else {
            return firstField > secondField ? 1 : -1;
        }
    });
};
export default sort;