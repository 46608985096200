import React, { Component } from "react";
import Axios from "axios";
import { withAuthorization } from "../Auth";
import * as routes from "../../config/routes";
import { hasPermission, PERMISSIONS } from "../../config/permissions";
import DocumentTitle from "react-document-title";

class CourseAssign extends Component {
  state = {
    loading: true,
    courses: [],
    users: [],
    filteredUsers: [],
    selectedCourse: 0,
    searchValueIdName: "",
    searchValueCompany: "",
    searchValuePosition: "",
    searchValueContributor: "",
    activeScreen: "select",
    registrationSuccess: false
  };

  componentDidMount() {
    this.fetch();
  }

  filter = () => {
    const {
      users,
      searchValueIdName,
      searchValueCompany,
      searchValuePosition,
      searchValueContributor
    } = this.state;

    const filteredUsers = users.filter(
      ({
        emplid,
        first_name,
        last_name,
        company,
        position,
        contributor_level
      }) =>
        `${emplid} ${first_name} ${last_name}`
          .toLowerCase()
          .includes(searchValueIdName.toLowerCase()) &&
        company.toLowerCase().includes(searchValueCompany) &&
        position.toLowerCase().includes(searchValuePosition) &&
        contributor_level.toLowerCase().includes(searchValueContributor)
    );

    this.setState({ filteredUsers });
  };

  fetch = async () => {
    let URL = `${process.env.REACT_APP_API_URL}/learning/courses`;
    let response = await Axios.get(URL);
    const courses = response.data;

    URL = `${process.env.REACT_APP_API_URL}/user`;
    response = await Axios.get(URL);
    const users = response.data;
    for (let u of users) {
      u.isSelectedUser = false;
    }

    this.setState({ courses, users, loading: false }, this.filter);
  };

  onCourseChange = e => {
    this.setState({ selectedCourse: e.target.value });
  };

  toggleSelectedUser = id => {
    this.setState(currentState => {
      const users = currentState.users.map(user =>
        user.employee_id === id
          ? { ...user, isSelectedUser: !user.isSelectedUser }
          : user
      );
      return { users };
    }, this.filter);
  };

  searchIdName = e => {
    this.setState({ searchValueIdName: e.target.value }, this.filter);
  };

  searchCompany = e => {
    this.setState({ searchValueCompany: e.target.value }, this.filter);
  };

  searchPosition = e => {
    this.setState({ searchValuePosition: e.target.value }, this.filter);
  };

  searchContributor = e => {
    this.setState({ searchValueContributor: e.target.value }, this.filter);
  };

  clearFilter = () => {
    this.setState(
      {
        searchValueIdName: "",
        searchValueCompany: "",
        searchValuePosition: "",
        searchValueContributor: ""
      },
      this.filter
    );
  };

  selectAllUsersInList = () => {
    for (let u of this.state.filteredUsers) {
      if (!u.isSelectedUser) {
        this.toggleSelectedUser(u.employee_id);
      }
    }
  };

  countSelectedStudents = () => {
    let count = 0;
    for (let u of this.state.filteredUsers) {
      if (u.isSelectedUser) {
        count++;
      }
    }
    return count;
  };

  removeSelectedStudents = () => {
    for (let u of this.state.users) {
      if (u.isSelectedUser) {
        this.toggleSelectedUser(u.employee_id);
      }
    }
  };

  setActiveScreen = screen => {
    this.setState(currentState => ({ activeScreen: screen }));
  };

  submitAssignment = async () => {
    const { courses, selectedCourse, users } = this.state;

    let classId = courses[selectedCourse - 1].id.toString();
    let userIds = [];
    let adminId = this.props.user.id;
    for (let u of users) {
      if (u.isSelectedUser) {
        userIds.push({
          employee_id: u.employee_id,
          email: u.email_address,
          student_id: u.student_id
        });
      }
    }
    var URL = `${process.env.REACT_APP_API_URL}/learning/assign/${classId}`;
    var payload = {
      classId,
      userIds,
      adminId: `${adminId}`
    };

    try {
      await Axios.post(URL, payload);
      this.setState({ registrationSuccess: true });
    } catch (err) {
      this.setState({ registrationSuccess: false });
      console.log(err);
    }

    this.setActiveScreen("result");
  };

  startAgain = () => {
    this.removeSelectedStudents();
    this.clearFilter();
    this.setState({ selectedCourse: 0 });
    this.setActiveScreen("select");
  };

  render() {
    const {
      loading,
      courses,
      users,
      filteredUsers,
      selectedCourse,
      searchValueIdName,
      searchValueCompany,
      searchValuePosition,
      searchValueContributor,
      activeScreen,
      registrationSuccess
    } = this.state;
    return (
      <DocumentTitle title="Course Assignment - Borger Team">
        <div>
          <div className="card">
            <div className="card-body">
              <h1>Course Assignment</h1>

              <p>
                Step One - Select Course. Step Two - Select Students.
                <br />
                If a student is showing as <b>INVALID</b>, then their Borger U
                student ID has not been populated yet. It may take up to half an
                hour after a new user is created for this to happen.
                <br />
                <br />
              </p>

              {activeScreen === "select" && (
                <div>
                  <label htmlFor="courseList">
                    <h3>Select course</h3>
                  </label>
                  <select
                    className="form-control custom-select mb-4"
                    id="courseList"
                    value={selectedCourse}
                    onChange={this.onCourseChange}
                  >
                    <option value="0">---</option>
                    {courses.map((course, index) => (
                      <option key={course.id} value={index + 1}>
                        {course.name} - {course.category}
                      </option>
                    ))}
                  </select>

                  {selectedCourse > 0 && this.countSelectedStudents() > 0 ? (
                    <div>
                      <br clear="all" />
                      <div className="float-right">
                        <button
                          onClick={() => this.setActiveScreen("confirm")}
                          type="button"
                          className="btn btn-primary btn-round d-block"
                        >
                          Next Step
                        </button>
                      </div>
                    </div>
                  ) : null}

                  <h3>Search</h3>
                  <div className="row">
                    <div className="form-group mx-2">
                      <label htmlFor="idNameSearch">ID or name:</label>{" "}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchValueIdName}
                        onChange={this.searchIdName}
                        id="idNameSearch"
                        style={{ width: 200 }}
                      />
                    </div>

                    <div className="form-group mx-2">
                      <label htmlFor="companySearch">Company:</label>{" "}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchValueCompany}
                        onChange={this.searchCompany}
                        id="companySearch"
                        style={{ width: 200 }}
                      />
                    </div>

                    <div className="form-group mx-2">
                      <label htmlFor="positionSearch">Position:</label>{" "}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchValuePosition}
                        onChange={this.searchPosition}
                        id="positionSearch"
                        style={{ width: 200 }}
                      />
                    </div>

                    <div className="form-group mx-2">
                      <label htmlFor="contributorSearch">Contributor:</label>{" "}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchValueContributor}
                        onChange={this.searchContributor}
                        id="contributorSearch"
                        style={{ width: 200 }}
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-9">
                      <h3>Select students</h3>
                      <div className="row float-right">
                        <button
                          onClick={this.clearFilter}
                          type="button"
                          className="btn btn-primary btn-round d-block btn-sm m-1"
                        >
                          Clear Filter
                        </button>
                        <button
                          onClick={this.selectAllUsersInList}
                          type="button"
                          className="btn btn-primary btn-round d-block btn-sm m-1"
                        >
                          Select All
                        </button>
                      </div>

                      <div className="table-responsive" style={{ height: 400 }}>
                        <table className="table table-striped table-bordered card-table">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Company</th>
                              <th>Position</th>
                              <th>Contributor</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsers.map(user =>
                              !user.isSelectedUser ? (
                                <tr key={user.employee_id}>
                                  <td className="align-middle">
                                    {user.emplid}
                                  </td>
                                  <td className="align-middle">
                                    {user.student_id ? (
                                      <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.toggleSelectedUser(
                                            user.employee_id
                                          )
                                        }
                                      >
                                        {`${user.first_name} ${user.last_name}`}
                                      </span>
                                    ) : (
                                      `${user.first_name} ${user.last_name}`
                                    )}
                                  </td>
                                  <td className="align-middle">
                                    {user.email_address}
                                  </td>
                                  <td className="align-middle">
                                    {user.company}
                                  </td>
                                  <td className="align-middle">
                                    {user.position}
                                  </td>
                                  <td className="align-middle">
                                    {user.contributor_level}
                                  </td>
                                  <td className="align-middle">
                                    {user.student_id ? (
                                      <button
                                        onClick={() =>
                                          this.toggleSelectedUser(
                                            user.employee_id
                                          )
                                        }
                                        type="button"
                                        className="btn btn-primary btn-round d-block btn-sm"
                                      >
                                        Select
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-secondary btn-round d-block btn-sm"
                                        disabled="true"
                                      >
                                        Invalid
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ) : null
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="card-body py-0">
                        <div className="row">
                          <div className="col-sm text-sm-left text-center pt-3 pb-3">
                            <span className="text-muted">
                              {loading ? "Loading..." : null}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <h3>Selected students</h3>
                      <div className="float-right">
                        <button
                          onClick={this.removeSelectedStudents}
                          type="button"
                          className="btn btn-danger btn-round d-block btn-sm"
                        >
                          Remove All
                        </button>
                      </div>
                      <div className="table-responsive" style={{ height: 400 }}>
                        <table className="table table-striped table-bordered card-table">
                          <tbody>
                            {users.map(user =>
                              user.isSelectedUser ? (
                                <tr key={user.employee_id}>
                                  <td className="align-middle">
                                    {`${user.first_name} ${user.last_name} (${
                                      user.emplid
                                    })`}{" "}
                                    <button
                                      onClick={() =>
                                        this.toggleSelectedUser(
                                          user.employee_id
                                        )
                                      }
                                      type="button"
                                      className="btn btn-danger btn-round btn-sm"
                                    >
                                      <i className="ion ion-md-close" />
                                    </button>
                                  </td>
                                </tr>
                              ) : null
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {selectedCourse > 0 && this.countSelectedStudents() > 0 ? (
                    <div>
                      <br clear="all" />
                      <div className="float-right">
                        <button
                          onClick={() => this.setActiveScreen("confirm")}
                          type="button"
                          className="btn btn-primary btn-round d-block"
                        >
                          Next Step
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
              {activeScreen === "confirm" && (
                <div>
                  <h3>Confirmation</h3>
                  This is what's about to happen:
                  <br />
                  COURSE: {courses[selectedCourse - 1].name} (
                  {courses[selectedCourse - 1].id})<br />
                  STUDENT COUNT: {this.countSelectedStudents()}
                  <br />
                  <br />
                  <div className="row">
                    <button
                      onClick={() => this.setActiveScreen("select")}
                      type="button"
                      className="btn btn-primary btn-round d-block m-1"
                    >
                      Previous Step
                    </button>

                    <button
                      onClick={this.submitAssignment}
                      type="button"
                      className="btn btn-primary btn-round d-block m-1"
                    >
                      Confirm Assignment
                    </button>
                  </div>
                </div>
              )}
              {activeScreen === "result" && (
                <div>
                  <h3>Result</h3>
                  {registrationSuccess
                    ? "The registration was successful."
                    : "An error occured while attempting to register."}
                  <br />
                  <br />
                  <button
                    onClick={this.startAgain}
                    type="button"
                    className="btn btn-primary btn-round d-block"
                  >
                    Start Again
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

const protectedPage = withAuthorization(
  user => hasPermission(user, PERMISSIONS.learning),
  () => routes.DASHBOARD
);

export default protectedPage(CourseAssign);
