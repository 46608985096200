import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";

class GalleryCreateEditForm extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    galleryInfo: PropTypes.object.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  state = {};

  formSchema = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string().required(),
    is_active: Yup.bool()
  });

  getInitialFormValues = () => {
    const { title, description, is_active } = this.props.galleryInfo;
    return {
      title: title || "",
      description: description || "",
      is_active: is_active
    };
  };

  handleSubmit = values => {
    const payload = { ...values };

    this.props.onSubmit(payload);
  };

  render() {
    const { submitText } = this.props;
    return (
      <Formik
        initialValues={this.getInitialFormValues()}
        validationSchema={this.formSchema}
        onSubmit={this.handleSubmit}
        enableReinitialize
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          isValid,
          handleSubmit,
          touched,
          errors,
          setFieldValue,
          ...rest
        }) => {
          return (
            <div className="card card-default">
              <div className="card-header">
                <div className="h4">
                  <strong>{this.props.title}</strong>
                  <button
                    type="button"
                    className="float-right btn btn-xs btn-oval btn-outline-danger"
                    onClick={this.props.onCloseClick}
                  >
                    Close
                  </button>
                  <button
                    aria-disabled={!isValid || isSubmitting}
                    disabled={!isValid || isSubmitting}
                    onClick={handleSubmit}
                    type="submit"
                    className="float-right btn btn-xs mr-1 btn-oval btn-primary"
                  >
                    {submitText}
                  </button>
                </div>
              </div>
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <div className="form-group">
                    <label className="form-label" htmlFor="title">
                      Title
                    </label>
                    <input
                      id="title"
                      name="title"
                      type="text"
                      className={
                        touched.title && errors.title
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Image Title"
                      required
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback mx-1"
                    />
                  </div>

                  <div className="form-group">
                    <label className="form-label" htmlFor="description">
                      Description
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      rows="3"
                      className={
                        touched.note && errors.note
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="form-group">
                    <label className="switcher" htmlFor="is_active">
                      <input
                        id="is_active"
                        name="is_active"
                        type="checkbox"
                        className="switcher-input"
                        checked={values.is_active}
                        value={values.is_active}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyPress={e => {
                          if (e.key === "Enter")
                            setFieldValue("is_active", !values.is_active);
                        }}
                      />
                      <span className="switcher-indicator">
                        <span className="switcher-yes">
                          <span className="ion ion-md-checkmark" />
                        </span>
                        <span className="switcher-no">
                          <span className="ion ion-md-close" />
                        </span>
                      </span>
                      <span className="switcher-label">Active</span>
                    </label>
                  </div>

                  <button
                    aria-disabled={!isValid || isSubmitting}
                    disabled={!isValid || isSubmitting}
                    className="btn btn-primary my-2"
                    type="submit"
                  >
                    {submitText}
                  </button>
                </form>
              </div>
            </div>
          );
        }}
      </Formik>
    );
  }
}

export default GalleryCreateEditForm;
