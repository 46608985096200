import React from "react";
import PropTypes from "prop-types";

class GalleryDeleteConfirmation extends React.Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  render() {
    const { galleryInfo, onCancelClick, onDeleteClick } = this.props;
    return (
      <div className="card card-default mb-3">
        <div className="card-header">
          <div className="h4">
            Are you sure you want to delete the gallery{" "}
            <em className="font-weight-bold">"{galleryInfo.title}"</em>?
            <button
              onClick={onCancelClick}
              type="button"
              className="float-right btn btn-xs btn-oval btn-outline-primary"
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="card-body">
          <button
            onClick={() => onDeleteClick()}
            type="button"
            className="btn btn-danger mb-2"
          >
            Confirm delete
          </button>
        </div>
      </div>
    );
  }
}
GalleryDeleteConfirmation.propTypes = {
  galleryInfo: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

export default GalleryDeleteConfirmation;
