import React, { Component } from "react";
import PropTypes from "prop-types";
import LayoutNavBar from "../LayoutNavbar";
import LayoutSideNav from "../LayoutSideNav";
import LayoutFooter from "../LayoutFooter";
import { withTheme } from "../Theme";

class Layout1 extends Component {
  static propTypes = {
    theme: PropTypes.object.isRequired
  };

  componentDidMount() {
    // Update the theme layout since it could be initialized before mounting
    // the component.
    this.props.theme.update();
  }

  render() {
    return (
      <React.Fragment>
        <div className="layout-wrapper layout-1">
          <div className="layout-inner">
            <LayoutNavBar />

            <div className="layout-container">
              <LayoutSideNav />

              <div className="layout-content">
                <div className="container-fluid router-transitions flex-grow-1 container-p-y">
                  {this.props.children}
                </div>

                <LayoutFooter />
              </div>
            </div>
          </div>
        </div>
        <div
          className="layout-overlay layout-sidenav-toggle"
          onClick={this.props.theme.closeSideNav}
        />
      </React.Fragment>
    );
  }
}

export default withTheme(Layout1);
