import React from "react";
import PropTypes from "prop-types";
import SideNavItem from "./SideNavItem";
import * as routes from "../../config/routes";
import { isAdmin } from "../../config/permissions";
import { withTheme } from "../Theme";
import { withUser } from "../Auth";
import AdminSideNav from "./AdminSideNav";
import SideNav from "../../vendor/js/sidenav";
import "../../vendor/libs/perfect-scrollbar/perfect-scrollbar.css";

class LayoutSideNav extends React.Component {
  static propTypes = {
    theme: PropTypes.object.isRequired,
    user: PropTypes.object
  };

  static defaultProps = {
    user: null
  };

  componentDidMount() {
    this.initializeSideNav();
  }

  componentWillUnmount() {
    this.destroySideNav();
  }

  // Initialize theme sidenav to make it work
  initializeSideNav = () => {
    this.instance = new SideNav.SideNav(
      document.getElementById("layout-sidenav"),
      {
        orientation: "vertical"
      }
    );
  };

  // Destroy theme sidenav instance
  destroySideNav = () => {
    if (!this.sidenav) return;

    this.sidenav.destroy();
    this.sidenav = null;
  };

  render() {
    const { theme, user } = this.props;
    return (
      <div
        id="layout-sidenav"
        className={`layout-sidenav sidenav sidenav-vertical ${
          theme.layoutSidenavBg
          }`}
      >
        <ul className="sidenav-inner py-1">
          <SideNavItem to={routes.DASHBOARD} iconClassName="ion-md-home">
            Home
          </SideNavItem>
          <SideNavItem iconClassName="ion-md-cart" to="/to-do-store">
            Borger Store (soon)
          </SideNavItem>
          <SideNavItem iconClassName="ion-md-photos" to="/to-do-gallery">
            Photo Galleries (soon)
          </SideNavItem>
          {user && isAdmin(user) ? <AdminSideNav /> : null}
        </ul>
      </div>
    );
  }
}

LayoutSideNav.propTypes = {};

export default withTheme(withUser(LayoutSideNav));
