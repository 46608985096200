import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "reactstrap";

class Project extends Component {
  state = {
    editTooltipOpen: false,
    deleteTooltipOpen: false
  };

  static propTypes = {
    project: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      directions: PropTypes.string.isRequired,
      is_active: PropTypes.bool.isRequired
    }),
    onEditClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired
  };

  toggleTooltip = name => {
    this.setState(currentState => ({
      [name]: !currentState[name]
    }));
  };

  render() {
    const {
      project: { id, name, directions, is_active },
      onEditClick,
      onDeleteClick
    } = this.props;

    const { editTooltipOpen, deleteTooltipOpen } = this.state;

    return (
      <>
        <tr>
          <td className="align-middle">{id}</td>
          <td className="align-middle">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => onEditClick(this.props.project)}
            >
              {name}
            </button>
          </td>
          <td className="align-middle">{directions}</td>
          <td className="align-middle">{is_active ? "Yes" : "No"}</td>
          <td className="text-center text-nowrap align-middle">
            <button
              type="button"
              id={`edit-${id}`}
              onClick={() => onEditClick(this.props.project)}
              className="btn btn-default btn-xs icon-btn mr-1 bg-warning"
            >
              <i className="ion ion-md-create" />
            </button>
            <button
              type="button"
              id={`delete-${id}`}
              onClick={() => onDeleteClick(this.props.project)}
              className="btn btn-default btn-xs icon-btn bg-danger text-white"
            >
              <i className="ion ion-md-close" />
            </button>
          </td>
          <Tooltip
            placement="top"
            isOpen={editTooltipOpen}
            target={`edit-${id}`}
            toggle={() => this.toggleTooltip("editTooltipOpen")}
          >
            Edit
          </Tooltip>
          <Tooltip
            placement="top"
            isOpen={deleteTooltipOpen}
            target={`delete-${id}`}
            toggle={() => this.toggleTooltip("deleteTooltipOpen")}
          >
            Delete
          </Tooltip>
        </tr>
      </>
    );
  }
}

export default Project;
