import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as routes from "../../config/routes.js";
import DocumentTitle from "react-document-title";
import { IntlProvider } from "react-intl";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Theme from "../Theme";
import Dashboard from "../Dashboard";
import Login from "../Login";
import { withAuthentication } from "../Auth";
import GeneralMessaging from "../GeneralMessaging";
import Layout1 from "../Layout1";
import CallsMessaging from "../CallsMessaging";
import Projects from "../Projects";
import GalleryAdmin from "../GalleryAdmin";
import CareersPostings from "../CareersPostings";
import CareersApplicants from "../CareersApplicants";
import AccountTransactions from "../AccountTransactions";
import AccountUpdates from "../AccountUpdates";
import LearningAdmin from "../LearningAdmin";
import CourseAssign from "../CourseAssign";
import UserManagement from "../UserManagement";

class App extends Component {
  render() {
    return (
      <IntlProvider locale="en">
        <DocumentTitle title="Borger Team">
          <Theme>
            <Router>
              <Switch>
                <Route exact path={routes.LOGIN} component={Login} />
                <Layout1>
                  <Route exact path={"/"} component={Dashboard} />
                  <Route exact path={routes.DASHBOARD} component={Dashboard} />
                  <Route
                    exact
                    path={routes.MESSAGING.general}
                    component={GeneralMessaging}
                  />
                  <Route
                    exact
                    path={routes.MESSAGING.calls}
                    component={CallsMessaging}
                  />
                  <Route exact path={routes.PROJECTS} component={Projects} />
                  <Route
                    exact
                    path={routes.CAREERS.postings}
                    component={CareersPostings}
                  />
                  <Route
                    exact
                    path={routes.CAREERS.applicants}
                    component={CareersApplicants}
                  />
                  <Route
                    exact
                    path={routes.GALLERYADMIN}
                    component={GalleryAdmin}
                  />
                  <Route
                    exact
                    path={routes.ACCOUNTS.transactions}
                    component={AccountTransactions}
                  />
                  <Route
                    exact
                    path={routes.ACCOUNTS.update}
                    component={AccountUpdates}
                  />
                  <Route
                    exact
                    path={routes.LEARNING.admin}
                    component={LearningAdmin}
                  />
                  <Route
                    exact
                    path={routes.LEARNING.courseassign}
                    component={CourseAssign}
                  />
                  <Route
                    exact
                    path={routes.USER}
                    component={UserManagement}
                  />
                </Layout1>
              </Switch>
            </Router>
            <ToastContainer />
          </Theme>
        </DocumentTitle>
      </IntlProvider>
    );
  }
}

export default withAuthentication(App);
