import React, { Component } from "react";
import PropTypes from "prop-types";
import Axios from "axios";
import { withAuthorization } from "../Auth";
import * as routes from "../../config/routes";
import { hasPermission, PERMISSIONS } from "../../config/permissions";
import DocumentTitle from "react-document-title";
import CallsMessagingForm from "./CallsMessagingForm";
import CallsMessagingList from "./CallsMessagingList";

class CallsMessaging extends Component {
  state = {
    calls: []
  };

  static propTypes = {
    user: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.fetchCalls();
  }

  fetchCalls = async (page = 0) => {
    const URL = `${process.env.REACT_APP_API_URL}/phonecall/retrieve/${page}`;

    const response = await Axios.get(URL);
    this.setState({ calls: response.data });
  };

  submit = async payload => {
    const URL = `${process.env.REACT_APP_API_URL}/phonecall`;
    payload.createdBy = this.props.user.id;

    await Axios.post(URL, payload);
    if (!this.state.calls.length) return this.fetchMessages();

    const call = {
      id: this.state.calls[0].id + 1,
      createdAt: Date.now(),
      ...payload
    };

    this.setState(currentState => ({
      calls: [call, ...currentState.calls]
    }));
  };

  delete = async id => {
    const URL = `${process.env.REACT_APP_API_URL}/phonecall/${id}`;

    await Axios.delete(URL);
    this.setState(({ calls }) => ({
      calls: calls.filter(m => m.id !== id)
    }));
  };

  render() {
    const { calls } = this.state;
    return (
      <DocumentTitle title="Calls Messaging - Borger Team">
        <>
          <CallsMessagingForm onSubmit={this.submit} />
          <CallsMessagingList calls={calls} onDelete={this.delete} />
        </>
      </DocumentTitle>
    );
  }
}

const protectedPage = withAuthorization(
  user => hasPermission(user, PERMISSIONS.messaging),
  () => routes.DASHBOARD
);

export default protectedPage(CallsMessaging);
