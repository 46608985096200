import React from "react";
import PropTypes from "prop-types";
import Axios from "axios";
import AuthContext from "./AuthContext";
import { withStorage } from "../Storage";
import setUpInterceptors from "../../config/axios";

const withAuthentication = Component => {
  class Authentication extends React.Component {
    constructor(props) {
      super(props);

      if (!this.state.user) return;

      // Validate user's token if any.
      const { id, token } = this.state.user;
      setUpInterceptors(this.unauthenticate, token);
      const URL = `${process.env.REACT_APP_API_URL}/user/${id}`;
      Axios.get(URL);
    }

    static propTypes = {
      storage: PropTypes.object.isRequired
    };

    authenticate = (user, callback = () => {}) => {
      this.setState({ user }, () => {
        setUpInterceptors(this.unauthenticate, user.token);
        this.props.storage.setItem("borgerUser", JSON.stringify(user));
        callback();
      });
    };

    unauthenticate = () => {
      this.setState({ user: null });
      this.props.storage.removeItem("borgerUser");
    };

    state = {
      user: this.props.storage.getItem("borgerUser")
        ? JSON.parse(this.props.storage.getItem("borgerUser"))
        : null,
      authenticate: this.authenticate,
      unauthenticate: this.unauthenticate
    };

    componentDidMount() {
      const { user } = this.state;

      if (user) return;

      setUpInterceptors(this.unauthenticate);
    }

    render() {
      const { storage, ...props } = this.props;
      return (
        <AuthContext.Provider value={this.state}>
          <Component {...props} />
        </AuthContext.Provider>
      );
    }
  }

  return withStorage(Authentication);
};

export default withAuthentication;
