import React, { Component } from "react";
import Axios from "axios";
import { withAuthorization } from "../Auth";
import * as routes from "../../config/routes";
import { hasPermission, PERMISSIONS } from "../../config/permissions";
import DocumentTitle from "react-document-title";
import ProjectsList from "./ProjectsList";
import ProjectForm from "./ProjectForm";
import DeleteConfirmation from "../DeleteConfirmation";

class Projects extends Component {
  state = {
    projects: [],
    filteredProjects: [],
    selectedProject: {},
    searchValue: "",
    loading: true,
    currentAction: ""
  };

  actions = {
    create: "CREATE",
    update: "UPDATE",
    delete: "DELETE"
  };

  componentDidMount() {
    this.fetch();
  }

  setCurrentAction = (currentAction = "", selectedProject = {}) => {
    this.setState({ currentAction, selectedProject });
  };

  castProjectBooleanFields = project => {
    const fields = [
      "is_active",
      "is_underground",
      "is_earthworks",
      "is_maintenance"
    ];

    fields.forEach(field => {
      if (project[field] !== undefined) {
        project[field] = Boolean(project[field]);
      }
    });

    return project;
  };

  setProjectDirections = project => {
    if (project.latitude && project.longitude) {
      project.directions = "Coordinates";
    } else if (project.address && project.city && project.province) {
      project.directions = "Address";
    } else {
      project.directions = "Incomplete";
    }

    return project;
  };

  filter = () => {
    const { projects, searchValue } = this.state;

    const filteredProjects = projects.filter(
      ({ id, name }) =>
        name.toLowerCase().includes(searchValue.toLowerCase()) ||
        id.toString() === searchValue.toLowerCase()
    );

    this.setState({ filteredProjects });
  };

  onSearchChange = e => {
    this.setState({ searchValue: e.target.value }, this.filter);
  };

  sortProjects = (a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;

  fetch = async () => {
    const URL = `${process.env.REACT_APP_API_URL}/projects`;

    const response = await Axios.get(URL);

    const projects = response.data
      .map(this.setProjectDirections)
      .map(this.castProjectBooleanFields)
      .sort(this.sortProjects);

    this.setState({ projects, filteredProjects: projects, loading: false });
  };

  delete = async project => {
    const url = `${process.env.REACT_APP_API_URL}/projects/${project.id}`;

    await Axios.delete(url);

    this.setState(currentState => {
      const projects = currentState.projects
        .filter(p => p.id !== project.id)
        .sort(this.sortProjects);

      return {
        projects
      };
    }, this.filter);
  };

  update = async payload => {
    const URL = `${process.env.REACT_APP_API_URL}/projects/${
      this.state.selectedProject.id
    }`;

    const project = { ...payload };
    project.updated_by = this.props.user.id;

    await Axios.put(URL, {
      project
    });

    project.id = this.state.selectedProject.id;
    this.setProjectDirections(project);
    this.setState(currentState => {
      const projects = currentState.projects.map(p =>
        p.id === project.id ? project : p
      );
      return {
        projects: projects.sort(this.sortProjects)
      };
    }, this.filter);
  };

  create = async payload => {
    const URL = `${process.env.REACT_APP_API_URL}/projects`;

    const project = { ...payload, created_by: this.props.user.id };

    const response = await Axios.post(URL, {
      project
    });

    project.id = response.data.insertId;
    this.setProjectDirections(project);

    this.setState(
      currentState => ({
        projects: [...currentState.projects, project].sort(this.sortProjects)
      }),
      this.filter
    );
  };

  submit = async payload => {
    if (this.state.currentAction === this.actions.create) {
      await this.create(payload);
    } else if (this.state.currentAction === this.actions.update) {
      await this.update(payload);
    } else if (this.state.currentAction === this.actions.delete) {
      await this.delete(payload);
    }

    this.setCurrentAction();
  };

  render() {
    const {
      filteredProjects,
      selectedProject,
      searchValue,
      loading,
      currentAction
    } = this.state;
    return (
      <DocumentTitle title="Projects - Borger Team">
        <>
          <h4 className="d-flex justify-content-between align-items-center w-100 font-weight-bold py-3 mb-4">
            <div>Projects</div>
            <button
              onClick={() => this.setCurrentAction(this.actions.create)}
              type="button"
              className="btn btn-primary btn-round d-block"
            >
              <span className="ion ion-md-add" />
              &nbsp; Create Project
            </button>
          </h4>
          {currentAction === this.actions.create && (
            <ProjectForm
              title="Create Project"
              onCloseClick={_ => this.setCurrentAction()}
              onSubmit={this.submit}
            />
          )}
          {currentAction === this.actions.update && (
            <ProjectForm
              title="Update Project"
              project={selectedProject}
              onCloseClick={_ => this.setCurrentAction()}
              onSubmit={this.submit}
            />
          )}
          {currentAction === this.actions.delete && (
            <DeleteConfirmation
              name={`project ${selectedProject.name} (#${selectedProject.id})`}
              onCancelClick={_ => this.setCurrentAction()}
              onDeleteClick={this.submit}
              resource={selectedProject}
            />
          )}
          <ProjectsList
            searchValue={searchValue}
            search={this.onSearchChange}
            onDeleteClick={project =>
              this.setCurrentAction(this.actions.delete, project)
            }
            onEditClick={project =>
              this.setCurrentAction(this.actions.update, project)
            }
            projects={filteredProjects}
            loading={loading}
          />
        </>
      </DocumentTitle>
    );
  }
}

const protectedPage = withAuthorization(
  user => hasPermission(user, PERMISSIONS.projects),
  () => routes.DASHBOARD
);

export default protectedPage(Projects);
