import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import Pagination from '../common/Pagination';
import UsersFilters from "./UsersFilters";

class UsersList extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    sortDesc: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    sortKey: PropTypes.string.isRequired,
    searchValue: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired,
    filterField: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    sortBy: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    onPages: PropTypes.func.isRequired,
  };

  renderHeader = (path, label, sorting) => {
    const {
      sortBy,
      sortKey,
      sortDesc,
    } = this.props;
    if (!sorting) return <th className="text-nowrap">{label}</th>
    return (
      <th
        title="Sort"
        className="text-nowrap"
        onClick={() => sortBy(path)}
      >
        <button
          type="button"
          className="btn btn-link p-0"
          aria-pressed={sortKey === path}
        >
          {label}
          {sortKey === path && (
            <i
              className={`ion text-muted ml-2 ion-md-arrow-${
                sortDesc ? "down" : "up"
                }`}
            />
          )}
        </button>
      </th>
    )
  };
  renderRow = (user, columns) => (
    columns.map((col, i) => (
      (!col.content) ? (
        <td key={i}>
          {user[col.path]}
        </td>
      ) : col.content(i))
    )
  )
  renderButtons = (i) => {
    return (<td key={`buttons${i}`} className="text-center text-nowrap align-middle p-2">
      <button
        type="button"
        className="btn btn-default btn-xs icon-btn bg-warning mr-1"
      >
        <i className="ion ion-md-create" />
      </button>
      <button
        type="button"
        className="btn btn-default btn-xs icon-btn bg-danger text-white"
      >
        <i className="ion ion-md-close" />
      </button>
    </td>)
  }
  renderPageCount = () => {
    const {
      totalCount,
      loading,
      onPages,
      currentPage,
      pageSize,
    } = this.props;
    return (
      <div className="card-body py-0">
        <div className="d-flex justify-content-between align-items-baseline">
          <div className="pt-3 pb-3">
            <span className="text-muted">
              {loading ? "Loading..." : `Total items: ${totalCount}`}
            </span>
          </div>
          <div className="pt-3 pb-3">
            <Pagination
              count={totalCount}
              onPages={onPages}
              currentPage={currentPage}
              pageSize={pageSize}
            /></div>
        </div>
      </div>

    )
  }
  render() {
    const {
      users,
      searchValue,
      search,
      filterField,
      onFilterChange
    } = this.props;
    const columns = [
      { path: "emplid", label: "Employee ID", sorting: true },
      { path: "first_name", label: "First Name", sorting: true },
      { path: "last_name", label: "Last Name", sorting: true },
      { path: "username", label: "Username" },
      { path: "email_address", label: "Email", sorting: true },
      { path: "active", label: "Active" },
      { path: "eligible", label: "Eligible" },
      { path: "access_level", label: "Access Level" },
      { path: "login_count", label: "Login Count", sorting: true },
      {
        content: (i) => this.renderButtons(i), label: ""
      },
    ]

    return (
      <Fragment>
        <h4 className="d-flex justify-content-between align-items-center w-100 font-weight-bold py-3 mb-4">
          <div>Users</div>
          <button
            type="button"
            className="btn btn-primary btn-round d-block"
          >
            <span className="ion ion-md-add" />
            &nbsp; Create User
      </button>
        </h4>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  className="form-control d-inline-block"
                  placeholder="Search..."
                  value={searchValue}
                  onChange={search}
                />
              </div>
            </div>
          </div>
          <UsersFilters search={search} searchValue={searchValue} filterField={filterField} onFilterChange={onFilterChange} />
          {this.renderPageCount()}
          <hr className="border-light m-0" />
          <div className="table-responsive">
            <table className="table table-striped table-bordered card-table">
              <thead>
                <tr>
                  {columns.map((col, i) =>
                    <Fragment key={`col${i}`}>
                      {this.renderHeader(col.path, col.label, col.sorting)}
                    </Fragment>)}
                </tr>
              </thead>
              <tbody>
                {users.map(user => (
                  <tr key={`user${user.employee_id}`}>
                    {this.renderRow(user, columns)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {this.renderPageCount()}
        </div >
      </Fragment>
    );
  }
}

export default UsersList;
