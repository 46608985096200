import React, { Component } from "react";
import Axios from "axios";
import DocumentTitle from "react-document-title";
import { withAuthorization } from "../Auth";
import * as routes from "../../config/routes";
import { hasPermission, PERMISSIONS } from "../../config/permissions";
import CareersPostingsList from "./CareersPostingsList";
import CareersPostingForm from "./CareersPostingForm";
import DeleteConfirmation from "../DeleteConfirmation";

class CareersPostings extends Component {
  state = {
    loading: true,
    careers: [],
    filteredCareers: [],
    selectedCareer: {},
    searchValue: "",
    filterField: "all",
    currentAction: ""
  };

  actions = {
    create: "CREATE",
    update: "UPDATE",
    delete: "DELETE"
  };

  componentDidMount() {
    this.fetch();
  }

  setCurrentAction = (currentAction = "", selectedCareer = {}) => {
    this.setState({ currentAction, selectedCareer });
  };

  filter = () => {
    const filterOptions = {
      active: career => career.job_active,
      inactive: career => !career.job_active,
      all: career => career
    };

    this.setState(({ careers, filterField, searchValue }) => {
      return {
        filteredCareers: careers
          .filter(filterOptions[filterField])
          .filter(({ job_title }) =>
            job_title.toLowerCase().includes(searchValue.toLowerCase())
          )
      };
    });
  };

  sort = careers => {
    const active = careers.filter(career => career.job_active);
    const inactive = careers
      .filter(career => !career.job_active)
      .sort((a, b) => (a.job_title < b.job_title ? -1 : 1));

    return active.concat(inactive);
  };

  fetch = async () => {
    const URL = `${process.env.REACT_APP_API_URL}/careers`;
    const response = await Axios.get(URL);

    const careers = this.sort(response.data);

    this.setState({
      careers,
      filteredCareers: careers,
      loading: false
    });
  };

  handleSearchChange = e => {
    this.setState({ searchValue: e.target.value }, this.filter);
  };

  handleFilterChange = ({ target: { value } }) => {
    this.setState({ filterField: value }, this.filter);
  };

  create = async payload => {
    const URL = `${process.env.REACT_APP_API_URL}/careers`;

    const response = await Axios.post(URL, payload);

    const career = {
      job_id: response.data.insertId,
      job_title: payload.title,
      job_description: payload.description,
      job_active: payload.active
    };

    this.setState(
      currentState => ({
        careers: this.sort([...currentState.careers, career])
      }),
      this.filter
    );
  };

  update = async payload => {
    const URL = `${process.env.REACT_APP_API_URL}/careers/${
      this.state.selectedCareer.job_id
    }`;

    await Axios.put(URL, payload);

    const career = {
      job_id: this.state.selectedCareer.job_id,
      job_title: payload.title,
      job_description: payload.description,
      job_active: payload.active
    };

    this.setState(currentState => {
      const careers = currentState.careers.map(c =>
        c.job_id === career.job_id ? career : c
      );
      return {
        careers: this.sort(careers)
      };
    }, this.filter);
  };

  delete = async career => {
    const URL = `${process.env.REACT_APP_API_URL}/careers/${career.job_id}`;

    await Axios.delete(URL);

    this.setState(currentState => {
      const careers = this.sort(
        currentState.careers.filter(c => c.job_id !== career.job_id)
      );

      return { careers };
    }, this.filter);
  };

  submit = async payload => {
    if (this.state.currentAction === this.actions.create) {
      await this.create(payload);
    } else if (this.state.currentAction === this.actions.update) {
      await this.update(payload);
    } else if (this.state.currentAction === this.actions.delete) {
      await this.delete(payload);
    }

    this.setCurrentAction();
  };

  render() {
    const {
      loading,
      filteredCareers,
      searchValue,
      filterField,
      currentAction,
      selectedCareer
    } = this.state;
    return (
      <DocumentTitle title="Careers Postings - Borger Team">
        <>
          <h4 className="d-flex justify-content-between align-items-center w-100 font-weight-bold py-3 mb-4">
            <div>Careers Postings</div>
            <button
              onClick={() => this.setCurrentAction(this.actions.create)}
              type="button"
              className="btn btn-primary btn-round d-block"
            >
              <span className="ion ion-md-add" />
              &nbsp; Create Posting
            </button>
          </h4>
          {currentAction === this.actions.create && (
            <CareersPostingForm
              title="Create Posting"
              onCloseClick={_ => this.setCurrentAction()}
              onSubmit={this.submit}
            />
          )}
          {currentAction === this.actions.update && (
            <CareersPostingForm
              title="Update Posting"
              onCloseClick={_ => this.setCurrentAction()}
              onSubmit={this.submit}
              career={selectedCareer}
            />
          )}
          {currentAction === this.actions.delete && (
            <DeleteConfirmation
              name={`${selectedCareer.job_title} (#${selectedCareer.job_id})`}
              onCancelClick={_ => this.setCurrentAction()}
              onDeleteClick={this.submit}
              resource={selectedCareer}
            />
          )}
          <CareersPostingsList
            searchValue={searchValue}
            search={this.handleSearchChange}
            careers={filteredCareers}
            loading={loading}
            filterField={filterField}
            onFilterChange={this.handleFilterChange}
            onEditClick={career => {
              this.setCurrentAction(this.actions.update, career);
            }}
            onDeleteClick={career => {
              this.setCurrentAction(this.actions.delete, career);
            }}
          />
        </>
      </DocumentTitle>
    );
  }
}

const protectedPage = withAuthorization(
  user => hasPermission(user, PERMISSIONS.careers),
  () => routes.DASHBOARD
);

export default protectedPage(CareersPostings);
