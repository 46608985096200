import React, { Component } from "react";
import Axios from "axios";
import DocumentTitle from "react-document-title";
import { withAuthorization } from "../Auth";
import * as routes from "../../config/routes";
import { hasPermission, PERMISSIONS } from "../../config/permissions";
import TransactionsFilters from "./TransactionsFilters";
import moment from "moment";
import { CSVLink } from "react-csv";

class AccountTransactions extends Component {
  state = {
    loading: true,
    activePage: "bucks",
    bucksSelectedEmployee: 0,
    spcSelectedEmployee: 0,
    coinSelectedEmployee: 0,
    employees: [],
    bucksSelectedStartDate: moment()
      .subtract(1, "months")
      .toDate(),
    bucksSelectedEndDate: moment()
      .add(1, "days")
      .toDate(),
    spcSelectedStartDate: moment()
      .subtract(1, "months")
      .toDate(),
    spcSelectedEndDate: moment()
      .add(1, "days")
      .toDate(),
    bucksTransactions: [],
    bucksDeposits: 0,
    bucksWithdrawals: 0,
    bucksDataToDownload: [],
    spcTransactions: [],
    spcDeposits: 0,
    spcWithdrawals: 0,
    spcDataToDownload: [],
    coinTransactions: [],
    coinDeposits: 0,
    coinWithdrawals: 0,
    coinDataToDownload: []
  };

  bucksHeaders = [
    "Employee ID",
    "Name",
    "Transaction Date",
    "Transaction Description",
    "Deposits",
    "Withdrawals",
    "Balance"
  ];

  spcHeaders = [
    "Employee ID",
    "Name",
    "Transaction Date",
    "Transaction Description",
    "Deposits",
    "Withdrawals",
    "Balance"
  ];

  coinHeaders = [
    "Employee ID",
    "Name",
    "Transaction Description",
    "Deposits",
    "Withdrawals",
    "Balance"
  ];

  bucksCsvHeaders = [
    { label: "Employee ID", key: "emplid" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "email_address" },
    { label: "Company", key: "company" },
    { label: "Transaction ID", key: "transactionId" },
    { label: "Transaction Date", key: "transactionDate" },
    { label: "Description", key: "description" },
    { label: "Transaction Type", key: "transactionType" },
    { label: "Amount", key: "amount" },
    { label: "Balance", key: "balance" },
    { label: "System Employee ID", key: "employee_id" }
  ];

  spcCsvHeaders = [
    { label: "Employee ID", key: "emplid" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "email_address" },
    { label: "Company", key: "company" },
    { label: "Transaction ID", key: "transactionId" },
    { label: "Transaction Date", key: "transactionDate" },
    { label: "Description", key: "description" },
    { label: "Amount", key: "amount" },
    { label: "Balance", key: "balance" },
    { label: "System Employee ID", key: "employee_id" }
  ];

  coinCsvHeaders = [
    { label: "Employee ID", key: "emplid" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "email_address" },
    { label: "Company", key: "company" },
    { label: "Transaction ID", key: "transactionId" },
    { label: "Description", key: "description" },
    { label: "Amount", key: "amount" },
    { label: "Balance", key: "balance" },
    { label: "System Employee ID", key: "employee_id" }
  ];

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    let URL = `${process.env.REACT_APP_API_URL}/user/all`;
    let response = await Axios.get(URL);
    const employees = response.data;

    this.setState({ employees, loading: false });
  };

  onPageChange = page => {
    this.setState({ activePage: page });
  };

  onEmployeeChange = e => {
    switch (this.state.activePage) {
      case "bucks":
        this.setState({ bucksSelectedEmployee: e.target.value });
        break;
      case "spc":
        this.setState({ spcSelectedEmployee: e.target.value });
        break;
      case "coins":
        this.setState({ coinSelectedEmployee: e.target.value });
        break;
      default:
        console.log("Invalid case.");
    }
  };

  onStartDateChange = value => {
    switch (this.state.activePage) {
      case "bucks":
        this.setState({
          bucksSelectedStartDate: value
        });
        break;
      case "spc":
        this.setState({
          spcSelectedStartDate: value
        });
        break;
      case "coins":
        this.setState({
          coinSelectedStartDate: value
        });
        break;
      default:
        console.log("Invalid case.");
    }
  };

  onEndDateChange = value => {
    switch (this.state.activePage) {
      case "bucks":
        this.setState({
          bucksSelectedEndDate: value
        });
        break;
      case "spc":
        this.setState({
          spcSelectedEndDate: value
        });
        break;
      case "coins":
        this.setState({
          coinSelectedEndDate: value
        });
        break;
      default:
        console.log("Invalid case.");
    }
  };

  sumDepoWith = transactions => {
    let sumDepo = 0;
    let sumWith = 0;
    for (let t of transactions) {
      let amt = parseInt(t.amount);
      if (amt > 0) {
        sumDepo += amt;
      } else {
        sumWith += amt;
      }
    }

    return { deposit: sumDepo, withdrawal: sumWith };
  };

  executeSearch = async () => {
    const {
      bucksSelectedStartDate,
      bucksSelectedEndDate,
      spcSelectedStartDate,
      spcSelectedEndDate,
      bucksSelectedEmployee,
      spcSelectedEmployee,
      coinSelectedEmployee,
      employees
    } = this.state;
    let URL, response;
    switch (this.state.activePage) {
      case "bucks":
        URL = `${
          process.env.REACT_APP_API_URL
        }/rewards/bucks?start=${encodeURIComponent(
          moment(bucksSelectedStartDate).format("YYYY-MM-DD")
        )}&end=${encodeURIComponent(
          moment(bucksSelectedEndDate).format("YYYY-MM-DD")
        )}`;
        if (bucksSelectedEmployee > 0) {
          URL = `${URL}&employee_id=${encodeURIComponent(
            employees[bucksSelectedEmployee - 1].employee_id
          )}`;
        }
        response = await Axios.get(URL);
        const bucksTransactions = response.data;

        const {
          deposit: bucksDeposits,
          withdrawal: bucksWithdrawals
        } = this.sumDepoWith(bucksTransactions);

        this.setState({
          bucksTransactions,
          bucksDeposits,
          bucksWithdrawals
        });
        break;
      case "spc":
        URL = `${
          process.env.REACT_APP_API_URL
        }/rewards/spc?start=${encodeURIComponent(
          moment(spcSelectedStartDate).format("YYYY-MM-DD")
        )}&end=${encodeURIComponent(
          moment(spcSelectedEndDate).format("YYYY-MM-DD")
        )}`;
        if (spcSelectedEmployee > 0) {
          URL = `${URL}&employee_id=${encodeURIComponent(
            employees[spcSelectedEmployee - 1].employee_id
          )}`;
        }
        response = await Axios.get(URL);
        const spcTransactions = response.data;

        const {
          deposit: spcDeposits,
          withdrawal: spcWithdrawals
        } = this.sumDepoWith(spcTransactions);

        this.setState({
          spcTransactions,
          spcDeposits,
          spcWithdrawals
        });
        break;
      case "coins":
        URL = `${process.env.REACT_APP_API_URL}/rewards/coin`;
        if (coinSelectedEmployee > 0) {
          URL = `${URL}?employee_id=${encodeURIComponent(
            employees[coinSelectedEmployee - 1].employee_id
          )}`;
        }
        response = await Axios.get(URL);
        const coinTransactions = response.data;

        const {
          deposit: coinDeposits,
          withdrawal: coinWithdrawals
        } = this.sumDepoWith(coinTransactions);

        this.setState({
          coinTransactions,
          coinDeposits,
          coinWithdrawals
        });
        break;
      default:
        console.log("Invalid case.");
    }
  };

  downloadCsv = () => {
    switch (this.state.activePage) {
      case "bucks":
        this.setState(
          currentState => ({
            bucksDataToDownload: [
              this.bucksHeaders,
              ...currentState.bucksTransactions
            ]
          }),
          () => {
            this.bucksCsvLink.link.click();
          }
        );
        break;
      case "spc":
        this.setState(
          currentState => ({
            spcDataToDownload: [
              this.spcHeaders,
              ...currentState.spcTransactions
            ]
          }),
          () => {
            this.spcCsvLink.link.click();
          }
        );
        break;
      case "coins":
        this.setState(
          currentState => ({
            coinDataToDownload: [
              this.coinHeaders,
              ...currentState.coinTransactions
            ]
          }),
          () => {
            this.coinCsvLink.link.click();
          }
        );
        break;
      default:
        console.log("Invalid case.");
    }
  };

  render() {
    const {
      loading,
      activePage,
      bucksSelectedEmployee,
      spcSelectedEmployee,
      coinSelectedEmployee,
      employees,
      bucksSelectedStartDate,
      bucksSelectedEndDate,
      spcSelectedStartDate,
      spcSelectedEndDate,
      bucksTransactions,
      bucksDeposits,
      bucksWithdrawals,
      spcTransactions,
      spcDeposits,
      spcWithdrawals,
      coinTransactions,
      coinDeposits,
      coinWithdrawals
    } = this.state;
    return (
      <DocumentTitle title="Account Transactions - Borger Team">
        <div className="card">
          <div className="card-body">
            <h1>Borger Account Transactions: {activePage.toUpperCase()}</h1>
            {loading && <h4>Loading...</h4>}
            <button
              type="button"
              className={
                activePage === "bucks"
                  ? "btn m-1 btn-primary"
                  : "btn m-1 btn-secondary"
              }
              onClick={() => this.onPageChange("bucks")}
            >
              Borger Bucks
            </button>

            <button
              type="button"
              className={
                activePage === "spc"
                  ? "btn m-1 btn-primary"
                  : "btn m-1 btn-secondary"
              }
              onClick={() => this.onPageChange("spc")}
            >
              SPC Wins
            </button>

            <button
              type="button"
              className={
                activePage === "coins"
                  ? "btn m-1 btn-primary"
                  : "btn m-1 btn-secondary"
              }
              onClick={() => this.onPageChange("coins")}
            >
              Innovation Coins
            </button>
            <br />
            <br />
            {activePage === "bucks" && (
              <div>
                <TransactionsFilters
                  selectedEmployee={bucksSelectedEmployee}
                  onEmployeeChange={this.onEmployeeChange}
                  employees={employees}
                  showDatePicker={true}
                  selectedStartDate={bucksSelectedStartDate}
                  onStartDateChange={this.onStartDateChange}
                  selectedEndDate={bucksSelectedEndDate}
                  onEndDateChange={this.onEndDateChange}
                  executeSearch={this.executeSearch}
                />

                {bucksTransactions.length > 0 ? (
                  <div>
                    <br />
                    <center>
                      <b>Borger Bucks Summary</b>
                      <br />
                      {moment(bucksSelectedStartDate).format(
                        "MMMM D, YYYY"
                      )} to{" "}
                      {moment(bucksSelectedEndDate).format("MMMM D, YYYY")}
                      <div className="row">
                        <div className="col">
                          ALLOCATED
                          <br />
                          {bucksDeposits}
                        </div>
                        <div className="col">
                          REDEEMED
                          <br />
                          {-bucksWithdrawals}
                        </div>
                      </div>
                    </center>
                    <br />

                    <button
                      onClick={this.downloadCsv}
                      type="button"
                      className="btn btn-primary btn-round d-block"
                      style={{ float: "right" }}
                    >
                      Export CSV
                    </button>
                    <CSVLink
                      data={bucksTransactions}
                      headers={this.bucksCsvHeaders}
                      filename="buck-transactions.csv"
                      className="d-none"
                      ref={r => (this.bucksCsvLink = r)}
                      target="_blank"
                    />

                    <div className="table-responsive">
                      <table className="table table-striped table-bordered card-table">
                        <thead>
                          <tr>
                            {this.bucksHeaders.map(h => (
                              <th key={h}>{h}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {bucksTransactions.map(t => (
                            <tr key={t.transactionId}>
                              <td>{t.emplid}</td>
                              <td>{`${t.first_name} ${t.last_name}`}</td>
                              <td>
                                {moment(t.transactionDate).format(
                                  "MMM DD, YYYY"
                                )}
                              </td>
                              <td>{t.description}</td>
                              <td>{t.amount > 0 && t.amount}</td>
                              <td>{t.amount < 0 && -t.amount}</td>
                              <td>{t.balance}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td>
                              <b>{bucksDeposits}</b>
                            </td>
                            <td>
                              <b>{-bucksWithdrawals}</b>
                            </td>
                            <td />
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div>No records loaded.</div>
                )}
              </div>
            )}

            {activePage === "spc" && (
              <div>
                <TransactionsFilters
                  selectedEmployee={spcSelectedEmployee}
                  onEmployeeChange={this.onEmployeeChange}
                  employees={employees}
                  showDatePicker={true}
                  selectedStartDate={spcSelectedStartDate}
                  onStartDateChange={this.onStartDateChange}
                  selectedEndDate={spcSelectedEndDate}
                  onEndDateChange={this.onEndDateChange}
                  executeSearch={this.executeSearch}
                />

                {spcTransactions.length > 0 ? (
                  <div>
                    <br />

                    <button
                      onClick={this.downloadCsv}
                      type="button"
                      className="btn btn-primary btn-round d-block"
                      style={{ float: "right" }}
                    >
                      Export CSV
                    </button>
                    <CSVLink
                      data={spcTransactions}
                      headers={this.spcCsvHeaders}
                      filename="spc-transactions.csv"
                      className="d-none"
                      ref={r => (this.spcCsvLink = r)}
                      target="_blank"
                    />

                    <div className="table-responsive">
                      <table className="table table-striped table-bordered card-table">
                        <thead>
                          <tr>
                            {this.spcHeaders.map(h => (
                              <th key={h}>{h}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {spcTransactions.map(t => (
                            <tr key={t.transactionId}>
                              <td>{t.emplid}</td>
                              <td>{`${t.first_name} ${t.last_name}`}</td>
                              <td>
                                {moment(t.transactionDate).format(
                                  "MMM DD, YYYY"
                                )}
                              </td>
                              <td>{t.description}</td>
                              <td>{t.amount > 0 && t.amount}</td>
                              <td>{t.amount < 0 && -t.amount}</td>
                              <td>{t.balance}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td>
                              <b>{spcDeposits}</b>
                            </td>
                            <td>
                              <b>{-spcWithdrawals}</b>
                            </td>
                            <td />
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div>No records loaded.</div>
                )}
              </div>
            )}

            {activePage === "coins" && (
              <div>
                <TransactionsFilters
                  selectedEmployee={coinSelectedEmployee}
                  onEmployeeChange={this.onEmployeeChange}
                  employees={employees}
                  showDatePicker={false}
                  executeSearch={this.executeSearch}
                />

                {coinTransactions.length > 0 ? (
                  <div>
                    <br />

                    <button
                      onClick={this.downloadCsv}
                      type="button"
                      className="btn btn-primary btn-round d-block"
                      style={{ float: "right" }}
                    >
                      Export CSV
                    </button>
                    <CSVLink
                      data={coinTransactions}
                      headers={this.coinCsvHeaders}
                      filename="coin-transactions.csv"
                      className="d-none"
                      ref={r => (this.coinCsvLink = r)}
                      target="_blank"
                    />

                    <div className="table-responsive">
                      <table className="table table-striped table-bordered card-table">
                        <thead>
                          <tr>
                            {this.coinHeaders.map(h => (
                              <th key={h}>{h}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {coinTransactions.map(t => (
                            <tr key={t.transactionId}>
                              <td>{t.emplid}</td>
                              <td>{`${t.first_name} ${t.last_name}`}</td>
                              <td>{t.description}</td>
                              <td>{t.amount > 0 && t.amount}</td>
                              <td>{t.amount < 0 && -t.amount}</td>
                              <td>{t.balance}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td />
                            <td />
                            <td />
                            <td>
                              <b>{coinDeposits}</b>
                            </td>
                            <td>
                              <b>{-coinWithdrawals}</b>
                            </td>
                            <td />
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div>No records loaded.</div>
                )}
              </div>
            )}
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

const protectedPage = withAuthorization(
  user => hasPermission(user, PERMISSIONS.accounts),
  () => routes.DASHBOARD
);

export default protectedPage(AccountTransactions);
