/* eslint-disable */
!(function(e, t) {
  var n = (function(e) {
    var t = {};
    function n(i) {
      if (t[i]) return t[i].exports;
      var s = (t[i] = { i: i, l: !1, exports: {} });
      return e[i].call(s.exports, s, s.exports, n), (s.l = !0), s.exports;
    }
    return (
      (n.m = e),
      (n.c = t),
      (n.d = function(e, t, i) {
        n.o(e, t) ||
          Object.defineProperty(e, t, {
            configurable: !1,
            enumerable: !0,
            get: i
          });
      }),
      (n.r = function(e) {
        Object.defineProperty(e, "__esModule", { value: !0 });
      }),
      (n.n = function(e) {
        var t =
          e && e.__esModule
            ? function() {
                return e.default;
              }
            : function() {
                return e;
              };
        return n.d(t, "a", t), t;
      }),
      (n.o = function(e, t) {
        return Object.prototype.hasOwnProperty.call(e, t);
      }),
      (n.p = ""),
      n((n.s = 450))
    );
  })({
    450: function(e, t, n) {
      "use strict";
      Object.defineProperty(t, "__esModule", { value: !0 });
      var i = (function() {
          function e(e, t) {
            for (var n = 0; n < t.length; n++) {
              var i = t[n];
              (i.enumerable = i.enumerable || !1),
                (i.configurable = !0),
                "value" in i && (i.writable = !0),
                Object.defineProperty(e, i.key, i);
            }
          }
          return function(t, n, i) {
            return n && e(t.prototype, n), i && e(t, i), t;
          };
        })(),
        s = ["transitionend", "webkitTransitionEnd", "oTransitionEnd"],
        o = [
          "transition",
          "MozTransition",
          "webkitTransition",
          "WebkitTransition",
          "OTransition"
        ],
        r = (function() {
          function e(t) {
            var n =
                arguments.length > 1 && void 0 !== arguments[1]
                  ? arguments[1]
                  : {},
              i =
                arguments.length > 2 && void 0 !== arguments[2]
                  ? arguments[2]
                  : null;
            if (
              ((function(e, t) {
                if (!(e instanceof t))
                  throw new TypeError("Cannot call a class as a function");
              })(this, e),
              (this._el = t),
              (this._horizontal = "horizontal" === n.orientation),
              (this._animate =
                !1 !== n.animate && this._supportsTransitionEnd()),
              (this._accordion = !1 !== n.accordion),
              (this._closeChildren = Boolean(n.closeChildren)),
              (this._showDropdownOnHover = Boolean(n.showDropdownOnHover)),
              (this._rtl =
                "rtl" === document.documentElement.getAttribute("dir") ||
                "rtl" === document.body.getAttribute("dir")),
              (this._lastWidth = this._horizontal ? window.innerWidth : null),
              (this._onOpen = n.onOpen || function() {}),
              (this._onOpened = n.onOpened || function() {}),
              (this._onClose = n.onClose || function() {}),
              (this._onClosed = n.onClosed || function() {}),
              t.classList.add("sidenav"),
              t.classList[this._animate ? "remove" : "add"](
                "sidenav-no-animation"
              ),
              this._horizontal)
            ) {
              t.classList.add("sidenav-horizontal"),
                t.classList.remove("sidenav-vertical"),
                (this._inner = t.querySelector(".sidenav-inner"));
              var s = this._inner.parentNode;
              (this._prevBtn = t.querySelector(".sidenav-horizontal-prev")),
                this._prevBtn ||
                  ((this._prevBtn = document.createElement("a")),
                  (this._prevBtn.href = "#"),
                  (this._prevBtn.className = "sidenav-horizontal-prev"),
                  s.appendChild(this._prevBtn)),
                (this._wrapper = t.querySelector(
                  ".sidenav-horizontal-wrapper"
                )),
                this._wrapper ||
                  ((this._wrapper = document.createElement("div")),
                  (this._wrapper.className = "sidenav-horizontal-wrapper"),
                  this._wrapper.appendChild(this._inner),
                  s.appendChild(this._wrapper)),
                (this._nextBtn = t.querySelector(".sidenav-horizontal-next")),
                this._nextBtn ||
                  ((this._nextBtn = document.createElement("a")),
                  (this._nextBtn.href = "#"),
                  (this._nextBtn.className = "sidenav-horizontal-next"),
                  s.appendChild(this._nextBtn)),
                (this._innerPosition = 0),
                this.update();
            } else {
              t.classList.add("sidenav-vertical"),
                t.classList.remove("sidenav-horizontal");
              var o = i || window.PerfectScrollbar;
              o &&
                (this._scrollbar = new o(t.querySelector(".sidenav-inner"), {
                  suppressScrollX: !0,
                  wheelPropagation: !0
                }));
            }
            this._bindEvents(), (t.sidenavInstance = this);
          }
          return (
            i(e, [
              {
                key: "open",
                value: function(e) {
                  var t = this,
                    n =
                      arguments.length > 1 && void 0 !== arguments[1]
                        ? arguments[1]
                        : this._closeChildren,
                    i = this._findUnopenedParent(this._getItem(e, !0), n);
                  if (i) {
                    var s = this._getLink(i, !0);
                    this._horizontal && this._isRoot(i)
                      ? (this._onOpen(this, i, s, this._findMenu(i)),
                        this._toggleDropdown(!0, i, n),
                        this._onOpened(this, i, s, this._findMenu(i)))
                      : (this._animate
                          ? (this._onOpen(this, i, s, this._findMenu(i)),
                            window.requestAnimationFrame(function() {
                              return t._toggleAnimation(!0, i, !1);
                            }))
                          : (this._onOpen(this, i, s, this._findMenu(i)),
                            i.classList.add("open"),
                            this._onOpened(this, i, s, this._findMenu(i))),
                        this._accordion && this._closeOther(i, n));
                  }
                }
              },
              {
                key: "close",
                value: function(e) {
                  var t = this,
                    n =
                      arguments.length > 1 && void 0 !== arguments[1]
                        ? arguments[1]
                        : this._closeChildren,
                    i = this._getItem(e, !0),
                    s = this._getLink(e, !0);
                  if (
                    i.classList.contains("open") &&
                    !i.classList.contains("disabled")
                  )
                    if (this._horizontal && this._isRoot(i))
                      this._onClose(this, i, s, this._findMenu(i)),
                        this._toggleDropdown(!1, i, n),
                        this._onClosed(this, i, s, this._findMenu(i));
                    else if (this._animate)
                      this._onClose(this, i, s, this._findMenu(i)),
                        window.requestAnimationFrame(function() {
                          return t._toggleAnimation(!1, i, n);
                        });
                    else {
                      if (
                        (this._onClose(this, i, s, this._findMenu(i)),
                        i.classList.remove("open"),
                        n)
                      )
                        for (
                          var o = i.querySelectorAll(".sidenav-item.open"),
                            r = 0,
                            l = o.length;
                          r < l;
                          r++
                        )
                          o[r].classList.remove("open");
                      this._onClosed(this, i, s, this._findMenu(i));
                    }
                }
              },
              {
                key: "toggle",
                value: function(e) {
                  var t =
                      arguments.length > 1 && void 0 !== arguments[1]
                        ? arguments[1]
                        : this._closeChildren,
                    n = this._getItem(e, !0);
                  this._getLink(e, !0),
                    n.classList.contains("open")
                      ? this.close(n, t)
                      : this.open(n, t);
                }
              },
              {
                key: "closeAll",
                value: function() {
                  for (
                    var e =
                        arguments.length > 0 && void 0 !== arguments[0]
                          ? arguments[0]
                          : this._closeChildren,
                      t = this._el.querySelectorAll(
                        ".sidenav-inner > .sidenav-item.open"
                      ),
                      n = 0,
                      i = t.length;
                    n < i;
                    n++
                  )
                    this.close(t[n], e);
                }
              },
              {
                key: "setActive",
                value: function(e, t) {
                  var n =
                      !(arguments.length > 2 && void 0 !== arguments[2]) ||
                      arguments[2],
                    i =
                      !(arguments.length > 3 && void 0 !== arguments[3]) ||
                      arguments[3],
                    s = this._getItem(e, !1);
                  if (t && i)
                    for (
                      var o = this._el.querySelectorAll(
                          ".sidenav-inner .sidenav-item.active"
                        ),
                        r = 0,
                        l = o.length;
                      r < l;
                      r++
                    )
                      o[r].classList.remove("active");
                  if (t && n) {
                    var a = this._findParent(s, "sidenav-item", !1);
                    a && this.open(a);
                  }
                  for (; s; )
                    s.classList[t ? "add" : "remove"]("active"),
                      (s = this._findParent(s, "sidenav-item", !1));
                }
              },
              {
                key: "setDisabled",
                value: function(e, t) {
                  this._getItem(e, !1).classList[t ? "add" : "remove"](
                    "disabled"
                  );
                }
              },
              {
                key: "isActive",
                value: function(e) {
                  return this._getItem(e, !1).classList.contains("active");
                }
              },
              {
                key: "isOpened",
                value: function(e) {
                  return this._getItem(e, !1).classList.contains("open");
                }
              },
              {
                key: "isDisabled",
                value: function(e) {
                  return this._getItem(e, !1).classList.contains("disabled");
                }
              },
              {
                key: "update",
                value: function() {
                  if (this._horizontal) {
                    this.closeAll();
                    var e = Math.round(
                        this._wrapper.getBoundingClientRect().width
                      ),
                      t = this._innerWidth,
                      n = this._innerPosition;
                    e - n > t &&
                      ((n = e - t) > 0 && (n = 0), (this._innerPosition = n)),
                      this._updateSlider(e, t, n);
                  } else this._scrollbar && this._scrollbar.update();
                }
              },
              {
                key: "_updateSlider",
                value: function() {
                  var e =
                      arguments.length > 0 && void 0 !== arguments[0]
                        ? arguments[0]
                        : null,
                    t =
                      arguments.length > 1 && void 0 !== arguments[1]
                        ? arguments[1]
                        : null,
                    n =
                      arguments.length > 2 && void 0 !== arguments[2]
                        ? arguments[2]
                        : null,
                    i =
                      null !== e
                        ? e
                        : Math.round(
                            this._wrapper.getBoundingClientRect().width
                          ),
                    s = null !== t ? t : this._innerWidth,
                    o = null !== n ? n : this._innerPosition;
                  0 === o
                    ? this._prevBtn.classList.add("disabled")
                    : this._prevBtn.classList.remove("disabled"),
                    s + o <= i
                      ? this._nextBtn.classList.add("disabled")
                      : this._nextBtn.classList.remove("disabled");
                }
              },
              {
                key: "destroy",
                value: function() {
                  if (this._el) {
                    this._unbindEvents();
                    for (
                      var e = this._el.querySelectorAll(".sidenav-item"),
                        t = 0,
                        n = e.length;
                      t < n;
                      t++
                    )
                      this._unbindAnimationEndEvent(e[t]),
                        e[t].classList.remove("sidenav-item-animating"),
                        e[t].classList.remove("open"),
                        (e[t].style.overflow = null),
                        (e[t].style.height = null);
                    for (
                      var i = this._el.querySelectorAll(".sidenav-menu"),
                        s = 0,
                        o = i.length;
                      s < o;
                      s++
                    )
                      (i[s].style.marginRight = null),
                        (i[s].style.marginLeft = null);
                    this._el.classList.remove("sidenav-no-animation"),
                      this._wrapper &&
                        (this._prevBtn.parentNode.removeChild(this._prevBtn),
                        this._nextBtn.parentNode.removeChild(this._nextBtn),
                        this._wrapper.parentNode.insertBefore(
                          this._inner,
                          this._wrapper
                        ),
                        this._wrapper.parentNode.removeChild(this._wrapper),
                        (this._inner.style.marginLeft = null),
                        (this._inner.style.marginRight = null)),
                      (this._el.sidenavInstance = null),
                      delete this._el.sidenavInstance,
                      (this._el = null),
                      (this._horizontal = null),
                      (this._animate = null),
                      (this._accordion = null),
                      (this._closeChildren = null),
                      (this._showDropdownOnHover = null),
                      (this._rtl = null),
                      (this._onOpen = null),
                      (this._onOpened = null),
                      (this._onClose = null),
                      (this._onClosed = null),
                      this._scrollbar &&
                        (this._scrollbar.destroy(), (this._scrollbar = null)),
                      (this._inner = null),
                      (this._prevBtn = null),
                      (this._wrapper = null),
                      (this._nextBtn = null);
                  }
                }
              },
              {
                key: "_getLink",
                value: function(e, t) {
                  var n = [],
                    i = t ? "sidenav-toggle" : "sidenav-link";
                  if (
                    (e.classList.contains(i)
                      ? (n = [e])
                      : e.classList.contains("sidenav-item") &&
                        (n = this._findChild(e, [i])),
                    !n.length)
                  )
                    throw new Error("`" + i + "` element not found.");
                  return n[0];
                }
              },
              {
                key: "_getItem",
                value: function(e, t) {
                  var n = null,
                    i = t ? "sidenav-toggle" : "sidenav-link";
                  if (
                    (e.classList.contains("sidenav-item")
                      ? this._findChild(e, [i]).length && (n = e)
                      : e.classList.contains(i) &&
                        (n = e.parentNode.classList.contains("sidenav-item")
                          ? e.parentNode
                          : null),
                    !n)
                  )
                    throw new Error(
                      (t ? "Toggable " : "") +
                        "`.sidenav-item` element not found."
                    );
                  return n;
                }
              },
              {
                key: "_findUnopenedParent",
                value: function(e, t) {
                  for (var n = [], i = null; e; )
                    e.classList.contains("disabled")
                      ? ((i = null), (n = []))
                      : (e.classList.contains("open") || (i = e), n.push(e)),
                      (e = this._findParent(e, "sidenav-item", !1));
                  if (!i) return null;
                  if (1 === n.length) return i;
                  for (
                    var s = 0, o = (n = n.slice(0, n.indexOf(i))).length;
                    s < o;
                    s++
                  )
                    if ((n[s].classList.add("open"), this._accordion))
                      for (
                        var r = this._findChild(n[s].parentNode, [
                            "sidenav-item",
                            "open"
                          ]),
                          l = 0,
                          a = r.length;
                        l < a;
                        l++
                      )
                        if (r[l] !== n[s] && (r[l].classList.remove("open"), t))
                          for (
                            var d = r[l].querySelectorAll(".sidenav-item.open"),
                              h = 0,
                              u = d.length;
                            h < u;
                            h++
                          )
                            d[h].classList.remove("open");
                  return i;
                }
              },
              {
                key: "_closeOther",
                value: function(e, t) {
                  for (
                    var n = this._findChild(e.parentNode, [
                        "sidenav-item",
                        "open"
                      ]),
                      i = 0,
                      s = n.length;
                    i < s;
                    i++
                  )
                    n[i] !== e && this.close(n[i], t);
                }
              },
              {
                key: "_toggleAnimation",
                value: function(e, t, n) {
                  var i = this,
                    s = this._getLink(t, !0),
                    o = this._findMenu(t);
                  this._unbindAnimationEndEvent(t);
                  var r = Math.round(s.getBoundingClientRect().height);
                  t.style.overflow = "hidden";
                  var l = function() {
                    t.classList.remove("sidenav-item-animating"),
                      t.classList.remove("sidenav-item-closing"),
                      (t.style.overflow = null),
                      (t.style.height = null),
                      i._horizontal || i.update();
                  };
                  e
                    ? ((t.style.height = r + "px"),
                      t.classList.add("sidenav-item-animating"),
                      t.classList.add("open"),
                      this._bindAnimationEndEvent(t, function() {
                        l(), i._onOpened(i, t, s, o);
                      }),
                      setTimeout(function() {
                        return (t.style.height =
                          r +
                          Math.round(o.getBoundingClientRect().height) +
                          "px");
                      }, 50))
                    : ((t.style.height =
                        r +
                        Math.round(o.getBoundingClientRect().height) +
                        "px"),
                      t.classList.add("sidenav-item-animating"),
                      t.classList.add("sidenav-item-closing"),
                      this._bindAnimationEndEvent(t, function() {
                        if ((t.classList.remove("open"), l(), n))
                          for (
                            var e = t.querySelectorAll(".sidenav-item.open"),
                              r = 0,
                              a = e.length;
                            r < a;
                            r++
                          )
                            e[r].classList.remove("open");
                        i._onClosed(i, t, s, o);
                      }),
                      setTimeout(function() {
                        return (t.style.height = r + "px");
                      }, 50));
                }
              },
              {
                key: "_toggleDropdown",
                value: function(e, t, n) {
                  var i = this._findMenu(t);
                  if (e) {
                    var s = Math.round(
                        this._wrapper.getBoundingClientRect().width
                      ),
                      o = (this._innerWidth, this._innerPosition),
                      r = this._getItemOffset(t),
                      l = Math.round(t.getBoundingClientRect().width);
                    r - 5 <= -1 * o
                      ? (this._innerPosition = -1 * r)
                      : r + o + l + 5 >= s &&
                        (this._innerPosition =
                          l > s ? -1 * r : -1 * (r + l - s)),
                      t.classList.add("open");
                    var a = Math.round(i.getBoundingClientRect().width);
                    r + this._innerPosition + a > s &&
                      a < s &&
                      a > l &&
                      (i.style[this._rtl ? "marginRight" : "marginLeft"] =
                        "-" + (a - l) + "px"),
                      this._closeOther(t, n),
                      this._updateSlider();
                  } else {
                    var d = this._findChild(t, ["sidenav-toggle"]);
                    if (
                      (d.length && d[0].removeAttribute("data-hover", "true"),
                      t.classList.remove("open"),
                      (i.style[
                        this._rtl ? "marginRight" : "marginLeft"
                      ] = null),
                      n)
                    )
                      for (
                        var h = i.querySelectorAll(".sidenav-item.open"),
                          u = 0,
                          v = h.length;
                        u < v;
                        u++
                      )
                        h[u].classList.remove("open");
                  }
                }
              },
              {
                key: "_slide",
                value: function(e) {
                  var t = Math.round(
                      this._wrapper.getBoundingClientRect().width
                    ),
                    n = this._innerWidth,
                    i = void this._innerPosition;
                  "next" === e
                    ? ((i = this._getSlideNextPos()), n + i < t && (i = t - n))
                    : (i = this._getSlidePrevPos()) > 0 && (i = 0),
                    (this._innerPosition = i),
                    this.update();
                }
              },
              {
                key: "_getSlideNextPos",
                value: function() {
                  for (
                    var e = Math.round(
                        this._wrapper.getBoundingClientRect().width
                      ),
                      t = this._innerPosition,
                      n = this._inner.childNodes[0],
                      i = 0;
                    n;

                  ) {
                    if (n.tagName) {
                      var s = Math.round(n.getBoundingClientRect().width);
                      if (i + t - 5 <= e && i + t + s + 5 >= e) {
                        s > e && i === -1 * t && (i += s);
                        break;
                      }
                      i += s;
                    }
                    n = n.nextSibling;
                  }
                  return -1 * i;
                }
              },
              {
                key: "_getSlidePrevPos",
                value: function() {
                  for (
                    var e = Math.round(
                        this._wrapper.getBoundingClientRect().width
                      ),
                      t = this._innerPosition,
                      n = this._inner.childNodes[0],
                      i = 0;
                    n;

                  ) {
                    if (n.tagName) {
                      var s = Math.round(n.getBoundingClientRect().width);
                      if (i - 5 <= -1 * t && i + s + 5 >= -1 * t) {
                        s <= e && (i = i + s - e);
                        break;
                      }
                      i += s;
                    }
                    n = n.nextSibling;
                  }
                  return -1 * i;
                }
              },
              {
                key: "_getItemOffset",
                value: function(e) {
                  for (var t = this._inner.childNodes[0], n = 0; t !== e; )
                    t.tagName &&
                      (n += Math.round(t.getBoundingClientRect().width)),
                      (t = t.nextSibling);
                  return n;
                }
              },
              {
                key: "_bindAnimationEndEvent",
                value: function(e, t) {
                  var n = this,
                    i = function(i) {
                      i.target === e && (n._unbindAnimationEndEvent(e), t(i));
                    },
                    o = window.getComputedStyle(e).transitionDuration;
                  (o = parseFloat(o) * (-1 !== o.indexOf("ms") ? 1 : 1e3)),
                    (e._sideNavAnimationEndEventCb = i),
                    s.forEach(function(t) {
                      return e.addEventListener(
                        t,
                        e._sideNavAnimationEndEventCb,
                        !1
                      );
                    }),
                    (e._sideNavAnimationEndEventTimeout = setTimeout(
                      function() {
                        i({ target: e });
                      },
                      o + 50
                    ));
                }
              },
              {
                key: "_unbindAnimationEndEvent",
                value: function(e) {
                  var t = e._sideNavAnimationEndEventCb;
                  e._sideNavAnimationEndEventTimeout &&
                    (clearTimeout(e._sideNavAnimationEndEventTimeout),
                    (e._sideNavAnimationEndEventTimeout = null)),
                    t &&
                      (s.forEach(function(n) {
                        return e.removeEventListener(n, t, !1);
                      }),
                      (e._sideNavAnimationEndEventCb = null));
                }
              },
              {
                key: "_bindEvents",
                value: function() {
                  var e = this;
                  (this._evntElClick = function(t) {
                    var n = t.target.classList.contains("sidenav-toggle")
                      ? t.target
                      : e._findParent(t.target, "sidenav-toggle", !1);
                    n &&
                      (t.preventDefault(),
                      "true" !== n.getAttribute("data-hover") && e.toggle(n));
                  }),
                    this._el.addEventListener("click", this._evntElClick),
                    (this._evntWindowResize = function() {
                      e._horizontal
                        ? e._lastWidth !== window.innerWidth &&
                          ((e._lastWidth = window.innerWidth), e.update())
                        : e.update();
                    }),
                    window.addEventListener("resize", this._evntWindowResize),
                    this._horizontal &&
                      ((this._evntPrevBtnClick = function(t) {
                        t.preventDefault(),
                          e._prevBtn.classList.contains("disabled") ||
                            e._slide("prev");
                      }),
                      this._prevBtn.addEventListener(
                        "click",
                        this._evntPrevBtnClick
                      ),
                      (this._evntNextBtnClick = function(t) {
                        t.preventDefault(),
                          e._nextBtn.classList.contains("disabled") ||
                            e._slide("next");
                      }),
                      this._nextBtn.addEventListener(
                        "click",
                        this._evntNextBtnClick
                      ),
                      (this._evntBodyClick = function(t) {
                        !e._inner.contains(t.target) &&
                          e._el.querySelectorAll(
                            ".sidenav-inner > .sidenav-item.open"
                          ).length &&
                          e.closeAll();
                      }),
                      document.body.addEventListener(
                        "click",
                        this._evntBodyClick
                      ),
                      (this._evntHorizontalElClick = function(t) {
                        var n = t.target.classList.contains("sidenav-link")
                          ? t.target
                          : e._findParent(t.target, "sidenav-link", !1);
                        n &&
                          !n.classList.contains("sidenav-toggle") &&
                          e.closeAll();
                      }),
                      this._el.addEventListener(
                        "click",
                        this._evntHorizontalElClick
                      ),
                      this._showDropdownOnHover &&
                        ((this._evntInnerMousemove = function(t) {
                          for (
                            var n = e._findParent(t.target, "sidenav-item", !1),
                              i = null;
                            n;

                          )
                            (i = n), (n = e._findParent(n, "sidenav-item", !1));
                          if (i && !i.classList.contains("open")) {
                            var s = e._findChild(i, ["sidenav-toggle"]);
                            s.length &&
                              (s[0].setAttribute("data-hover", "true"),
                              e.open(s[0], e._closeChildren, !0),
                              setTimeout(function() {
                                s[0].removeAttribute("data-hover");
                              }, 500));
                          }
                        }),
                        this._inner.addEventListener(
                          "mousemove",
                          this._evntInnerMousemove
                        ),
                        (this._evntInnerMouseleave = function(t) {
                          e.closeAll();
                        }),
                        this._inner.addEventListener(
                          "mouseleave",
                          this._evntInnerMouseleave
                        )));
                }
              },
              {
                key: "_unbindEvents",
                value: function() {
                  this._evntElClick &&
                    (this._el.removeEventListener("click", this._evntElClick),
                    (this._evntElClick = null)),
                    this._evntWindowResize &&
                      (window.removeEventListener(
                        "resize",
                        this._evntWindowResize
                      ),
                      (this._evntWindowResize = null)),
                    this._evntPrevBtnClick &&
                      (this._prevBtn.removeEventListener(
                        "click",
                        this._evntPrevBtnClick
                      ),
                      (this._evntPrevBtnClick = null)),
                    this._evntNextBtnClick &&
                      (this._nextBtn.removeEventListener(
                        "click",
                        this._evntNextBtnClick
                      ),
                      (this._evntNextBtnClick = null)),
                    this._evntBodyClick &&
                      (document.body.removeEventListener(
                        "click",
                        this._evntBodyClick
                      ),
                      (this._evntBodyClick = null)),
                    this._evntHorizontalElClick &&
                      (this._el.removeEventListener(
                        "click",
                        this._evntHorizontalElClick
                      ),
                      (this._evntHorizontalElClick = null)),
                    this._evntInnerMousemove &&
                      (this._inner.removeEventListener(
                        "mousemove",
                        this._evntInnerMousemove
                      ),
                      (this._evntInnerMousemove = null)),
                    this._evntInnerMouseleave &&
                      (this._inner.removeEventListener(
                        "mouseleave",
                        this._evntInnerMouseleave
                      ),
                      (this._evntInnerMouseleave = null));
                }
              },
              {
                key: "_findMenu",
                value: function(e) {
                  for (var t = e.childNodes[0], n = null; t && !n; )
                    t.classList &&
                      t.classList.contains("sidenav-menu") &&
                      (n = t),
                      (t = t.nextSibling);
                  if (!n)
                    throw new Error(
                      "Cannot find `.sidenav-menu` element for the current `.sidenav-toggle`"
                    );
                  return n;
                }
              },
              {
                key: "_isRoot",
                value: function(e) {
                  return !this._findParent(e, "sidenav-item", !1);
                }
              },
              {
                key: "_findParent",
                value: function(e, t) {
                  var n =
                    !(arguments.length > 2 && void 0 !== arguments[2]) ||
                    arguments[2];
                  if ("BODY" === e.tagName.toUpperCase()) return null;
                  for (
                    e = e.parentNode;
                    "BODY" !== e.tagName.toUpperCase() &&
                    !e.classList.contains(t);

                  )
                    e = e.parentNode;
                  if (!(e = "BODY" !== e.tagName.toUpperCase() ? e : null) && n)
                    throw new Error("Cannot find `." + t + "` parent element");
                  return e;
                }
              },
              {
                key: "_findChild",
                value: function(e, t) {
                  for (
                    var n = e.childNodes, i = [], s = 0, o = n.length;
                    s < o;
                    s++
                  )
                    if (n[s].classList) {
                      for (var r = 0, l = 0; l < t.length; l++)
                        n[s].classList.contains(t[l]) && r++;
                      t.length === r && i.push(n[s]);
                    }
                  return i;
                }
              },
              {
                key: "_supportsTransitionEnd",
                value: function() {
                  if (window.QUnit) return !1;
                  var e = document.body || document.documentElement,
                    t = !1;
                  return (
                    o.forEach(function(n) {
                      void 0 !== e.style[n] && (t = !0);
                    }),
                    t
                  );
                }
              },
              {
                key: "_innerWidth",
                get: function() {
                  for (
                    var e = this._inner.childNodes, t = 0, n = 0, i = e.length;
                    n < i;
                    n++
                  )
                    e[n].tagName &&
                      (t += Math.round(e[n].getBoundingClientRect().width));
                  return t;
                }
              },
              {
                key: "_innerPosition",
                get: function() {
                  return parseInt(
                    this._inner.style[
                      this._rtl ? "marginRight" : "marginLeft"
                    ] || "0px"
                  );
                },
                set: function(e) {
                  return (
                    (this._inner.style[
                      this._rtl ? "marginRight" : "marginLeft"
                    ] = e + "px"),
                    e
                  );
                }
              }
            ]),
            e
          );
        })();
      t.SideNav = r;
    }
  });
  if ("object" == typeof n) {
    var i = [
      "object" == typeof module && "object" == typeof module.exports
        ? module.exports
        : null,
      "undefined" != typeof window ? window : null,
      e && e !== window ? e : null
    ];
    for (var s in n)
      i[0] && (i[0][s] = n[s]),
        i[1] && "__esModule" !== s && (i[1][s] = n[s]),
        i[2] && (i[2][s] = n[s]);
  }
})(this);
