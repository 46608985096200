import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withUser } from "../Auth";
import { hasPermission } from "../../config/permissions";

class SideNavMenu extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    permission: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    iconClassName: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  static defaultProps = {
    user: null,
    permission: ""
  };

  state = {
    open: false,
    childrenPathname: []
  };

  componentDidMount() {
    this.unlisten = this.props.history.listen(({ pathname }) => {
      this.setOpen(pathname);
    });
  }

  componentDidUpdate(_, { childrenPathname }) {
    if (childrenPathname.length !== this.state.childrenPathname.length) {
      this.setOpen(this.props.location.pathname);
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  setChidrenPathname = pathname => {
    this.setState(currentState => ({
      childrenPathname: [...currentState.childrenPathname, pathname]
    }));
  };

  setOpen = pathname => {
    const open = this.state.childrenPathname.includes(pathname);
    this.setState({
      open
    });
  };

  render() {
    const { text, children, iconClassName, user, permission } = this.props;

    if (permission && user && !hasPermission(user, permission)) return null;

    return (
      <li className={this.state.open ? "sidenav-item open" : "sidenav-item"}>
        <button
          type="button"
          className="sidenav-link sidenav-toggle btn btn-link"
        >
          {iconClassName && (
            <i className={`sidenav-icon ion ${iconClassName}`} />
          )}
          <div>{text}</div>
        </button>
        <ul className="sidenav-menu">
          {React.Children.map(children, child =>
            React.cloneElement(child, {
              initialize: this.setChidrenPathname
            })
          )}
        </ul>
      </li>
    );
  }
}

export default withRouter(withUser(SideNavMenu));
