import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";

class FileEditForm extends Component {
  static propTypes = {
    file: PropTypes.object,
    title: PropTypes.string.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  static defaultProps = {
    project: {}
  };

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  state = {};

  formSchema = Yup.object().shape({
    title: Yup.string().required(),
    note: Yup.string().required(),
    tags: Yup.string()
  });

  getInitialFormValues = () => {
    const { title, note, tags } = this.props.file;
    //console.log("FEF: ", { title, note, tags });
    return {
      title: title || "",
      note: note || "",
      tags: (tags && tags.join(", ")) || ""
    };
  };

  handleSubmit = values => {
    const file = { ...values };

    this.props.onSubmit(file);
  };

  render() {
    const submitText = "Update";
    return (
      <Formik
        initialValues={this.getInitialFormValues()}
        validationSchema={this.formSchema}
        onSubmit={this.handleSubmit}
        enableReinitialize
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          isValid,
          handleSubmit,
          touched,
          errors,
          setFieldValue,
          ...rest
        }) => {
          return (
            <div className="card card-default">
              <div className="card-header">
                <div className="h4">
                  <strong>{this.props.title}</strong>
                  <button
                    type="button"
                    className="float-right btn btn-xs btn-oval btn-outline-danger"
                    onClick={this.props.onCloseClick}
                  >
                    Close
                  </button>
                  <button
                    aria-disabled={!isValid || isSubmitting}
                    disabled={!isValid || isSubmitting}
                    onClick={handleSubmit}
                    type="submit"
                    className="float-right btn btn-xs mr-1 btn-oval btn-primary"
                  >
                    {submitText}
                  </button>
                </div>
              </div>
              <div className="card-body">
                <img
                  src={`${process.env.REACT_APP_API_URL}/gallery/file/${
                    this.props.file.id
                  }/thumb?token=${this.props.token}`}
                  alt={this.props.file.title}
                />
                <br />
                <br />
                <form
                  className="needs-validation"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <div className="form-group">
                    <label className="form-label" htmlFor="title">
                      Title
                    </label>
                    <input
                      id="title"
                      name="title"
                      type="text"
                      className={
                        touched.title && errors.title
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Image Title"
                      required
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback mx-1"
                    />
                  </div>

                  <div className="form-group">
                    <label className="form-label" htmlFor="note">
                      Description
                    </label>
                    <textarea
                      id="note"
                      name="note"
                      rows="3"
                      className={
                        touched.note && errors.note
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      value={values.note}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="form-group">
                    <label className="form-label" htmlFor="tags">
                      Tags (separate tags by commas, e.g.: tag1, tag2, tag3)
                    </label>
                    <input
                      id="tags"
                      name="tags"
                      type="text"
                      className={
                        touched.tags && errors.tags
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Image Tags"
                      required
                      value={values.tags}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback mx-1"
                    />
                  </div>

                  <button
                    aria-disabled={!isValid || isSubmitting}
                    disabled={!isValid || isSubmitting}
                    className="btn btn-primary my-2"
                    type="submit"
                  >
                    {submitText}
                  </button>
                </form>
              </div>
            </div>
          );
        }}
      </Formik>
    );
  }
}

export default FileEditForm;
