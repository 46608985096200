exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".editorStyles_editor__HeRgx {\n  box-sizing: border-box;\n  border: 1px solid #ddd;\n  cursor: text;\n  padding: 16px;\n  border-radius: 5px;\n  margin-bottom: 2em;\n  background: #fefefe;\n}\n\n.editorStyles_editor__HeRgx .public-DraftEditor-content {\n  min-height: 140px;\n}\n\n.editorStyles_headlineButtonWrapper__2FcRH {\n  display: inline-block;\n}\n\n.editorStyles_headlineButton__crd2t {\n  background: #fbfbfb;\n  color: #888;\n  font-size: 18px;\n  border: 0;\n  padding-top: 5px;\n  vertical-align: bottom;\n  height: 34px;\n  width: 36px;\n}\n\n.editorStyles_headlineButton__crd2t:hover,\n.editorStyles_headlineButton__crd2t:focus {\n  background: #f3f3f3;\n}\n", ""]);

// exports
exports.locals = {
	"editor": "editorStyles_editor__HeRgx",
	"headlineButtonWrapper": "editorStyles_headlineButtonWrapper__2FcRH",
	"headlineButton": "editorStyles_headlineButton__crd2t"
};