import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { stateToHTML } from "draft-js-export-html";
import scrollToTop from "../../utils/scrollToTop";
import Editor from "../Editor";

class CareersPostingForm extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    career: PropTypes.object
  };

  static defaultProps = {
    career: {}
  };

  formSchema = Yup.object().shape({
    title: Yup.string().required("The job title is required."),
    active: Yup.bool().required("The job should be active or inactive."),
    description: Yup.string().required("The job description is required.")
  });

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate() {
    scrollToTop();
  }

  getInitialFormValues = () => {
    const { job_title, job_description, job_active } = this.props.career;

    return {
      title: job_title || "",
      description: job_description || "",
      active: job_active || false
    };
  };

  handleSubmit = values => {
    this.props.onSubmit({ ...values, active: values.active ? 1 : 0 });
  };

  render() {
    const submitText = Object.keys(this.props.career).length
      ? "Update"
      : "Create";
    return (
      <Formik
        initialValues={this.getInitialFormValues()}
        validationSchema={this.formSchema}
        onSubmit={this.handleSubmit}
        enableReinitialize
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          isValid,
          handleSubmit,
          touched,
          errors,
          setFieldValue,
          setFieldTouched
        }) => {
          return (
            <div className="card card-default">
              <div className="card-header">
                <div className="h4">
                  <strong>{this.props.title}</strong>
                  <button
                    type="button"
                    className="float-right btn btn-xs mr-1 btn-oval btn-outline-danger"
                    onClick={this.props.onCloseClick}
                  >
                    Close
                  </button>
                  <button
                    aria-disabled={!isValid || isSubmitting}
                    disabled={!isValid || isSubmitting}
                    onClick={handleSubmit}
                    type="submit"
                    className="float-right btn btn-xs mr-1 btn-oval btn-primary"
                  >
                    {submitText}
                  </button>
                </div>
              </div>
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <div className="form-group">
                    <label className="form-label" htmlFor="title">
                      Job Title
                    </label>
                    <input
                      id="title"
                      name="title"
                      type="text"
                      className={
                        touched.title && errors.title
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Job title"
                      required
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="invalid-feedback mx-1"
                    />
                  </div>
                  <div className="form-group">
                    <label className="switcher" htmlFor="active">
                      <input
                        id="active"
                        name="active"
                        type="checkbox"
                        className="switcher-input"
                        checked={values.active}
                        value={values.active}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyPress={e => {
                          if (e.key === "Enter")
                            setFieldValue("active", !values.active);
                        }}
                      />
                      <span className="switcher-indicator">
                        <span className="switcher-yes">
                          <span className="ion ion-md-checkmark" />
                        </span>
                        <span className="switcher-no">
                          <span className="ion ion-md-close" />
                        </span>
                      </span>
                      <span className="switcher-label">Active</span>
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="description">
                      Job Description
                    </label>
                    <Editor
                      content={this.props.career.job_description}
                      onBlur={() => {
                        setFieldTouched("description", true);
                      }}
                      onChange={editorState => {
                        const rawContent = editorState
                          .getCurrentContent()
                          .getPlainText();

                        // The empty editor state is not an empty string.
                        if (rawContent) {
                          setFieldValue(
                            "description",
                            stateToHTML(editorState.getCurrentContent())
                          );
                        } else {
                          setFieldValue("description", "");
                        }
                      }}
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-danger mx-1"
                    />
                  </div>
                  <button
                    aria-disabled={!isValid || isSubmitting}
                    disabled={!isValid || isSubmitting}
                    className="btn btn-primary my-2"
                    type="submit"
                  >
                    {submitText}
                  </button>
                </form>
              </div>
            </div>
          );
        }}
      </Formik>
    );
  }
}

export default CareersPostingForm;
