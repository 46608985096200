import React from "react";
import PropTypes from "prop-types";

const Pagination = ({ count, pageSize, onPages, currentPage }) => {
    const pagesCount = Math.ceil(count / pageSize);
    if (pagesCount <= 1) return null;

    return (
        <ul className="pagination m-0">
            <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
            >
                <button className="page-link" onClick={() => onPages(1)} href="#">
                    <i className="fas fa-angle-double-left" />
                </button>
            </li>
            <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
            >
                <button className="page-link" onClick={() => onPages(currentPage - 1)} href="#">
                    <i className="fas fa-angle-left" />
                </button>
            </li>
            <li
                className={`page-item active`}
            >

                <button className="page-link" href="#">
                    {currentPage}
                </button>
            </li>
            <li
                className={`page-item ${currentPage === pagesCount ? "disabled" : ""}`}
            >
                <button className="page-link" onClick={() => onPages(currentPage + 1)} href="#">
                    <i className="fas fa-angle-right" />
                </button>
            </li>
            <li
                className={`page-item ${currentPage === pagesCount ? "disabled" : ""}`}
            >
                <button className="page-link" onClick={() => onPages(pagesCount)} href="#">
                    <i className="fas fa-angle-double-right" />
                </button>
            </li>
        </ul>
    );
};

Pagination.propTypes = {
    count: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPages: PropTypes.func.isRequired,
};
export default Pagination;