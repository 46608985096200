import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import Axios from "axios";
import _ from 'lodash';
import { withAuthorization } from "../Auth";
import * as routes from "../../config/routes";
import { hasPermission, PERMISSIONS } from "../../config/permissions";
import sort from "./../../utils/sort";
import UsersList from "./UsersList";


class UserManagement extends Component {
    state = {
        users: [],
        filteredUsers: [],
        searchValue: "",
        loading: true,
        sortBy: "emplid",
        sortDesc: false,
        pageSize: 20,
        currentPage: 1,
        filterField: {
            isActive: "all",
            isEligible: "all",
            isNexus: "all",
            accessLevel: "all",
            company: "all",
            contributorLevel: "all",
            position: "all",
            clearAll: false,
        },
    };

    componentDidMount() {
        this.fetch();
    }

    fetch = async () => {
        const URL = `${process.env.REACT_APP_API_URL}/user/all`;

        const response = await Axios.get(URL);
        const users = response.data;
        const sortedUsers = sort(users, this.state.sortBy, this.state.sortDesc);
        this.setState({
            users: sortedUsers,
            filteredUsers: sortedUsers,
            loading: false
        });
    };

    handlePages = page => {
        this.setState({ currentPage: page });
    };

    handleSearchChange = async (e) => {
        await this.setState({ searchValue: e.target.value })
        this.filter();
    };

    handleFilterChange = async (e) => {
        const filterField = {};
        for (let k in this.state.filterField) {
            filterField[k] = k === e.target.name ? e.target.value : this.state.filterField[k]
        }
        await this.setState({ filterField })
        this.filter();
    };

    setSortBy = key => {
        this.setState(
            currentState => {
                const nextState = {};
                if (key === currentState.sortBy) {
                    nextState.sortDesc = !currentState.sortDesc;
                } else {
                    nextState.sortBy = key;
                }
                return nextState;
            },
            () => {
                this.setState(
                    { users: sort(this.state.users, this.state.sortBy, this.state.sortDesc) },
                    this.filter
                );
            }
        );
    };

    filter = () => {
        const { users, searchValue, filterField: { clearAll } } = this.state;
        if (clearAll) {
            const filterField = {
                isActive: "all",
                isEligible: "all",
                isNexus: "all",
                accessLevel: "all",
                company: "all",
                contributorLevel: "all",
                position: "all",
                clearAll: false,
            }
            this.setState({ filterField });
        }
        const { isActive, isEligible, isNexus, accessLevel, company, contributorLevel, position } = this.state.filterField;
        const filteredUsers = users
            .filter(({ first_name, last_name, email_address, username, emplid }) =>
                first_name.toLowerCase().includes(searchValue.toLowerCase())
                || last_name.toLowerCase().includes(searchValue.toLowerCase())
                || email_address.toLowerCase().includes(searchValue.toLowerCase())
                || username.toLowerCase().includes(searchValue.toLowerCase())
                || emplid.toLowerCase().includes(searchValue.toLowerCase())
            ).filter(user => isActive === "active" ? user.active : isActive === "inactive" ? !user.active : user
            ).filter(user => isEligible === "eligible" ? user.eligible : isEligible === "notEligible" ? !user.eligible : user
            ).filter(user => isNexus === "nexus" ? user.is_nexus : isNexus === "notNexus" ? !user.is_nexus : user
            ).filter(user =>
                accessLevel === "admin" ? user.access_level === "Admin" :
                    accessLevel === "careerAdmin" ? user.access_level === "Career Admin" :
                        accessLevel === "borgerAppAdmin" ? user.access_level === "Borger App Admin" :
                            accessLevel === "employee" ? user.access_level === "Employee" : user
            ).filter(user =>
                company === "WRD" ? user.company === "WRD" :
                    company === "earthworks" ? user.company === "Earthworks" :
                        company === "transport" ? user.company === "Transport" : user
            ).filter(user =>
                contributorLevel === "individual" ? user.contributor_level === "Individual" :
                    contributorLevel === "supervisor" ? user.contributor_level === "Supervisor" :
                        contributorLevel === "manager" ? user.contributor_level === "Manager" : user
            ).filter(user => position === "field" ? user.position === "Field Employee" :
                position === "office" ? user.position === "Head Office Employee" : user
            )
        this.setState({ filteredUsers });
    };

    getPagedData = () => {
        const {
            pageSize,
            currentPage,
            filteredUsers: items
        } = this.state;

        const startIndex = (currentPage - 1) * pageSize;
        const pageUsers = _(items).slice(startIndex).take(pageSize).value();
        const totalCount = items.length;
        return { pageUsers, totalCount };
    }

    render() {
        const {
            searchValue,
            loading,
            sortBy,
            sortDesc,
            pageSize,
            currentPage,
            filterField,
        } = this.state;

        const { pageUsers, totalCount } = this.getPagedData();

        return (
            <DocumentTitle title="User Management - Borger Team">
                <UsersList
                    users={pageUsers}
                    totalCount={totalCount}
                    search={this.handleSearchChange}
                    searchValue={searchValue}
                    loading={loading}
                    sortBy={this.setSortBy}
                    sortDesc={sortDesc}
                    sortKey={sortBy}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPages={this.handlePages}
                    filterField={filterField}
                    onFilterChange={this.handleFilterChange}
                />
            </DocumentTitle >
        );
    }
}

const protectedPage = withAuthorization(
    user => hasPermission(user, PERMISSIONS.users),
    () => routes.DASHBOARD
);

export default protectedPage(UserManagement);
