import React, { Component } from "react";
import ThemeContext from "./ThemeContext";
import withTheme from "./withTheme";
import "../../vendor/js/layout-helpers";

class Theme extends Component {
  closeSideNav = e => {
    e.preventDefault();
    window.layoutHelpers.setCollapsed(true);
  };

  toggleCollapsed = e => {
    e.preventDefault();
    window.layoutHelpers.toggleCollapsed();
  };

  update = () => {
    window.layoutHelpers.update();
  };

  state = {
    layoutNavbarBg: "bg-navbar-theme",
    layoutFooterBg: "bg-footer-theme",
    layoutSidenavBg: "bg-sidenav-theme",
    toggleCollapsed: this.toggleCollapsed,
    closeSideNav: this.closeSideNav,
    update: this.update
  };

  componentDidMount() {
    window.layoutHelpers.init();
  }

  render() {
    return (
      <ThemeContext.Provider value={this.state}>
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}

export { Theme as default, withTheme };
