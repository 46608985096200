export const DASHBOARD = "/dashboard";
export const LOGIN = "/login";
export const MESSAGING = {
  general: "/messaging/general",
  calls: "/messaging/calls"
};
export const PROJECTS = "/projects";
export const GALLERYADMIN = "/gallery_admin";
export const CAREERS = {
  postings: "/careers/postings",
  applicants: "/careers/applicants"
};
export const ACCOUNTS = {
  transactions: "/accounts/transactions",
  update: "/accounts/update"
};
export const LEARNING = {
  admin: "/learning/learning_admin",
  courseassign: "/learning/course_assignment"
};
export const USER = "/user";