import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Marker } from "google-maps-react";
import GoogleMap from "../GoogleMap";
import AddressForm from "./AddressForm";
import scrollToTop from "../../utils/scrollToTop";

class ProjectForm extends Component {
  static propTypes = {
    project: PropTypes.object,
    title: PropTypes.string.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  static defaultProps = {
    project: {}
  };

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate() {
    scrollToTop();
  }

  getInitialAddress = () => {
    const { address, city, province, country } = this.props.project;

    return {
      address: address || "",
      city: city || "",
      province: province || "",
      country: country || ""
    };
  };

  state = {
    address: this.getInitialAddress(),
    showCoordsInput: true
  };

  mapConfig = {
    map: {
      initialCenter: {
        lat: this.props.project.latitude || 50.98468734724245,
        lng: this.props.project.longitude || -113.97658824920654
      },
      center: {
        lat: this.props.project.latitude || 50.98468734724245,
        lng: this.props.project.longitude || -113.97658824920654
      },
      zoom: 12
    },
    marker: {
      position: {
        lat: this.props.project.latitude || 50.98468734724245,
        lng: this.props.project.longitude || -113.97658824920654
      }
    }
  };

  formSchema = Yup.object().shape({
    name: Yup.string().required("The project name is required."),
    note: Yup.string(),
    is_active: Yup.bool().required(),
    is_underground: Yup.bool().required(),
    is_earthworks: Yup.bool().required(),
    is_maintenance: Yup.bool().required(),
    latitude: Yup.string().required("Latitude is required."),
    longitude: Yup.string().required("Longitude is required.")
  });

  getInitialFormValues = () => {
    const {
      name,
      note,
      is_active,
      is_underground,
      is_earthworks,
      is_maintenance,
      latitude,
      longitude
    } = this.props.project;
    return {
      name: name || "",
      note: note || "",
      is_active: is_active || true,
      is_underground: is_underground || false,
      is_earthworks: is_earthworks || false,
      is_maintenance: is_maintenance || false,
      latitude: latitude || "",
      longitude: longitude || ""
    };
  };

  coordinatesFieldChange = position => {
    if (!position.lat || !position.lng) return;

    this.mapConfig.marker.position = position;
    this.mapConfig.map.center = position;
  };

  markerMoved = (position, setFormValue) => {
    const { lat, lng } = position;
    setFormValue("latitude", lat());
    setFormValue("longitude", lng());

    this.setShowCoordsInput(true);
    this.coordinatesFieldChange({ lat: lat(), lng: lng() });
  };

  setShowCoordsInput = showCoordsInput => this.setState({ showCoordsInput });

  handleSubmit = values => {
    const project = { ...values };
    const address = { ...this.state.address };

    Object.keys(project).forEach(
      k =>
        typeof project[k] !== "boolean" &&
        !project[k] &&
        project[k] !== undefined &&
        delete project[k]
    );
    Object.keys(address).forEach(
      k => !address[k] && address[k] !== undefined && delete address[k]
    );

    this.props.onSubmit({
      ...project,
      ...address
    });
  };

  render() {
    const { showCoordsInput, address } = this.state;
    const submitText = Object.keys(this.props.project).length
      ? "Update"
      : "Create";
    return (
      <Formik
        initialValues={this.getInitialFormValues()}
        validationSchema={this.formSchema}
        onSubmit={this.handleSubmit}
        enableReinitialize
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          isValid,
          handleSubmit,
          touched,
          errors,
          setFieldValue,
          ...rest
        }) => {
          return (
            <div className="card card-default">
              <div className="card-header">
                <div className="h4">
                  <strong>{this.props.title}</strong>
                  <button
                    type="button"
                    className="float-right btn btn-xs btn-oval btn-outline-danger"
                    onClick={this.props.onCloseClick}
                  >
                    Close
                  </button>
                  <button
                    aria-disabled={!isValid || isSubmitting}
                    disabled={!isValid || isSubmitting}
                    onClick={handleSubmit}
                    type="submit"
                    className="float-right btn btn-xs mr-1 btn-oval btn-primary"
                  >
                    {submitText}
                  </button>
                </div>
              </div>
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <div className="form-group">
                    <label className="form-label" htmlFor="name">
                      Project Name
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      className={
                        touched.name && errors.name
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Project name"
                      required
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback mx-1"
                    />
                  </div>

                  <div className="form-group">
                    <label className="form-label" htmlFor="note">
                      Notes<small className="ml-2 text-muted">optional</small>
                    </label>
                    <textarea
                      id="note"
                      name="note"
                      rows="3"
                      className={
                        touched.note && errors.note
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      value={values.note}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  <hr className="border-light my-3 container-m--x" />

                  <div className="form-group">
                    <label className="switcher" htmlFor="is_active">
                      <input
                        id="is_active"
                        name="is_active"
                        type="checkbox"
                        className="switcher-input"
                        checked={values.is_active}
                        value={values.is_active}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyPress={e => {
                          if (e.key === "Enter")
                            setFieldValue("is_active", !values.is_active);
                        }}
                      />
                      <span className="switcher-indicator">
                        <span className="switcher-yes">
                          <span className="ion ion-md-checkmark" />
                        </span>
                        <span className="switcher-no">
                          <span className="ion ion-md-close" />
                        </span>
                      </span>
                      <span className="switcher-label">Active</span>
                    </label>
                  </div>

                  <div className="form-group">
                    <div className="text-light small font-weight-semibold mb-3">
                      Teams
                    </div>

                    <div className="switchers-stacked">
                      <label className="switcher" htmlFor="is_underground">
                        <input
                          id="is_underground"
                          name="is_underground"
                          type="checkbox"
                          className="switcher-input"
                          checked={values.is_underground}
                          value={values.is_underground}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onKeyPress={e => {
                            if (e.key === "Enter")
                              setFieldValue(
                                "is_underground",
                                !values.is_underground
                              );
                          }}
                        />
                        <span className="switcher-indicator">
                          <span className="switcher-yes">
                            <span className="ion ion-md-checkmark" />
                          </span>
                          <span className="switcher-no">
                            <span className="ion ion-md-close" />
                          </span>
                        </span>
                        <span className="switcher-label">Underground</span>
                      </label>

                      <label className="switcher" htmlFor="is_earthworks">
                        <input
                          id="is_earthworks"
                          name="is_earthworks"
                          type="checkbox"
                          className="switcher-input"
                          checked={values.is_earthworks}
                          value={values.is_earthworks}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onKeyPress={e => {
                            if (e.key === "Enter")
                              setFieldValue(
                                "is_earthworks",
                                !values.is_earthworks
                              );
                          }}
                        />
                        <span className="switcher-indicator">
                          <span className="switcher-yes">
                            <span className="ion ion-md-checkmark" />
                          </span>
                          <span className="switcher-no">
                            <span className="ion ion-md-close" />
                          </span>
                        </span>
                        <span className="switcher-label">Earthworks</span>
                      </label>

                      <label className="switcher" htmlFor="is_maintenance">
                        <input
                          id="is_maintenance"
                          name="is_maintenance"
                          type="checkbox"
                          className="switcher-input"
                          checked={values.is_maintenance}
                          value={values.is_maintenance}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onKeyPress={e => {
                            if (e.key === "Enter")
                              setFieldValue(
                                "is_maintenance",
                                !values.is_maintenance
                              );
                          }}
                        />
                        <span className="switcher-indicator">
                          <span className="switcher-yes">
                            <span className="ion ion-md-checkmark" />
                          </span>
                          <span className="switcher-no">
                            <span className="ion ion-md-close" />
                          </span>
                        </span>
                        <span className="switcher-label">Maintenance</span>
                      </label>
                    </div>
                  </div>

                  <hr className="border-light my-3 container-m--x" />

                  <div className="h4">
                    Directions{" "}
                    <small className="text-muted">
                      Specify directions to the project site
                    </small>
                  </div>
                  <p>
                    Latitude/longitude coordinates provide more precise and
                    reliable results to drivers. You can select cooridnates
                    directly from the map provided.
                    <br />
                    If desired, you can also specify the street address. Street
                    addresses will be converted to coordinates by Google Maps
                    and displayed in the map.
                  </p>

                  <div className="mb-2">
                    <button
                      type="button"
                      aria-pressed={showCoordsInput}
                      className={
                        showCoordsInput
                          ? "btn btn-sm btn-oval mr-1 btn-outline-primary active"
                          : "btn btn-sm btn-oval mr-1 btn-outline-primary"
                      }
                      onClick={() => this.setShowCoordsInput(true)}
                    >
                      Coordinates
                    </button>
                    <button
                      type="button"
                      aria-pressed={!showCoordsInput}
                      className={
                        !showCoordsInput
                          ? "btn btn-sm btn-oval btn-outline-primary active"
                          : "btn btn-sm btn-oval btn-outline-primary"
                      }
                      onClick={() => this.setShowCoordsInput(false)}
                    >
                      Address
                    </button>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      {showCoordsInput && (
                        <>
                          <div className="form-group">
                            <label className="form-label" htmlFor="latitude">
                              Latitude
                            </label>
                            <input
                              id="latitude"
                              name="latitude"
                              type="text"
                              className={
                                touched.latitude && errors.latitude
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              placeholder="latitude"
                              value={values.latitude}
                              onChange={e => {
                                handleChange(e);
                                this.coordinatesFieldChange({
                                  lat: e.target.value,
                                  lng: values.longitude
                                });
                              }}
                              onBlur={handleBlur}
                              required
                            />
                            <ErrorMessage
                              name="latitude"
                              component="div"
                              className="invalid-feedback mx-1"
                            />
                          </div>

                          <div className="form-group">
                            <label className="form-label" htmlFor="longitude">
                              Longitude
                            </label>
                            <input
                              id="longitude"
                              name="longitude"
                              type="text"
                              className={
                                touched.longitude && errors.longitude
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              placeholder="longitude"
                              value={values.longitude}
                              onChange={e => {
                                handleChange(e);
                                this.coordinatesFieldChange({
                                  lat: values.latitude,
                                  lng: e.target.value
                                });
                              }}
                              onBlur={handleBlur}
                              required
                            />
                            <ErrorMessage
                              name="longitude"
                              component="div"
                              className="invalid-feedback mx-1"
                            />
                          </div>
                        </>
                      )}
                      {!showCoordsInput && (
                        <AddressForm
                          address={address}
                          onSubmit={coords => {
                            this.setState({ address: coords.address });
                            this.markerMoved(coords, setFieldValue);
                          }}
                        />
                      )}
                    </div>

                    <div className="col-12 col-md-6" style={{ height: 300 }}>
                      <GoogleMap
                        {...this.mapConfig.map}
                        onClick={(t, map, { latLng }) => {
                          this.markerMoved(latLng, setFieldValue);
                        }}
                        disableDefaultUI
                        zoomControl
                        streetViewControl
                      >
                        <Marker
                          {...this.mapConfig.marker}
                          draggable={true}
                          onDragend={(_, { position }) =>
                            this.markerMoved(position, setFieldValue)
                          }
                        />
                      </GoogleMap>
                    </div>
                  </div>

                  <button
                    aria-disabled={!isValid || isSubmitting}
                    disabled={!isValid || isSubmitting}
                    className="btn btn-primary my-2"
                    type="submit"
                  >
                    {submitText}
                  </button>
                </form>
              </div>
            </div>
          );
        }}
      </Formik>
    );
  }
}

export default ProjectForm;
