import React from "react";
import { Redirect } from "react-router-dom";
import AuthContext from "./AuthContext";
import * as routes from "../../config/routes";
import * as permissions from "../../config/permissions";

const withAuthorization = (
  authCondition = permissions.isLoggedIn,
  getRedirectRoute = user => routes.LOGIN
) => Component => {
  function Wrapper(props) {
    return (
      <AuthContext.Consumer>
        {({ user }) => {
          if (!user || !authCondition(user))
            return <Redirect to={getRedirectRoute(user)} />;

          return <Component {...props} user={user} />;
        }}
      </AuthContext.Consumer>
    );
  }

  Wrapper.displayName = `withAuthorization(${Component.displayName ||
    Component.name})`;

  return Wrapper;
};

export default withAuthorization;
