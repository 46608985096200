import axios from "axios";
import Pace from "pace-js";
import { toast } from "react-toastify";

const setupAxiosInterceptors = (onUnauthenticated, token = "") => {
  const onRequest = config => {
    // Show progress bar
    Pace.restart();

    if (config.url.includes(process.env.REACT_APP_API_URL) && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  };

  const onResponseSuccess = response => {
    // Hide progress bar
    Pace.restart();

    return response;
  };

  const onResponseError = err => {
    // Hide progress bar
    Pace.restart();

    const status = err.status || (err.response && err.response.status);

    if (status === 401) {
      onUnauthenticated();
    } else {
      toast.error("Oops! It seems there was a network error.", {
        autoClose: 2000
      });
    }

    return Promise.reject(err);
  };

  if (setupAxiosInterceptors.requestInterceptor !== undefined) {
    axios.interceptors.request.eject(setupAxiosInterceptors.requestInterceptor);
  }
  if (setupAxiosInterceptors.responseInterceptor !== undefined) {
    axios.interceptors.response.eject(
      setupAxiosInterceptors.responseInterceptor
    );
  }

  setupAxiosInterceptors.requestInterceptor = axios.interceptors.request.use(
    onRequest
  );
  setupAxiosInterceptors.responseInterceptor = axios.interceptors.response.use(
    onResponseSuccess,
    onResponseError
  );
};

export default setupAxiosInterceptors;
