import React from "react";
//import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TransactionsFilters = ({
  selectedEmployee,
  onEmployeeChange,
  employees,
  showDatePicker,
  selectedStartDate,
  onStartDateChange,
  selectedEndDate,
  onEndDateChange,
  executeSearch
}) => {
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group mx-2">
            <label htmlFor="employeeList">Employee</label>
            <select
              className="form-control custom-select"
              id="employeeList"
              value={selectedEmployee}
              onChange={onEmployeeChange}
            >
              <option value="0">All</option>
              {employees.map((employee, index) => (
                <option key={employee.employee_id} value={index + 1}>
                  {`${employee.first_name} ${employee.last_name} - ${
                    employee.emplid
                  }`}
                </option>
              ))}
            </select>
          </div>
        </div>
        {showDatePicker && (
          <>
            <div className="col">
              <div className="form-group mx-2">
                <label htmlFor="startDatePicker">Report Start Date</label>
                <br />
                <DatePicker
                  className="form-control"
                  id="startDatePicker"
                  selected={selectedStartDate}
                  onChange={onStartDateChange}
                  dateFormat="yyyy-MM-dd"
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group mx-2">
                <label htmlFor="endDatePicker">Report End Date</label>
                <br />
                <DatePicker
                  className="form-control"
                  id="endDatePicker"
                  selected={selectedEndDate}
                  onChange={onEndDateChange}
                  dateFormat="yyyy-MM-dd"
                />
              </div>
            </div>
          </>
        )}
        <div>
          <br />
          <button
            onClick={executeSearch}
            type="button"
            className="btn btn-primary btn-round d-block"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

TransactionsFilters.propTypes = {
  // search: PropTypes.func.isRequired,
  // onFilterChange: PropTypes.func.isRequired,
  // searchValue: PropTypes.string,
  // filterField: PropTypes.string
};

TransactionsFilters.defaultProps = {
  // filterField: "",
  // searchValue: ""
};

export default TransactionsFilters;
