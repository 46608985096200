import formatPermissions from "../utils/formatPermissions";

export const PERMISSIONS = {
  messaging: "messaging",
  projects: "projects",
  careers: "careers",
  gallery: "gallery",
  accounts: "accounts",
  learning: "learning",
  users: "users"
};

export const isLoggedIn = user => !!user;

export const isAdmin = user =>
  user && user.accessList && user.accessList.length;

export const hasPermission = (user, permission) =>
  user && formatPermissions(user).includes(permission);
